import React, { useEffect, useState } from 'react';
import './AccountCard.scss';
import FormInput from '../../../../components/form-input/FormInput';
import FormButton from '../../../../components/form-button/FormButton';
import utils from '../../../../utils';
import { useHistory } from 'react-router';
import { getCountries, regionsTR } from "../../../../consts"
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import store from '../../../../store';
import { Trans, useTranslation } from 'react-i18next';
import axios from 'axios';
import { observer } from 'mobx-react';

const AccountCard = props => {

    const history = useHistory();
    const form = React.useRef();
    const form2 = React.useRef();
    const country = React.useRef();
    const { t, i18n } = useTranslation();
    const [refresh, setrefresh] = useState(0);
    const [errorMessage, seterrorMessage] = useState('');
    const [message, setmessage] = useState('');
    const [errorMessage2, seterrorMessage2] = useState('');
    // const [message2, setmessage2] = useState('');
    const [showChangePassword, setshowChangePassword] = useState(false);
    const password = React.useRef();
    const passwordConfirm = React.useRef();
    const [sending, setsending] = useState(false);
    const [cancelling, setcancelling] = useState(false);
    const [passwordChanging, setpasswordChanging] = useState(false);

    useEffect(() => {
        setrefresh(refresh + 1);
        // eslint-disable-next-line
    }, []);

    function updateAccount(e) {
        e.preventDefault();

        seterrorMessage("");
        setmessage("");
        setsending(true);

        var data = utils.formSerialize(form.current);
        data.language = i18n.language;
        // var data = new URLSearchParams(formData);
        // data.append("device_id", formData.email);

        axios.post(`${utils.ApiAddress}/api/profile`, data, { headers: { "Authorization": "Bearer " + store.token } })
            .then(response => {
                store.user.name = data.name;
                store.user.email = data.email;
                store.user.country = data.country && data.country.toUpperCase();
                store.user.city = data.city;
                store.saveUserToLocalStorage();

                setmessage(t("Değişiklik yapıldı"));
            })
            .catch((error) => {
                console.error(error);
                seterrorMessage(error.message);
            })
            .finally(() => setsending(false));

        // axios.put('https://8milyarsalavat.com/api/profile', data, { headers: { "X-Salavat-Auth-Token": store.token } })
        //     .then(response => {
        //         store.user.name = formData.fullname;
        //         store.user.email = formData.email;
        //         store.user.country = formData.country;
        //         store.user.city = formData.city;
        //         store.saveUserToLocalStorage();

        //         setmessage("Değişiklik yapıldı");
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //         seterrorMessage(error.message);
        //     })
        //     .finally(() => setsending(false));
    }

    function changePassword(e) {
        e.preventDefault();

        seterrorMessage2("");
        // setmessage2("");

        var data = utils.formSerialize(form2.current);
        data.language = i18n.language;
        //profil değiştirme ve şifre değiştirme için aynı api kullanıldığı için profil bilgileri de yeni şifre ile birilikte gönderiliyor
        data.name = store.user.name;
        data.email = store.user.email;
        data.country = store.user.country;
        data.city = store.user.city;

        setpasswordChanging(true);
        axios.post(`${utils.ApiAddress}/api/profile`, data, { headers: { "Authorization": "Bearer " + store.token } })
            .then(response => {
                if (response.data.code >= 400) {
                    seterrorMessage2(utils.getApiError(response));
                } else {
                    store.message = (t("Şifreniz değiştirilmiştir"));
                    setshowChangePassword(false);
                }
            })
            .catch((error) => {
                seterrorMessage(utils.getAxiosErrorMessage(error));
            })
            .finally(() => setpasswordChanging(false));
        // axios.post(`https://8milyarsalavat.com/api/changepwd`, formData, { headers: { "X-Salavat-Auth-Token": store.token } })
        //     .then(response => {
        //         if (response.data.error === true) {
        //             seterrorMessage2("");
        //             seterrorMessage2(response.data.message)
        //         } else if (response.data.errorMessage) {
        //             seterrorMessage2("");
        //             seterrorMessage2(response.data.errorMessage)
        //         } else {
        //             seterrorMessage2("");
        //             store.message = (t("Şifreniz değiştirilmiştir"));
        //             setshowChangePassword(false);
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error);
        //         seterrorMessage2(utils.getAxiosErrorMessage(error));
        //     })
        //     .finally(() => {
        //         setpasswordChanging(false)
        //     });
    }

    function cancelMembership() {
        var response = window.prompt(t("Üyeliğinizi iptal etmek"));
        if (response === "") {
            return;
        }
        else if (response !== store.user.email) {
            store.error = t("Epostanızı yanlış girdiniz.");
        }
        else {
            var formData = {
                "language": i18n.language,
                "email": store.user.email
            };

            setcancelling(true);
            axios.post(`${utils.ApiAddress}/api/remove`, formData, { headers: { "Authorization": "Bearer " + store.token } })
                .then(response => {
                    // if (response.data.error === true) {
                    //     store.error = response.data.message;
                    // } else if (response.data.errorMessage) {
                    //     store.error = response.data.errorMessage;
                    // } else {
                    store.message = t("Üyeliğiniz iptal edilmiştir");
                    logOut();
                    // }
                })
                .catch(error => {
                    console.log(error);
                    store.error = utils.getAxiosErrorMessage(error);
                })
                .finally(() => {
                    setcancelling(false);
                });

            //setcancelling(true);
            // axios.post(`https://8milyarsalavat.com/api/cancelMembership`, formData, { headers: { "X-Salavat-Auth-Token": store.token } })
            //     .then(response => {
            //         if (response.data.error === true) {
            //             store.error = response.data.message;
            //         } else if (response.data.errorMessage) {
            //             store.error = response.data.errorMessage;
            //         } else {
            //             store.message = "Üyeliğiniz iptal edilmiştir";
            //             logOut();
            //         }
            //     })
            //     .catch(error => {
            //         console.log(error);
            //         store.error = utils.getAxiosErrorMessage(error);
            //     })
            //     .finally(() => {
            //         setcancelling(false);
            //     });
        }
    }

    function logOut(e) {
        if (e)
            e.preventDefault();
        store.logOut();
        history.push("/");
    }

    return (
        <div id="account-card-container">
            <span id="title"><Trans>Hesabım</Trans></span>
            <div id="user" className="d-flex">
                <img src="/assets/images/account_circle.svg" alt="" id="user-img" />
                <div id="details" className="w-100">
                    <span id="username">{store.user.name}</span>
                    <div id="telegram-container" className="d-flex align-items-start">
                        <img src="/assets/images/t_logo.svg" alt="" id="telegram-img" />
                        <div>
                            <a id="title2" className="d-block" target="8msTelegram" href={`https://t.me/sekizmilyarsalavatbot?start=` + store.user.user_uid}>telegram salavat bot</a>
                            <span id="telegram-bot-info"><Trans>BOT KODU</Trans>: {store.user.user_uid}</span>
                        </div>
                    </div>

                    <form ref={form} onSubmit={updateAccount}>
                        <FormInput name="name" label={t("Adınız Soyadınız")} required maxLength={100} defaultValue={store.user.name} />
                        <FormInput name="email" label={t("Email Adresiniz")} type="email" required maxLength={500} defaultValue={store.user.email} />
                        {/* <FormInput name="password" label="Şifreniz" type="password" required maxLength={100} /> */}
                        <FormInput key={i18n.language} name="country" parentRef={country} label={t("Ülke")} type="select" options={getCountries()} required defaultValue={store.user.country} onChange={e => setrefresh(refresh + 1)} />
                        {
                            country.current && country.current.value === "TR"
                                ? <FormInput name="city" label={t("İl")} type="select" options={regionsTR} required defaultValue={store.user.city} />
                                : null
                        }
                        <div>
                            <FormButton type="submit" color="primary" text={t("Güncelle")} sending={sending} />
                            {message && <Alert color="success fade" className="mt-2">{message}</Alert>}
                            {errorMessage && <Alert color="warning fade" className="mt-2">{errorMessage}</Alert>}
                            <hr />
                            <FormButton color="light" text={t("Üyelik İptal")} onClick={cancelMembership} sending={cancelling} /><br />
                            <FormButton color="light" text={t("Şifreyi Değiştir")} onClick={() => setshowChangePassword(true)} /><br />
                            <FormButton color="light" text={t("Çıkış")} onClick={logOut} />
                        </div>
                    </form>
                </div>
            </div>

            <Modal isOpen={showChangePassword}>
                <form onChange={() => {
                    if (password.current.value !== passwordConfirm.current.value)
                        passwordConfirm.current.setCustomValidity(t("Parola ve tekrarı aynı olmalıdır"));
                    else
                        passwordConfirm.current.setCustomValidity("");
                }} ref={form2} onSubmit={changePassword}>
                    <ModalHeader color="#0942bd"><Trans>ŞİFRE DEĞİŞTİRME</Trans></ModalHeader>
                    <ModalBody>
                        {/* <FormInput name="oldPassword" label="Mevcut Şifre" required maxLength={100} type="password" /> */}
                        <FormInput name="password" parentRef={password} label={t("Yeni Şifre")} required maxLength={100} type="password" autocomplete="new-password" />
                        <FormInput name="password_confirmation" parentRef={passwordConfirm} label={t("Yeni Şifre Tekrarı")} required maxLength={100} type="password" autocomplete="new-password" />
                    </ModalBody>
                    <ModalFooter>
                        <FormButton type="submit" color="primary" text={t("Onayla")} sending={passwordChanging} />
                        {/* <Button color="primary" type="submit">Onayla</Button> */}
                        <FormButton onClick={() => {
                            setshowChangePassword(false);
                        }} text={t("İptal")} color="light" />
                    </ModalFooter>
                    {/* {message2 && <Alert color="success" className="fade mr-3 ml-3">{message2}</Alert>} */}
                    {errorMessage2 && <Alert color="warning" className="fade mr-3 ml-3">{errorMessage2}</Alert>}
                </form>
            </Modal>
        </div>
    )
}

AccountCard.propTypes = {

}

export default observer(AccountCard)