import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './StatisticCard.scss';
import CountUp from 'react-countup';
import store from '../../../../store';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
// import i18n from "i18next";

const StatisticCard = props => {

    const { t, i18n } = useTranslation();

    return (
        <div id="statistic-card-container">
            <div className="d-flex justify-content-center">
                <div className="flex-grow-1 mr-2">
                    <span className="title"><Trans>Toplam</Trans></span>
                    <div className="item text-right">
                        <span className="title2"><Trans>Bugün</Trans></span>
                        <CountUp start={store.statisticsPrev.todays_salawats || 0} end={store.statistics.todays_salawats || 0} delay={0} separator=",">
                            {({ countUpRef }) => (
                                <span className="counter heart-s" ref={countUpRef} />
                            )}
                        </CountUp>
                    </div>
                    <div className="item text-right">
                        <span className="title2"><Trans>Bu Hafta</Trans></span>
                        <CountUp start={store.statisticsPrev.this_weeks_salawats || 0} end={store.statistics.this_weeks_salawats || 0} delay={0} separator=",">
                            {({ countUpRef }) => (
                                <span className="counter heart-s" ref={countUpRef} />
                            )}
                        </CountUp>
                    </div>
                    <div className="item text-right">
                        <span className="title2"><Trans>Bu Ay</Trans></span>
                        <CountUp start={store.statisticsPrev.this_months_salawats || 0} end={store.statistics.this_months_salawats || 0} delay={0} separator=",">
                            {({ countUpRef }) => (
                                <span className="counter heart-s" ref={countUpRef} />
                            )}
                        </CountUp>
                    </div>
                </div>
                <div className="flex-grow-1">
                    <span className="title"><Trans>Ortalama</Trans></span>
                    <div className="item text-right">
                        <span className="title2"><Trans>Günlük</Trans></span>
                        <CountUp start={store.statisticsPrev.avgDaily || 0} end={store.statistics.avgDaily || 0} delay={0} separator=",">
                            {({ countUpRef }) => (
                                <span className="counter heart-s" ref={countUpRef} />
                            )}
                        </CountUp>
                    </div>
                    <div className="item text-right">
                        <span className="title2"><Trans>Haftalık</Trans></span>
                        <CountUp start={store.statisticsPrev.avgWeekly || 0} end={store.statistics.avgWeekly || 0} delay={0} separator=",">
                            {({ countUpRef }) => (
                                <span className="counter heart-s" ref={countUpRef} />
                            )}
                        </CountUp>
                    </div>
                    <div className="item text-right">
                        <span className="title2"><Trans>Aylık</Trans></span>
                        <CountUp start={store.statisticsPrev.avgMonthly || 0} end={store.statistics.avgMonthly || 0} delay={0} separator=",">
                            {({ countUpRef }) => (
                                <span className="counter heart-s" ref={countUpRef} />
                            )}
                        </CountUp>
                    </div>
                </div>
            </div>
        </div>
    )
}

StatisticCard.propTypes = {

}

export default observer(StatisticCard);