import React from 'react';
import { Route } from 'react-router-dom';
import Menubar from '../components/menubar/Menubar';
import Footer from '../components/footer/Footer';
import ShareButtons from '../components/share-buttons/ShareButtons';

const PublicLayout = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <div>
                <div id="public-layout">
                    <Menubar />
                    <Component {...matchProps} />
                    {/* <ShareButtons /> */}
                    <Footer />
                </div>
            </div>
        )} />
    )
}

export default PublicLayout;