import React, { useState, useContext, useRef, useEffect } from 'react';
import { DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown, NavItem, NavLink, TabContent, TabPane, DropdownItem, Col, Card, CardTitle, CardText, Button, Input, Alert } from 'reactstrap';
import LoginForm from './LoginForm';
import './Menubar.scss';
import RegisterForm from './RegisterForm';
import utils from '../../utils';
import { Trans } from 'react-i18next';
import { MainContext } from '../../context/MainContext';
import { NavLink as NavLinkRouter } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import store from '../../store';
import { observer } from 'mobx-react';

const Menubar = () => {

    const { t, i18n } = useTranslation();
    const [activeTab, setActiveTab] = useState('1');

    const [errorMessage, seterrorMessage] = useState('');
    const [message, setmessage] = useState('');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    // const [lang, setLang] = useState(i18n.language);
    // const changeLang = (lang) => {
    //     i18n.changeLanguage(lang);
    //     //setLang(lang);
    // }

    const [isMenuOpen, setMenuOpen] = useContext(MainContext);

    const hideHamburgerMenu = () => {
        if (isMenuOpen)
            document.getElementById("burger").click();
    }

    var userDropdown = useRef();
    //var userDropdown2 = useRef();

    useEffect(() => {
        store.userDropdown = userDropdown;
        //store.userDropdown2 = userDropdown;
    }, []);

    return (
        <div id="menubar-container" className={isMenuOpen ? "menu-open" : ""}>
            <div className="d-flex align-items-center">
                <div className={"flex-grow-1 menu-logo"} style={{ height: 90 }}>
                    <NavLinkRouter to="/" exact={true}>
                        <img src={t("/assets/images/logo-tr.svg")} alt={t("8 Milyar Salavat")} id="logo" />
                    </NavLinkRouter>
                    <img src="/assets/images/muhammed-mozaik.svg" alt={t("8 Milyar Salavat")} id="muahmmed-mozaik" className="nme" />
                </div>
                <div className="d-flex align-items-center">
                    <div className="pb-2">
                        <ul className={`align-items-center user d-flex`}>
                            {global.languages.length > 1 && <UncontrolledDropdown nav inNavbar id="lang-dropdown" className="narrow">
                                <DropdownToggle nav id="lang-dropdown-toggle">
                                    {i18n.language.toUpperCase()}
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {global.languages.filter(l => i18n.language !== l).map(l =>
                                        <DropdownItem key={l} onClick={() => i18n.changeLanguage(l)} className="d-flex align-items-center mb-2">
                                            <span>{l.toUpperCase()}</span>
                                        </DropdownItem>
                                    )}
                                    {/* {i18n.language !== "tr" && <DropdownItem onClick={() => i18n.changeLanguage("tr")} className="d-flex align-items-center mb-2">
                                        <span>TR</span>
                                    </DropdownItem>}
                                    {i18n.language !== "ar" && <DropdownItem onClick={() => i18n.changeLanguage("ar")} className="d-flex align-items-center">
                                        <span>AR</span>
                                    </DropdownItem>} 
                                     {i18n.language !== "en" && <DropdownItem onClick={() => i18n.changeLanguage("en")} className="d-flex align-items-center">
                                        <span>EN</span>
                                    </DropdownItem>} */}
                                </DropdownMenu>
                            </UncontrolledDropdown>}
                            {store.token ?
                                <UncontrolledDropdown nav inNavbar id="user-dropdown">
                                    {/* <DropdownToggle nav className="d-flex align-items-center" id="user-dropdown-toggle"> */}
                                    <span className="d-flex align-items-center" id="user-dropdown-toggle">
                                        <NavLinkRouter to="/dashboard" exact={true} onClick={hideHamburgerMenu}>
                                            <span>{store.user.name}</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs></defs><g className="a"><path className="b" d="M0,0H24V24H0Z" /><path className="c" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM7.07,18.28c.43-.9,3.05-1.78,4.93-1.78s4.51.88,4.93,1.78a7.925,7.925,0,0,1-9.86,0Zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36,2.33a8,8,0,1,1,12.72,0ZM12,6a3.5,3.5,0,1,0,3.5,3.5A3.491,3.491,0,0,0,12,6Zm0,5a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,11Z" /></g></svg>
                                        </NavLinkRouter>
                                    </span>
                                    {/* </DropdownToggle> */}
                                </UncontrolledDropdown>
                                : <UncontrolledDropdown nav inNavbar id="user-dropdown" ref={userDropdown}>
                                    <DropdownToggle nav className="d-flex align-items-center" id="user-dropdown-toggle">
                                        <span><Trans>Giriş Yap</Trans></span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs></defs><g className="a"><path className="b" d="M0,0H24V24H0Z" /><path className="c" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM7.07,18.28c.43-.9,3.05-1.78,4.93-1.78s4.51.88,4.93,1.78a7.925,7.925,0,0,1-9.86,0Zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36,2.33a8,8,0,1,1,12.72,0ZM12,6a3.5,3.5,0,1,0,3.5,3.5A3.491,3.491,0,0,0,12,6Zm0,5a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,11Z" /></g></svg>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <div className="text-center">
                                            <img src="/assets/images/muahmmed-mozaik-yarim.svg" alt="" />
                                            <Nav tabs className="d-flex">
                                                <NavItem className="flex-grow-1">
                                                    <NavLink
                                                        className={`${activeTab === '1' && "active"}`}
                                                        onClick={() => { toggle('1'); }}>
                                                        <Trans>Giriş Yap</Trans>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem className="flex-grow-1">
                                                    <NavLink
                                                        className={`${activeTab === '2' && "active"}`}
                                                        onClick={() => { toggle('2'); }}>
                                                        <Trans>Yeni Üyelik</Trans>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <TabContent activeTab={"1"}>
                                            <TabPane tabId="1">
                                                {activeTab === "1" ? <LoginForm parent={{ setmessage, seterrorMessage, hideHamburgerMenu }} /> : <RegisterForm parent={{ setmessage, seterrorMessage, setActiveTab, hideHamburgerMenu }} />}
                                            </TabPane>
                                        </TabContent>

                                        {message && <Alert color="success" className="mt-2"><span dangerouslySetInnerHTML={{ __html: message }}></span></Alert>}
                                        {errorMessage && <Alert color="warning" className="mt-2"><span dangerouslySetInnerHTML={{ __html: errorMessage }}></span></Alert>}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            }
                        </ul>
                    </div>
                    <div id="hamburger-menu-container">
                        <Input type="checkbox" id="burger" value="off" className="menu" onChange={(e) => setMenuOpen(e.target.checked)} />

                        <label htmlFor="burger">
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>

                        <nav>
                            <div className="menu-logo">
                                <NavLinkRouter to="/" exact={true} onClick={hideHamburgerMenu}>
                                    <img src={t("/assets/images/logo-tr.svg")} alt={t("8 Milyar Salavat")} id="logo" />
                                </NavLinkRouter>
                                <img src="/assets/images/muhammed-mozaik.svg" alt={t("8 Milyar Salavat")} id="muahmmed-mozaik" className="nme" />
                            </div>

                            <ul className="menu">
                                <li onClick={hideHamburgerMenu}>
                                    <NavLinkRouter activeClassName='active' to="/" exact={true}> <Trans>Anasayfa</Trans></NavLinkRouter>
                                </li>
                                <li onClick={hideHamburgerMenu}>
                                    <NavLinkRouter activeClassName='active' to="/biz-kimiz" exact={true}> <Trans>Biz kimiz</Trans></NavLinkRouter>
                                </li>
                                <li onClick={hideHamburgerMenu}>
                                    <NavLinkRouter activeClassName='active' to="/salavat-hakkinda" exact={true}> <Trans>Salavat Hakkında0</Trans></NavLinkRouter>
                                </li>
                                <li onClick={hideHamburgerMenu}>
                                    <NavLinkRouter activeClassName='active' to="/s.s.s." exact={true}> <Trans>S.S.S.</Trans></NavLinkRouter>
                                </li>
                                <li onClick={hideHamburgerMenu}>
                                    <a href="mailto:bilgi@8milyarsalavat.com"> <Trans>İletişim</Trans></a>
                                    {/* <NavLinkRouter activeClassName='active' to="/iletisim" exact={true}> <Trans>İletişim</Trans></NavLinkRouter> */}
                                </li>
                            </ul>
                        </nav>
                    </div>
                    {/* <img src="/assets/images/menu.svg" alt="" id="hamburger-menu" /> */}
                </div>
            </div>
        </div>
    )
}

export default observer(Menubar);