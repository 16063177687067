import React from 'react';
import PropTypes from 'prop-types';
import './FormInput.scss';
import utils from '../../utils';
import _ from 'lodash';
import { useState } from 'react';


const FormInput = props => {
    const [value, setvalue] = useState('');
    const _props = _.clone(props);
    delete _props.options;
    delete _props.label;
    delete _props.parentRef;
    return <div className={`form-input-container ${props.type === "textarea" ? "no-height" : ""}`}>
        {
            props.type === "select"
                ? <>
                    <label>{props.label}</label>
                    {/* <select name={props.name} required={props.required || false} value={props.value} defaultValue={props.defaultValue}> */}
                    <select {..._props} ref={props.parentRef}>
                        {
                            !_.isEmpty(props.options) && _.isArray(props.options) && props.options.map((item, index) => {
                                let value = _.has(item, "value") ? item.value : item;
                                let text = _.has(item, "text") ? item.text : item;
                                // if (props.defaultValue && value === props.defaultValue)
                                //     return <option key={index} value={value} selected>{text}</option>;
                                // else
                                    return <option key={index} value={value}>{text}</option>;
                            })
                        }
                    </select>
                </>
                : props.type === "textarea"
                    ? <>
                        <label>{props.label}</label>
                        <textarea autoComplete="off" rows="5" {..._props} ref={props.parentRef} />
                    </>
                    : <>
                        <label>{props.label}</label>
                        <input autoComplete="off" {..._props} ref={props.parentRef} />
                    </>
        }
    </div>
}

FormInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    options: PropTypes.array,
    required: PropTypes.bool,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    maxLength: PropTypes.number,
    parentRef: PropTypes.object,
}

export default FormInput;