import { useEffect } from 'react';
import store from '../../store';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router';

const Confirmation = props => {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        localStorage.setItem("name", query.get("name"));
        localStorage.setItem("email", query.get("email"));
        store.showAddingConfirmationCount = query.get("count");
        history.push("/");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}

export default observer(Confirmation);