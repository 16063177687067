import React, { useContext, useState } from 'react';
import './SalavatAbout.scss';
import { MainContext } from '../../../context/MainContext';
import { Trans } from 'react-i18next';
import { observer } from 'mobx-react';

const SalavatAbout = () => {

    const [isMenuOpen, setMenuOpen] = useContext(MainContext);

    return (
        <div id="salavat-about-container" className={!isMenuOpen && "z-index-4"}>
            <div id="section1">
                <span id="title" className="d-none d-sm-block">
                    <Trans i18nKey="Salavat Hakkında">
                        <span className="heart-s mr-3">Salavat</span> Hakkında
                    </Trans>
                </span>
                <span id="description" className="d-none d-sm-block">
                    <Trans i18nKey="SalavatNedir1">
                        <span className="heart-s mr-3">Salavat</span> nedir?
                        Nasıl <span className="heart-s mr-3">salavat</span> getirilir?
                        Neden <span className="heart-s mr-3">salavat</span> getiririz?
                    </Trans>
                    {/* <br /> <span className="heart-s mr-3">Salavat</span> ile ilgili ayet ve hadisler… */}
                </span>
                <div className="info-card">
                    <span className="title">
                        <Trans i18nKey="SalavatNedir0"><span className="heart-s mr-3">Salavat</span> Nedir?</Trans>
                    </span>
                    <span className="subtitle"><Trans>SalavatDua1</Trans><sup>1</sup></span>
                    <p>
                        <Trans>AyetiKerime1</Trans>
                        {/* Ayet-i Kerime de <i>“Allah ve melekleri Peygambere salât ediyorlar. Ey iman edenler! Siz de ona salât edin ve onu tam bir teslimiyetle selâmlayın”</i> <sup>2</sup> buyurulur. */}
                    </p>
                    <p>
                        <Trans>BuAyetiKerimedeki1</Trans>
                        {/* Bu ayeti kerimedeki Allah(cc)’ın Peygamber(sav)'e salâtı; O’na rahmet etmesi ve O’nu melekleri katında övmesi, Meleklerin salâtı; Peygamber (sav) için istiğfarda bulunmaları, Müminlerin salâtı ise Allah(cc)’tan Peygamber (sav)'in kendi katındaki makamını yüceltmesi için dua etmeleridir. */}
                    </p>
                    <span className="source">
                        <Trans>RagaibEl1</Trans>
                        {/* (1) Râgıb el-İsfahânî, el-Müfredât, “ṣlv” md.; İbn Kayyim el-Cevziyye, s. 155-156; Fîrûzâbâdî, s. 6-7<br />
                        (2) el-Ahzâb 33/56; ayrıca bk. el-Bakara 2/157; et-Tevbe 9/99, 103; el-Ahzâb 33/43 */}
                    </span>
                </div>
            </div>
            <div id="section2">
                <div className="info-card">
                    <span className="title">
                        <Trans i18nKey="Neden Salavat Getiririz">
                            Neden <span className="heart-s mr-3">Salavat</span> Getiririz?
                        </Trans>
                    </span>
                    <p>
                        <Trans>Abdullah b Mes'ûd'dan</Trans>
                        {/* Abdullah b. Mes'ûd'dan rivayet edildiğine göre, Resûlullah (sav) şöyle buyurmuştur: <b>“Kıyamet günü insanların bana en yakını, bana en çok salavât getirendir.”</b> <sup>1</sup> */}
                    </p>
                    <p>
                        <Trans>Ali b Ebû Tâlib'in</Trans>
                        {/* Ali b. Ebû Tâlib'in naklettiğine göre, Resûlullah (sav) şöyle buyurmuştur: <b>“Cimri, yanında anıldığım hâlde bana salavât getirmeyen kimsedir.”</b> <sup>2</sup> */}
                    </p>
                    <p>
                        <Trans>Ebû Hüreyre'nin</Trans>
                        {/* Ebû Hüreyre'nin naklettiğine göre, Resûlullah (sav) şöyle buyurmuştur: <b>“Evlerinizi kabirlere çevirmeyin. Benim kabrimi de bayram yeri hâline getirmeyin. Bana salavât getirin. Çünkü nerede olursanız olun, salavâtınız bana ulaşır.”</b> <sup>3</sup> */}
                    </p>
                    <p>
                        <Trans>Ebû Hureyre'den</Trans>
                        {/* Ebû Hureyre'den rivayet edildiğine göre, Resûlullah (sav) şöyle buyurmuştur: <b>“Bana bir kez salavât getirene Allah on kez salavât getirir (rahmet eyler).”</b> <sup>4</sup> */}
                    </p>
                    <span className="source">
                        <Trans>Tirmizî, Vitr, 21 484(2)</Trans>
                        {/* (1)Tirmizî, Vitr, 21. 484 <br />
                        (2) Tirmizî, Deavât, 100. 3546<br />
                        (3) Ebû Dâvûd, Menâsik, 96, 97. 2042<br />
                        (4) Müslim, Salât, 70. 912 */}
                    </span>
                </div>
            </div>
            <div id="section3">
                <div className="info-card">
                    <span className="title">
                        <Trans i18nKey="Salavat Nasıl Getirilir?">
                            <span className="heart-s mr-3">Salavat</span> Nasıl Getirilir?
                        </Trans>
                    </span>
                    <p>
                        <Trans>KabbUcre</Trans>
                        {/* Kâ'b b. Ucre, benimle karşılaşınca, 'Sana bir hediye vereyim mi?' dedi (ve şöyle devam etti):
                        Bir gün Peygamber (sav) yanımıza geldi. Biz, 'Ey Allah'ın Resûlü! Sana nasıl selâm vereceğimizi öğrendik. Peki, sana nasıl salavât getireceğiz? dedik. */}
                    </p>
                    <p>
                        <Trans>Allah Resûlü de şöyle buyurdu</Trans>
                        {/* Allah Resûlü de şöyle buyurdu:
                        <br />
                        “Allah'ım! Muhammed'e ve Muhammed ailesine, tıpkı İbrâhim ailesine rahmet eylediğin gibi rahmet et.Şüphesiz sen, övgüye en lâyık ve şanı en yüce olansın.
                        Allah'ım! Muhammed'e ve Muhammed ailesine, tıpkı İbrâhim ailesine bereket ihsan ettiğin gibi bereket ihsan eyle!Şüphesiz sen övgüye en lâyık ve şanı en yüce olansın.' deyin.” <sup>1</sup> (Salli ve Bârik duaları) */}
                    </p>
                    <p>
                        <Trans>Birçok şekilde salavat getirilebilir</Trans>
                        {/* Birçok şekilde salavat getirilebilir.
                        <br />
                        En kısa şekliyle: “Âllâhümme salli alâ Muhammed (Allâhım Muhammed’i rahmetinle tebrik et ve esen kıl)" diye salât getirebilir. */}
                    </p>
                    <span className="source">
                        <Trans>Buhari32</Trans>
                        {/* (1) Buhârî, Deavât, 32; 6357. Müslim, Salât, 66. 908 */}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default observer(SalavatAbout);