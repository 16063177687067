import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tiny-fab/dist/styles.css';
import './i18n'
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configure } from 'mobx';
import Test2 from './Test2';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

configure({
  enforceActions: "never",
})

ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey="6Lf2WjEhAAAAADwQIlDFJfkWGyBTDEfmFLJEFPV1"
    //language="[optional_language]"
    //useRecaptchaNet="[optional_boolean_value]"
    useEnterprise={true}
    // scriptProps={{
    //   async: false, // optional, default to false,
    //   defer: false, // optional, default to false
    //   appendTo: 'head', // optional, default to "head", can be "head" or "body",
    //   nonce: undefined // optional, default undefined
    // }}
  >
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
