import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import './SendSalavatCard.scss';
import CountUp from 'react-countup';
import store from '../../../../store';
import { Alert, Input } from 'reactstrap';
import { observer } from 'mobx-react';
import utils from '../../../../utils';
import { Trans, useTranslation } from 'react-i18next';
import axios from 'axios';
import _ from 'lodash';
import NumberFormat from 'react-number-format';

const SendSalavatCard = props => {
    const form = React.useRef();

    const [errorMessage, seterrorMessage] = useState('');
    const [message, setmessage] = useState('');
    const [count, setcount] = useState(1);
    const [amountManuel, setamountManuel] = useState(1);
    const [showManuel, setshowManuel] = useState(false);
    const { t, i18n } = useTranslation();
    const [sending, setsending] = useState(false);


    useEffect(() => {
        store.refreshUserStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function send(e, force) {
        e.preventDefault();

        // store.message = <div className="sukranDiv">
        //     Değerli Salâvat gönüllüsü,<br />
        //     1445 yılı 8 Milyar Salavat kampanyamız sona ermiştir. <br />
        //     Bir sonraki kampanya dönemi için salavat girişleri en yakın zamanda tekrar başlayacaktır.<br />
        // </div>;
        // return;

        seterrorMessage("");
        setmessage("");

        var formData = utils.formSerialize(form.current);
        const count = showManuel ? amountManuel : parseInt(formData.extra_salavat);

        if (count < 0 || isNaN(count)) {
            return;
        }

        const data = { amount: count };

        if (!store.confirmSending(count)) {
            _.delay(() => setmessage(t("Salvat eklemesi yapılmadı")), 100);
            return;
        }

        setsending(true);

        data.recaptchaToken = await store.getRecaptchaToken();
        // if (!formData.recaptchaToken) {
        //     store.error = t("Recaptcha_error");
        //     setsending(false);
        //     return;
        // }

        axios.post(`${utils.ApiAddress}/api/salawat/add`, data, { headers: { "Authorization": "Bearer " + store.token } })
            .then(response => {
                if (response.data.code >= 400 || !response.data.message.statistics) {
                    props.parent.seterrorMessage(utils.getApiError(response));
                } else {
                    setmessage(t("salavat eklendi", { "0": utils.formatNumber(count) }));
                    store.refreshStats();
                    store.refreshUserStats();
                    store.setNewTotalCurrent(response.data.message.statistics.general.total_salawats);
                    store.setPrevSendedCount(count);
                }
            })
            .catch(error => {
                console.log(error);
                seterrorMessage(utils.getAxiosErrorMessage(error));
            })
            .finally(() => {
                setamountManuel("");
                setsending(false);
            });

        // axios.put(`https://8milyarsalavat.com/api/profile`, data, { headers: { "X-Salavat-Auth-Token": store.token } })
        //     .then(response => {
        //         if (response.data.error === true) {
        //             seterrorMessage(response.data.message)
        //         } else if (response.data.errorMessage) {
        //             seterrorMessage(response.data.errorMessage)
        //         } else {
        //             setmessage(utils.formatNumber(count) + " salavat eklendi");
        //             store.refreshStats();
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error);
        //         seterrorMessage(utils.getAxiosErrorMessage(error));
        //     })
        //     .finally(() => setsending(false));
    }

    return (
        <div id="send-salavat-card-container">
            <div className="text-center">
                <span id="title"><Trans>Bugüne Kadar</Trans></span>
                <CountUp start={store.statisticsPrev.total || 0} end={store.statistics.total || 0} delay={0} separator=",">
                    {({ countUpRef }) => (
                        <span id="total-count" className="heart-l" ref={countUpRef}></span>
                    )}
                </CountUp>
            </div>
            <form className="text-center" ref={form} onSubmit={send}>
                <Input type="select" name="extra_salavat"
                    onChange={e => {
                        if (e.target.value === "-1") {
                            setshowManuel(true);
                            setcount(e.target.value);
                        }
                        else if (e.target.value === "0") {
                            store.showMoreModal = true;
                        }
                        else {
                            setshowManuel(false);
                            setcount(e.target.value);
                        }
                    }}
                    value={count}>
                    <option value={-1}>{t("özel sayı")}</option>
                    <option value={1}>+1 {t("Salavat")}</option>
                    <option value={100}>+100 {t("Salavat")}</option>
                    <option value={300}>+300 {t("Salavat")}</option>
                    <option value={500}>+500 {t("Salavat")}</option>
                    <option value={1000}>+1.000 {t("Salavat")}</option>
                    <option value={5000}>+5.000 {t("Salavat")}</option>
                    <option value={10000}>+10.000 {t("Salavat")}</option>
                    <option value={20000}>+20.000 {t("Salavat")}</option>
                    <option value={50000}>+50.000 {t("Salavat")}</option>
                    <option value={100000}>+100.000 {t("Salavat")}</option>
                    <option value={500000}>+500.000 {t("Salavat")}</option>
                    <option value={1000000}>+1.000.000 {t("Salavat")}</option>
                    {/* <option value={0}>{t("Daha fazla ekle...")}</option> */}
                </Input>
                {showManuel && <NumberFormat
                    min={1}
                    max={100000}
                    value={amountManuel}
                    thousandSeparator={true}
                    allowNegative={false}
                    className="form-control"
                    isAllowed={(values) => {
                        const { floatValue } = values;
                        return floatValue == null || floatValue == undefined || (floatValue >= 1 && floatValue <= 100000);
                    }}
                    onChange={e => {
                        setamountManuel(e.target.value);
                    }} />
                /*<Input type="number"
                    value={amountManuel}
                    min={1}
                    max={100000}
                    onChange={e => {
                        setamountManuel(e.target.value);
                    }} />*/}
                <button type="submit" className={sending ? "progress-bar-animated progress-bar-striped" : ""} disabled={sending || store.ended}>
                    <Trans i18nKey="salavat ekle1">
                        <span className="heart-s mr-3">salavat</span> ekle
                    </Trans>
                </button>
                {message && <Alert color="success fade" className="mt-2">{message}</Alert>}
                {errorMessage && <Alert color="warning fade" className="mt-2">{errorMessage}</Alert>}
            </form>
        </div>
    )
}

SendSalavatCard.propTypes = {
}

export default observer(SendSalavatCard);