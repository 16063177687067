import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, Button, Alert, Spinner, Modal, ModalHeader } from 'reactstrap';
import { Trans, useTranslation } from "react-i18next";
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router';
import _ from 'lodash';
import utils from './utils';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Test2 = props => {

  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t, i18n } = useTranslation();
  const [recaptchaLoaded, setrecaptchaLoaded] = useState(false);
  const [recaptchaToken, setrecaptchaToken] = useState(null);
  const reCaptchaRef = React.useRef();

  const refreshRecaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('send');
    setrecaptchaToken(token);
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    refreshRecaptchaToken();
  }, [refreshRecaptchaToken]);

  return (
    <>
      {recaptchaToken}
      <br/>
      {recaptchaLoaded.toString()}
      <br/>
      <Button onClick={()=> setrecaptchaLoaded(!recaptchaLoaded)}>
        <Trans i18nKey="Salavat Gönder"><span className="heart-l mr-4">Salavat</span> Gönder</Trans>
      </Button>
    </>
  )
}

export default observer(Test2);