import React, { useContext, useEffect } from 'react'
import { Col, Row } from 'reactstrap';
import { MainContext } from '../../../context/MainContext';
import AccountCard from './account-card/AccountCard';
import './Dashboard.scss';
import SendSalavatCard from './send-salavat-card/SendSalavatCard';
import StatisticCard from './statistic-card/StatisticCard';
import store from '../../../store'
import { observer } from 'mobx-react';
import axios from 'axios';

const Dashboard = () => {
    const [isMenuOpen, setMenuOpen] = useContext(MainContext);

    useEffect(() => {
        store.refreshStats();
    }, []);

    if (!store.token)
        return null;
    else
        return (
            <div id="dashboard-container" className={!isMenuOpen && "z-index-4"}>
                <div id="gradient-div"></div>
                <Row>
                    <Col>
                        <AccountCard />
                    </Col>
                    <Col>
                        <SendSalavatCard />
                    </Col>
                    <Col>
                        <StatisticCard />
                    </Col>
                </Row>
            </div>
        )
}

export default observer(Dashboard);
