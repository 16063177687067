import _ from "lodash"

export default class utils {

    static ApiAddress = "https://api.8milyarsalavat.com";

    static RecaptchaKey = "6LdRoDIhAAAAABS8hEIWaH8O2X_fhHZX0DEUpjNs";
    //static RecaptchaKey = "6Lf2WjEhAAAAAC7SHSR-8bLFTgEDF6nIDAewwFVQ";//legacy

    static is8Billion = !window.location.hostname.match(/8milyarsalavat/i);

    static get isDev() { return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'; }

    static logDev() {
        if (utils.isDev) {
            console.log(...arguments);
        }
    }

    //call with await
    static sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    static formatNumber(number) {
        if (!number)
            return number;

        try {
            return typeof number === "string" ? parseInt(number).toLocaleString() : number.toLocaleString();
        } catch (error) {
            return number;
        }
    }

    // static isValidEmail = (email) => {
    //     const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     return re.test(String(email).toLowerCase());
    // }

    static isNullOrWhiteSpace = (str) => {
        str = str || "";
        return str.replace(/^\s+|\s+$/, "").length === 0;
    }


    static formSerialize(form) {
        let data = {};
        form.querySelectorAll('[name]').forEach((elem) => {
            if (elem.name && elem.value)
                data[elem.name] = elem.value;
        });
        return data;
    }

    static getAxiosErrorMessage(error) {
        var result = utils.getApiError(error.response);
        return result || (error.response && error.response.data && (error.response.data.errorMessage || error.response.data.data || error.response.data.message)) || error.message;
    }

    static getApiError(response) {
        if (response && response.data) {
            if (_.isObject(response.data.message)) {
                let errors = response.data.message;
                if (_.isObject(response.data.message.errors))
                    errors = response.data.message.errors;

                var result = "- " + Object.keys(errors).map(key => {
                    return errors[key].map(err => err.includes(key) ? err : key + ": " + err);
                }).join("<br/>- ");
                return result;
            } else if (_.isString(response.data) && !_.isEmpty(response.data)) {
                return response.data;
            } else if (_.isString(response.data.message) && !_.isEmpty(response.data.message)) {
                return response.data.message;
            }
        }

        return "An error occured";
    }

    // static hasProp(obj, propName) {
    //     return _.isObject(obj) && propName && propName in obj;
    // }
}