import React from 'react'
import PropTypes from 'prop-types';
import './SocialSection.scss';
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import utils from '../../../../utils';

const SocialSection = props => {
    const { t, i18n } = useTranslation();

    return (
        <div id="social-section-container">
            <div className="d-flex flex-column h-100">
                <div className="d-flex flex-column align-items-center" id="text-container">
                    <span id="title"><Trans>Paylaş ve Sevabını Katla</Trans></span>
                    <span id="description">
                        <Trans i18nKey="Sen de ecrine">
                            Sen de <strong className="heart-s mr-3">8 Milyar Salavat</strong> çalışmamızı sosyal medya hesaplarından dostlarınla paylaşarak ecrine ecir katabilirsin.
                        </Trans>
                    </span>
                    <div id="social-media">
                        <WhatsappShareButton url={utils.is8Billion ? "https://www.8billionsalawat.com/" : "https://8milyarsalavat.com/"} title={t("8 Milyar Salavat")}>
                        <img src="/assets/images/social/whatsapp.svg" alt="WhatsApp" />
                    </WhatsappShareButton>
                    <EmailShareButton url={utils.is8Billion ? "https://www.8billionsalawat.com/" : "https://8milyarsalavat.com/"} title={t("8 Milyar Salavat")} subject={t("8 Milyar Salavat")} body={t("Dünyada yaşayan insanlar")}>
                        <img src="/assets/images/social/email.svg" alt="Email" />
                    </EmailShareButton>
                    <TwitterShareButton url={utils.is8Billion ? "https://www.8billionsalawat.com/" : "https://8milyarsalavat.com/"} title={t("8 Milyar Salavat")}>
                        <img src="/assets/images/social/twitter.png" alt="Twitter" />
                    </TwitterShareButton>
                    <FacebookShareButton url={utils.is8Billion ? "https://www.8billionsalawat.com/" : "https://8milyarsalavat.com/"} title={t("8 Milyar Salavat")} quote={t("Dünyada yaşayan insanlar")}>
                        <img src="/assets/images/social/facebook.svg" alt="Facebook" />
                    </FacebookShareButton>
                </div>
                <div id="hadis">
                    <Trans i18nKey="Müslim, İmare, 133">
                        <span>"Bir iyiliğe öncülük eden kimseye o iyiliği yapanın ecri gibi sevap vardır."</span>
                        <small>(Müslim, İmare, 133)</small>
                    </Trans>
                </div>
            </div>
            <img src="/assets/images/mescidi_nebevi.png" alt={t("Mescid-i Nebevi")} className="w-100" />
        </div>
        </div >
    )
}

export default observer(SocialSection);