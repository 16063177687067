import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import utils from '../../../../utils';
import './LiveSection.scss';
import store from '../../../../store';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import { countriesByCode } from '../../../../consts';

const LiveSection = props => {
    const { t, i18n } = useTranslation();

    return (
        <div id="live-section-container">
            <div id="header">
                <span id="title-arabic">اللهم صل على سيدنا محمد</span>
                <span id="title"><Trans i18nKey="Salavat Canlı"><span className="heart-l mr-4">Salavat</span> Gönderenler Canlı</Trans></span>
                <div id="content">
                    {!store.liveStats
                        ? <Spinner />
                        : store.liveStats && store.liveStats.filter(i => i.name && i.countryCode && i.count).map((item, i) =>
                            <div key={i} className={`item ${i % 2 === 1 ? "opacity" : ""}`}>
                                <span className="name">{item.name} ({t(countriesByCode[item.countryCode]) || item.countryCode})
                                <span className="heart-s ml-2 mr-4">{utils.formatNumber(item.count)}</span></span>
                            </div>
                        )}
                </div>
            </div>
        </div>
    )
}

export default observer(LiveSection);