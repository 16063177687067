import React from 'react';
import './MapSection.scss';
import CountUp from 'react-countup';
import { observer } from 'mobx-react';
import store from '../../../../store';
import { Trans } from 'react-i18next';

const MapSection = props => {
    return (
        <div id="map-section-container">
            <div id="map-item" className="mx-auto d-flex flex-column justify-content-end">
                <div className="title text-center mb-2">
                    {store.countries.length && <CountUp start={store.countriesPrevLength} end={store.countries.length} delay={0} separator=",">
                        {({ countUpRef }) => (
                            <span className="mr-2 bold" ref={countUpRef} />
                        )}
                    </CountUp>}
                    <span className="mr-5 heart-multi"><Trans>Ülke2</Trans></span>
                    <span className="mr-2 bold"><Trans>Türkiye</Trans></span>
                    <CountUp start={store.TRPrevCount} end={(store.countries.find(c=>c.country==="TR") || {}).salavatCount || 0} delay={0} separator=",">
                        {({ countUpRef }) => (
                            <span ref={countUpRef} className="heart-s" />
                        )}
                    </CountUp>
                </div>
                {/* <iframe title="Harita" src="https://8milyarsalavat.azurewebsites.net/harita.html"></iframe> */}
                <iframe title="Harita" src="harita.html?3"></iframe>
            </div>
        </div>
    )
}

export default observer(MapSection);