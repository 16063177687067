import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Alert, Col, Row } from 'reactstrap';
import FormButton from '../../../components/form-button/FormButton';
import FormInput from '../../../components/form-input/FormInput';
import { MainContext } from '../../../context/MainContext';
import utils from '../../../utils';
import store from '../../../store';
import './ResetPassword.scss';
import { observer } from 'mobx-react';
import _ from 'lodash';

const ResetPassword = (props) => {

    const [isMenuOpen, setMenuOpen] = useContext(MainContext);
    const [errorMessage, seterrorMessage] = useState('');
    // const [message, setmessage] = useState('');
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [sending, setsending] = useState(false);
    const form = useRef();
    const password_confirmation = React.useRef();

    useEffect(() => {
        if (_.isNil(props.match.params.token))
            store.error = t("Token boş olmamalı.");

        axios.get(`${utils.ApiAddress}/api/password/reset/`+props.match.params.token)
        .catch(error => {
            store.error = t("Token yanlış veya zaten kullanılmış");
            store.errorOnClosed = () => {
                history.push('/');
            };
        });
    }, [props.match.params.token, history, t]);

    const send = (e) => {
        e.preventDefault();
        seterrorMessage("");
        var formData = utils.formSerialize(form.current);
        setsending(true);
        axios.post(`${utils.ApiAddress}/api/password/reset`, formData)
            .then(response => {
                if (response.data.code >= 400) {
                    seterrorMessage(utils.getApiError(response));
                } else {
                    store.logOut();
                    store.message = t("Şifreniz değiştirildi");
                    store.messageOnClosed = () => {
                        history.push('/');
                        store.openUserLogin();
                    };
                }
            })
            .catch(error => {
                console.log(error)
                seterrorMessage(utils.getAxiosErrorMessage(error));
            })
            .finally(() => setsending(false));
    }

    const formChange = (e) => {
        var formData = utils.formSerialize(form.current);

        if (formData.password !== formData.password_confirmation) {
            password_confirmation.current.setCustomValidity(t("Şifre ve tekrarı aynı olmalıdır"));
        }
        else {
            password_confirmation.current.setCustomValidity("");
        }
    }

    return (
        <div id="contact-container" className={`vh-100 ${!isMenuOpen && "z-index-4"}`}>
            <div className="info-card">
                <span className="title">Şifre Yenileme</span>
                <form ref={form} onChange={formChange} onSubmit={send}>
                    <FormInput name="password" label={t("Yeni şifreniz")} type="password" required maxLength={100} />
                    <FormInput name="password_confirmation" label={t("Şifre tekrarı")} type="password" required maxLength={100} parentRef={password_confirmation} />
                    <input type="hidden" name="token" value={props.match.params.token} />
                    <div className="text-right">
                        <FormButton type="submit" color="primary" text={t("Onayla")} sending={sending} />
                    </div>
                    {errorMessage && <Alert color="warning fade" className="mt-2">{errorMessage}</Alert>}
                </form>
            </div>
        </div>
    )
}

export default observer(ResetPassword);