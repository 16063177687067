import axios from 'axios';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Alert } from 'reactstrap';
import store from '../../store';
import utils from '../../utils';
import FormButton from '../form-button/FormButton';
import FormInput from '../form-input/FormInput';

const LoginForm = props => {

    const [sending, setsending] = useState(false);
    const { t, i18n } = useTranslation();
    const formRef = useRef();
    const formForgetRef = useRef();
    const [showPasswordForget, setShowPasswordForget] = useState(false);
    const history = useHistory();
    const passwordRef = useRef();

    useEffect(() => {
        if (store.emailConfirmed) {
            store.emailConfirmed = null;
            _.delay(() => {
                store.openUserLogin();
                passwordRef.current.focus();
            }, 100);
            props.parent.setmessage(t("E-postanız doğrulanmıştır. Şimdi giriş yapabilirsiniz."));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function login(e) {
        e.preventDefault();
        props.parent.seterrorMessage("");
        props.parent.setmessage("");
        setsending(true);

        const data = { ...utils.formSerialize(formRef.current), "language": i18n.language }
        axios.post(`${utils.ApiAddress}/api/login`, data)
            .then(response => {
                if (response.data.code >= 400) {
                    props.parent.seterrorMessage(utils.getApiError(response));
                } else if (response.data.token) {
                    let _token = response.data.token;
                    axios.get(`${utils.ApiAddress}/api/profile`, { headers: { "Authorization": "Bearer " + _token } })
                        .then(response => {
                            store.user = response.data.profile;
                            store.user.country = store.user.country && store.user.country.toUpperCase();
                            store.token = _token;
                            store.saveUserToLocalStorage();
                            //anonym user bilgileri siliniyor ki aynı anda kayıtlı ve anonym user bilgisi aynı cihazda olup karışmasın
                            localStorage.removeItem("name");
                            localStorage.removeItem("email");
                            props.parent.hideHamburgerMenu();
                            if (store.afterLoginGoToProfile) {
                                store.afterLoginGoToProfile = false;
                                history.push("/dashboard");
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            alert(utils.getAxiosErrorMessage(error));
                        });
                }
            })
            .catch(error => {
                console.log(error)
                let errorMessage = utils.getAxiosErrorMessage(error);
                if (errorMessage === "Check your email and password") {
                    errorMessage = t("E-posta veya şifre yanlıştır");
                }
                props.parent.seterrorMessage(errorMessage);
            })
            .finally(() => setsending(false));

        // axios.post(`https://8milyarsalavat.com/api/login`, utils.formSerialize(formRef.current))
        //     .then(response => {
        //         if (response.data.error === true) {
        //             props.parent.seterrorMessage(response.data.data)
        //         } else if (response.data.errorMessage) {
        //             props.parent.seterrorMessage(response.data.errorMessage)
        //         } else if (response.data.authToken) {
        //             const user = { ...response.data };
        //             delete user.authToken;
        //             store.user = user;
        //             store.token = response.data.authToken;
        //             store.saveUserToLocalStorage();
        //             props.parent.hideHamburgerMenu();
        //             if (store.afterLoginGoToProfile) {
        //                 store.afterLoginGoToProfile = false;
        //                 history.push("/dashboard");
        //             }                    
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error)
        //         props.parent.seterrorMessage(utils.getAxiosErrorMessage(error));
        //     })
        //     .finally(() => setsending(false));
    }

    function forgetPwd(e) {
        e.preventDefault();
        props.parent.seterrorMessage("");
        props.parent.setmessage("");
        setsending(true);

        const data = { ...utils.formSerialize(formForgetRef.current), "language": i18n.language }
        axios.post(`${utils.ApiAddress}/api/password/reset/request`, data)
            .then(response => {
                if (response.data.code >= 400) {
                    props.parent.seterrorMessage(utils.getApiError(response));
                } else {
                    props.parent.seterrorMessage("");
                    store.message = t("Girmiş olduğunuz e-posta adresine şifre sıfırlama gönderilmiştir");
                    store.messageOnClosed = () => store.openUserLogin();
                    setShowPasswordForget(false);
                }
            })
            .catch(error => {
                console.log(error);
                props.parent.seterrorMessage(utils.getAxiosErrorMessage(error));
            })
            .finally(() => setsending(false));

        // axios.post(`https://8milyarsalavat.com/api/lostpwd`, data)
        //     .then(response => {
        //         if (response.data.error === true) {
        //             props.parent.seterrorMessage(response.data.message)
        //         } else if (response.data.errorMessage) {
        //             props.parent.seterrorMessage(response.data.errorMessage)
        //         } else {
        //             props.parent.seterrorMessage("");                    
        //             store.message = t("Girmiş olduğunuz e-posta adresine şifreniz gönderilmiştir");
        //             store.messageOnClosed = () => store.openUserLogin();
        //             setShowPasswordForget(false);
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error);
        //         props.parent.seterrorMessage(utils.getAxiosErrorMessage(error));
        //     })
        //     .finally(() => setsending(false));
    }

    return (
        <div id="login-form-container">
            {
                !showPasswordForget
                    ? <form autoComplete="off" onSubmit={login} ref={formRef}>
                        <FormInput name="email" label={t("Email Adresiniz")} type="email" required defaultValue={store.loginEmail} />
                        <FormInput name="password" label={t("Şifreniz")} type="password" required parentRef={passwordRef} />
                        <FormButton type="submit" color="primary" text={t("Giriş Yap")} sending={sending} />
                        <div className="text-right">
                            <span id="sifremi-unuttum" onClick={() => {
                                props.parent.setmessage("");
                                props.parent.seterrorMessage("");
                                setShowPasswordForget(true);
                            }}><Trans>Şifremi Unuttum</Trans></span>
                        </div>
                    </form>
                    : <div>
                        <form autoComplete="off" onSubmit={forgetPwd} ref={formForgetRef}>
                            <div className="mb-4">
                                <small className="text-muted">
                                    <Trans>E-Posta adresinize göndereceğimiz</Trans>
                                </small>
                            </div>
                            <FormInput name="email" label={t("Email Adresiniz")} type="email" required />
                            <FormButton type="submit" color="primary" text={t("Şifremi Sıfırla")} sending={sending} />
                        </form>
                        <div className="text-right">
                            <span id="sifremi-unuttum" onClick={() => setShowPasswordForget(false)}><Trans>Giriş Yap</Trans></span>
                        </div>
                    </div>
            }
        </div>
    )
}

export default LoginForm;