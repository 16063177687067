import React, { useContext } from 'react';
import { MainContext } from '../../../context/MainContext';
import Faq from "react-faq-component";
import './FrequentlyQuestions.scss';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

const FrequentlyQuestions = () => {
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setMenuOpen] = useContext(MainContext);

    const data = {
        title: t("SIK SORULAN SORULAR"),
        rows: [
            {
                title: t("Neden 8 milyar salavat?"),
                content: t("Neden 8 milyar salavat?R")
                // content: <>
                //     Son Peygamber Hz. Muhammed ‘in (SAS.) sevgi ve yakınlığına vesile olması için dünya üzerinde yaşayan insanlar adına 8 milyar salavat hediye edilmektedir.`
                // </>
            },
            {
                title: t("8 Milyar Salavat Kampanyası’na neden katılmalıyım?"),
                content: t("8 Milyar Salavat Kampanyası’na neden katılmalıyım?R"),
                // content: <>
                //     Rabbimize ve O'nun en sevgili kuluna yakınlaşmak için salavat okumalıyız.<br /><br />‘Kıyamet günü bana insanların en yakını bana en çok salavat okuyandır’ (Tirmizi, salat, 484) diyen Nebi’ye dünya üzerinde yaşayan 8 milyar insan adına çektiğimiz salavatlarımızla daha da yakın olabilir, bu kampanya vesilesiyle dünya ve ahiret huzuruna çektiğimiz salavatlarımızla ulaşabiliriz.
                // </>,
            },
            {
                title: t("8 Milyar Salavat Kampanyası ne zaman bitiyor?"),
                content: t("8 Milyar Salavat Kampanyası ne zaman bitiyor?R"),
                // content: <>
                //     8 Milyar Salavat Kampanyasının bir bitiş tarihi yoktur. Çünkü kampanyamız, sene boyunca salavat getirmeyi teşvik eden, Efendimiz ‘in doğum gününde sene boyu çekilen 8 milyar salavatı hediye etmeyi hedefleyen bir kampanyadır.
                // </>,
            },
            {
                title: t("Salavatlarımı nasıl sayaca ekleyebilirim?"),
                content: t("Salavatlarımı nasıl sayaca ekleyebilirim?R"),
                // content: <>
                //     Sitemizde ana sayfada bulunan ‘salavat gönder’ kısmındaki bilgileri doldurduktan sonra salavat göndere tıkladığınızda e posta adresinize gelen onay linkine tıklarsanız salavatlarınız sayaca eklenecektir. Onay postası sadece ilk sefere mahsus gönderilecektir. Daha sonraki salavat eklemelerinizde aynı e posta ile onay postası olmadan sisteme salavat ekleyebilirsiniz.
                // </>,
            },
            {
                title: t("Hesap oluşturup salavatlarınızı daha kolay takip etmek isterseniz"),
                content: <Trans i18nKey="Hesap oluşturup salavatlarınızı daha kolay takip etmek istersenizR">
                    ‘<span style={{ color: "blue" }}>Nasıl üyelik oluşturabilirim?</span>’ başlıklı kısma bakabilirsiniz.
                </Trans>
                ,
                // content: <>
                //     ‘<span style={{ color: "blue" }}>Nasıl üyelik oluşturabilirim?</span>’ başlıklı kısma bakabilirsiniz.
                // </>,
            },
            {
                title: t("Nasıl üyelik oluşturabilirim?"),
                content: t("Nasıl üyelik oluşturabilirim?R"),
                // content: <>
                //     8milyarsalavat.com adresine girip “Giriş Yap” butonuna tıklayınız. “Yeni üyelik” bölümünde istenilen bilgileri doldurup “ÜYE OL” butonuna tıklayınız.
                // </>,
            },
            {
                title: t("Yanlış miktarda salavat girersem nasıl sileceğim?"),
                content: t("Yanlış miktarda salavat girersem nasıl sileceğim?R"),
                // content: <>
                //     Bizimle sitede bulunan iletişim bölümünden irtibata geçerseniz yardımcı olabiliriz.
                // </>,
            },
            {
                title: t("Şifremi unuttum, ne yapmalıyım?"),
                content: t("Şifremi unuttum, ne yapmalıyım?R"),
                // content: <>
                //     Giriş yap ekranında yer alan kısmında mail adresinizi girdikten sonra ‘şifremi unuttum’a tıklarsanız mailinize gelen aktivasyon mailiyle şifrenizi yenileyebilirsiniz. Şifre yenilemede herhangi bir sorun yaşarsanız lütfen iletişim sekmesinden bizimle irtibata geçiniz.
                // </>,
            },
        ],
    };

    const styles = {
        // bgColor: 'white',
        titleTextColor: "blue",
        rowTitleColor: "blue",
        // rowContentColor: 'grey',
        // arrowColor: "red",
    };

    const config = {
        // animate: true,
        // arrowIcon: "V",
        // tabFocus: true
    };

    return (
        <div id="fq-container" className={`${!isMenuOpen && "z-index-4"}`}>
            <div className="info-card">
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
        </div>
    )
}

export default observer(FrequentlyQuestions);