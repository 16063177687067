import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, HashRouter, useLocation, useHistory } from "react-router-dom";
import PublicLayout from "./layouts/PublicLayout";
import PrivateLayout from "./layouts/PrivateLayout";
import utils from './utils';
import { MainContext } from './context/MainContext';
import Dashboard from './private/pages/dashboard/Dashboard';
import Home from './public/pages/home/Home';
import SalavatAbout from './public/pages/salavat-about/SalavatAbout';
import About from './public/pages/about/About';
import Contact from './public/pages/contact/Contact';
import { Trans, useTranslation } from 'react-i18next';
import store from './store';
import Confirmation from './public/pages/Confirmation';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { observer } from 'mobx-react';
import { ExclamationCircleFill, InfoCircleFill } from "react-bootstrap-icons"
import _ from 'lodash';
import resetPassword from './public/pages/resetPassword/ResetPassword';
import FrequentlyQuestions from './public/pages/frequentlyQuestions/FrequentlyQuestions';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function App() {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const closeMessage = () => {
    store.message = null;
    const tmp = store.messageOnClosed;
    store.messageOnClosed = null;
    if (_.isFunction(tmp)) {
      tmp();
    }
  }
  const closeError = () => {
    store.error = null;
    const tmp = store.errorOnClosed;
    store.errorOnClosed = null;
    if (_.isFunction(tmp)) {
      tmp();
    }
  }

  useEffect(() => {
    document.title = t("8 Milyar Salavat");
    document.querySelector('meta[name="description"]').setAttribute("content", t("Son1"));

    store.startRecaptchaRefresh(executeRecaptcha)
  }, [i18n.language, t, executeRecaptcha]);

  return (store.jwtValidationInProgress && <>...</>) || (
    <>
      <MainContext.Provider value={[isMenuOpen, setMenuOpen]}>
        <HashRouter hashType="slash">
          <Switch>
            {/* <Route exact path="/">
          <Redirect to="/#" />
        </Route> */}

            <PrivateLayout path="/dashboard" component={Dashboard} />
            <PrivateLayout path="/resetpassword/:token" component={resetPassword} />
            {/* <PublicLayout path="/anasayfa" component={Home} /> */}
            <PublicLayout path="/" exact component={Home} />
            {/* <PublicLayout path="/salawat/approve" component={Confirmation} /> */}
            <Route path="/salawat/approve" render={props => {
              const query = new URLSearchParams(props.location.search);
              localStorage.setItem("name", query.get("name"));
              localStorage.setItem("email", query.get("email"));
              store.showAddingConfirmationCount = query.get("amount");

              if (query.get("lang") && global.languages.includes(query.get("lang")))
                i18n.changeLanguage(query.get("lang"));

              props.history.push("/");
              return null;
            }} />
            <PublicLayout path="/salavat-hakkinda" component={SalavatAbout} />
            <PublicLayout path="/biz-kimiz" component={About} />
            <PublicLayout path="/s.s.s." component={FrequentlyQuestions} />
            <PublicLayout path="/iletisim" component={Contact} />
            <Route path="/emailConfirmed" render={props => {
              const query = new URLSearchParams(props.location.search);
              store.emailConfirmed = true;
              store.loginEmail = query.get("email");
              store.logOut();
              props.history.push("/");
              return null;
            }} />
            <Route path="/error" render={props => {
              const query = new URLSearchParams(props.location.search);

              if (query.get("message"))
                store.error = query.get("message");

              if (query.get("lang"))
                i18n.changeLanguage(query.get("lang"));

              props.history.push("/");
              return null;
            }} />

            <Route path="/en" render={props => {
              i18n.changeLanguage("en");
              props.history.push("/");
              return null;
            }} />
            <Route path="/ar" render={props => {
              i18n.changeLanguage("ar");
              props.history.push("/");
              return null;
            }} />
            <Route path="/id" render={props => {
              i18n.changeLanguage("id");
              props.history.push("/");
              return null;
            }} />
            <Route path="/ru" render={props => {
              i18n.changeLanguage("ru");
              props.history.push("/");
              return null;
            }} />
            <Route path="/tr" render={props => {
              i18n.changeLanguage("tr");
              props.history.push("/");
              return null;
            }} />

            <Route path="*" render={props =>
              <div>
                <h4>
                  No match for <code>{props.location.pathname}</code>
                </h4>
              </div>}
            />
          </Switch>
        </HashRouter>
      </MainContext.Provider>

      {/* <Modal isOpen={store.showYeniden} toggle={() => store.showYeniden = false} centered={true} size="lg" modalClassName="modal-yeniden">
        <ModalHeader toggle={() => store.showYeniden = false} style={{ backgroundColor: "#0A42BD", color: "white", border: "0" }}>
        </ModalHeader>
      </Modal> */}

      {/* <Modal isOpen={store.showYeniden} toggle={() => store.showYeniden = false} centered={true} size="lg" modalClassName="modal-yeniden">
        <img src="/assets/images/Yeniden2022.jpg" alt="Yeniden" style={{cursor: "pointer", maxWidth:"800px"}} onClick={() => store.showYeniden = false} />
      </Modal> */}

      {/* <Modal isOpen={store.showSukran} toggle={() => store.showSukran = false} centered={true} size="lg" modalClassName="modal-yeniden">
        <img src="/assets/images/tesekkur2022.png" alt="Yeniden" style={{ cursor: "pointer", maxWidth: "800px", maxHeight: "800px" }} onClick={() => store.showSukran = false} />
      </Modal> */}

      {/* <Modal isOpen={store.showSukran} toggle={() => store.showSukran = false} centered={true} size="lg" modalClassName="modal-yeniden">
        <img src="/assets/images/tesekkur2023.png" alt="Yeniden" style={{ cursor: "pointer", maxWidth: "300px", maxHeight: "300px" }} onClick={() => store.showSukran = false} />
      </Modal> */}

      {/* <Modal isOpen={store.showSukran} toggle={() => store.showSukran = false} centered={true} size="sm" className=".d-none .d-sm-block .d-md-none">
        <ModalHeader toggle={() => store.showSukran = false} style={{ backgroundColor: "#0A42BD", color: "white", border: "0" }}>
        </ModalHeader>
        <img src="/assets/images/tesekkur.jpg" alt="Şükran" width={298} height={298} />
      </Modal> */}

      <Modal isOpen={store.showMoreModal} toggle={() => store.showMoreModal = false} size="lg" centered={true}>
        <ModalHeader toggle={() => store.showMoreModal = false}><center>Daha Fazla Salavat Ekle</center></ModalHeader>
        <iframe
          id="moreModalIframe"
          scrolling="no"
          title={t("Daha fazla selavat ekle")}
          src="https://hcc.bitrix24.site/preview/51a44aa823581e4e7207499a27af916a/crm_form10/">
        </iframe>
      </Modal>

      <Modal isOpen={!!store.message} toggle={closeMessage} centered={true}>
        <ModalHeader toggle={closeMessage}>
          <center>{t("8 Milyar Salavat")}</center>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex">
            <span className="mr-2"><InfoCircleFill color="royalblue" size={35} /></span>
            <span>{store.message}</span>
          </div >
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeMessage}><Trans>Tamam</Trans></Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={store.error} toggle={closeError} centered={true}>
        <ModalHeader toggle={closeError}>
          <center>{t("8 Milyar Salavat")}</center>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex">
            <span className="mr-2"><ExclamationCircleFill color="orange" size={35} /></span>
            <span>{store.error}</span>
          </div >
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeError}><Trans>Tamam</Trans></Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default observer(App);