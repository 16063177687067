import React, { useState } from 'react';
import { Button, CustomInput, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import utils from '../../utils';
import FormButton from '../form-button/FormButton';
import FormInput from '../form-input/FormInput';
import _ from 'lodash';
import { getCountries, regionsTR } from "../../consts"
import { useHistory } from 'react-router';
import axios from 'axios';
import store from '../../store';
import { Alert } from 'reactstrap';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import './RegisterForm.scss';
import { Trans, useTranslation } from 'react-i18next';

const RegisterForm = props => {

    const [formData, setFormData] = useState({ country: store.geoCountryCode });
    const [showKvkkModal, setShowKvkkModal] = useState(false);
    const [kvkk, setkvkk] = useState(false);
    const history = useHistory();
    const password_confirmation = React.useRef();
    const form = React.useRef();
    const btnSubmit = React.useRef();
    const { t, i18n } = useTranslation();
    const [sending, setsending] = useState(false);


    useEffect(() => {
        formChange({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formChange = (e) => {
        var formData = utils.formSerialize(form.current);
        formData.device_id = formData.email;
        formData.agreement = formData.agreement === "on";
        setFormData(formData);

        if (formData.password !== formData.password_confirmation) {
            password_confirmation.current.setCustomValidity(t("Parola ve tekrarı aynı olmalıdır"));
        }
        else {
            password_confirmation.current.setCustomValidity("");
        }
    }

    async function register(e) {
        e.preventDefault();

        props.parent.seterrorMessage("");
        setsending(true);

        formData.language = i18n.language;
        formData.recaptchaToken = await store.getRecaptchaToken();
        // if (!formData.recaptchaToken) {
        //     store.error = t("Recaptcha_error");
        //     setsending(false);
        //     return;
        // }

        axios.post(`${utils.ApiAddress}/api/register`, formData)
            .then(response => {
                if (response.data.code >= 400) {
                    props.parent.seterrorMessage(utils.getApiError(response));
                } else {
                    //props.parent.setmessage("Üyelik talebiniz alınmıştır. "+formData.email+" adresinize e-posta doğrulama mesajı gönderilmiştir. Onaylama yaptığınızda sitemize giriş yapabilirisiniz.");
                    props.parent.hideHamburgerMenu();
                    props.parent.setActiveTab("1");
                    store.message = t("uyelik talebiniz", { "0": formData.email });
                    //store.message = <>Üyelik talebiniz alınmıştır. <span className="text-primary">{formData.email}</span> adresinize e-posta doğrulama mesajı gönderilmiştir. Onaylama yaptığınızda sitemize giriş yapabilirisiniz.</>;
                }
            })
            .catch(error => {
                console.log(error)
                props.parent.seterrorMessage(utils.getAxiosErrorMessage(error));
            })
            .finally(() => setsending(false));
        // axios.post(`https://8milyarsalavat.com/api/register`, formData)
        //     .then(response => {
        //         if (response.data.error === true) {
        //             props.parent.seterrorMessage(response.data.data)
        //         } else if (response.data.errorMessage) {
        //             props.parent.seterrorMessage(response.data.errorMessage)
        //         } else if (response.data.data) {
        //             //props.parent.setmessage("Üyelik talebiniz alınmıştır. "+formData.email+" adresinize e-posta doğrulama mesajı gönderilmiştir. Onaylama yaptığınızda sitemize giriş yapabilirisiniz.");
        //             props.parent.hideHamburgerMenu();
        //             props.parent.setActiveTab("1");
        //             store.message = <>Üyelik talebiniz alınmıştır.<span className="text-primary">{formData.email}</span> adresinize e-posta doğrulama mesajı gönderilmiştir. Onaylama yaptığınızda sitemize giriş yapabilirisiniz.</>;
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error)
        //         props.parent.seterrorMessage(utils.getAxiosErrorMessage(error));
        //     })
        //     .finally(() => setsending(false));
    }

    return (
        <div id="login-form-container">
            <form autoComplete="off" onChange={formChange} ref={form} onSubmit={register}>
                <FormInput name="name" label={t("Adınız Soyadınız")} required maxLength={100} />
                <FormInput name="email" label={t("Email Adresiniz")} type="email" required maxLength={500} />
                <FormInput name="password" label={t("Parola")} type="password" required maxLength={100} />
                <FormInput name="password_confirmation" label={t("Parola Tekrar")} type="password" required maxLength={100} parentRef={password_confirmation} />
                <FormInput name="country" label={t("Ülke")} type="select" options={getCountries()} required defaultValue={store.geoCountryCode} />
                {
                    formData.country === "TR"
                        ? <FormInput name="city" label={t("İl")} type="select" options={regionsTR} required defaultValue={store.geoRegion} />
                        : null
                }
                {/* <CustomInput type="checkbox" id="kvkk" name="agreement" label={t("KVKK metnini okudum, onaylıyorum.")} required
                    checked={kvkk}
                    onChange={(e) => {
                        setkvkk(e.target.checked);
                        setShowKvkkModal(e.target.checked);
                    }} className="mb-3" /> */}
                <CustomInput type="checkbox" id="kvkk" name="agreement" label=
                    {
                        i18n.language === "ar"
                            ? <button type="button" class="btn btn-link p-0" onClick={() => setShowKvkkModal(true)}>قرأت نص قانون حماية البيانات الشخصية، وأوافق عليه.</button>
                            : <Trans i18nKey="KVKK metnini okudum, onaylıyorum.">
                                <button type="button" class="btn btn-link p-0" onClick={() => setShowKvkkModal(true)}>KVKK</button> metnini okudum, onaylıyorum.
                            </Trans>
                    } required
                    checked={kvkk}
                    onChange={(e) => {
                        setkvkk(e.target.checked);
                        //setShowKvkkModal(e.target.checked);
                    }} className="mb-3" />
                <FormButton type="submit" color="primary" text={t("Üye Ol")} parentRef={btnSubmit} sending={sending} />
            </form>

            {/* {message && <Alert color="success" className="mt-2">{message}</Alert>} */}
            {/* {errorMessage && <Alert color="warning" className="mt-2">{errorMessage}</Alert>} */}

            <Modal isOpen={showKvkkModal} size="lg">
                <ModalHeader>
                    <div className={i18n.language === "ar" && "rtl"}><Trans>KVKK Metni</Trans></div>
                </ModalHeader>
                <ModalBody>
                    {
                        i18n.language === "tr" &&
                        <>
                            <p>Kişisel verilerin işleme ama&ccedil;larını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve y&ouml;netilmesinden sorumlu olan t&uuml;zel kişiliktir.</p>
                            <p><strong>Kanun kapsamındaki haklarınızla ilgili olarak;</strong></p>
                            <p><a href="http://www.8milyarsalavat.com/">www.8milyarsalavat.com</a> internet sitemizin bize mesajlarınızı iletebileceğiniz &ldquo;İletişim&rdquo; veya &ldquo;E-B&uuml;lten&rdquo; sayfalarından; yukarıdaki adresimize g&ouml;ndereceğiniz mektuplarla;</p>
                            <p><a href="mailto:bilgi@8milyarsalavat.com">bilgi@8milyarsalavat.com</a> e-posta adresinden bize ulaşabilir, sorularınızı sorabilir ve Kanun&rsquo;un 11. Maddesinde yer alan ve aşağıda a&ccedil;ıklanan haklarınızı kullanabilirsiniz.</p>
                            <p><strong>Hangi Kişisel Verilerin İşleneceği;</strong></p>
                            <p>Aşağıda sıralanan kategoriler işlenmekte olan kişisel verilerinizi ifade eder;</p>
                            <ul>
                                <li>
                                    <p>Kimlik Bilgisi</p>
                                </li>
                                <li>
                                    <p>İletişim Bilgisi</p>
                                </li>
                            </ul>
                            <p><strong>Kişisel Verilerin Hangi Ama&ccedil;larla İşleneceği;</strong></p>
                            <p>Kişisel bilgilerinizi yalnızca faaliyet ve organizasyonlarımızın bilgilendirilmesinde, ileride organize edilecek organizasyon ile ilgili tanıtım ve bilgilendirmelerin yapılabilmesi ama&ccedil;larıyla işlemekte ve kullanmaktayız.</p>
                            <p><strong>Kişisel Verilerin Kimlere ve Hangi Ama&ccedil;larla Aktarılabileceği;</strong></p>
                            <p>Kişisel verileriniz;</p>
                            <ul>
                                <li>
                                    <p>İş bu Mahremiyet Politikası&rsquo;nda belirtilen ama&ccedil;lar ve Kanun&rsquo;un 8. ve 9. maddeleri uyarınca kamu kurumlarına ve &ouml;zel kişilere;</p>
                                </li>
                                <li>
                                    <p>Hizmetlerinden faydalandığı iş ortakları, dış kaynak hizmet sağlayıcıları, barındırma (hosting servisleri) hizmet sağlayıcıları, hukuk b&uuml;roları, araştırma şirketleri, &ccedil;ağrı merkezleri ve yarışma kapsamında sponsorluk sağlayan firmalar gibi &uuml;&ccedil;&uuml;nc&uuml; kişilere, s&ouml;z konusu hizmetlerin temini amacıyla sınırlı olmak &uuml;zere;</p>
                                </li>
                            </ul>
                            <p><strong>Kişisel Veri Toplamanın Y&ouml;ntemi ve Hukuki Sebebi;</strong></p>
                            <p>Kişisel verilerinizi Vakıf tarafından y&ouml;netilen kullanıcıların kişisel bilgilerini paylaşması veya web sitemiz E B&uuml;lten ve İletişim formları &uuml;zerinden otomatik olmayan y&ouml;ntemlerle toplamaktayız.</p>
                            <p>Bu bağlamda kullanıcıların kişisel verilerinizin toplanması, işlenmesi ve aktarılmasının hukuki sebebini Kanun&rsquo;un 5. maddesinin 1. Fıkrasında belirtilen &ldquo;ilgili kişinin a&ccedil;ık rızası&rdquo; teşkil etmektedir. Bu kapsamda kullanıcıların bu Mahremiyet Politikasını kabul ettiklerini belirten kutucuğu işaretlemeleri gerekmektedir.</p>
                            <p><strong>Kişisel Verilerinizin Silinmesi</strong></p>
                            <p>Kanun&rsquo;a uygun olarak, işbu Bilgilendirme Metni&rsquo;nde belirtilen ama&ccedil;larla işlenmiş olan kişisel verileriniz anonimleştirerek kullanılmaya devam edilecektir. Ancak yazılı olarak talep etmeniz halinde bizimle paylaşılan kişisel bilgileriniz veri tabanımızdan silinecektir.</p>
                            <p>Kişisel verilerinizin silinmesine ilişkin taleplerinizi</p>
                            <p><a href="http://www.8milyarsalavat.com/">www.8milyarsalavat.com</a> internet sitemizin bize mesajlarınızı iletebileceğiniz &ldquo;İletişim&rdquo; sayfasından; yukarıdaki adresimize g&ouml;ndereceğiniz mektuplarla;</p>
                            <p>Vakıf&rsquo;a ait Kayıtlı Elektronik Posta (KEP) adresi olan <a href="mailto:bilgi@8milyarsalavat.com">bilgi@8milyarsalavat.com</a> e-posta adresinden bizlere iletebilirsiniz.</p>
                            <p><strong>Kanun&rsquo;un 11. Maddesinde Sayılan Haklarınız;</strong></p>
                            <p>Kanun&rsquo;un 11. maddesi &ccedil;er&ccedil;evesinde herkes kendisiyle ilgili;</p>
                            <ul>
                                <li>
                                    <p>Kişisel veri işlenip işlenmediğini &ouml;ğrenme,</p>
                                </li>
                                <li>
                                    <p>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</p>
                                </li>
                                <li>
                                    <p>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını &ouml;ğrenme,</p>
                                </li>
                                <li>
                                    <p>Yurt i&ccedil;inde veya yurt dışında kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme,</p>
                                </li>
                                <li>
                                    <p>Kişisel verilerin eksik veya yanlış işlenmiş olması h&acirc;linde bunların d&uuml;zeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme,</p>
                                </li>
                                <li>
                                    <p>Kanun&rsquo;a ve ilgili diğer kanun h&uuml;k&uuml;mlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması h&acirc;linde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme,</p>
                                </li>
                                <li>
                                    <p>İşlenen verilerin m&uuml;nhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya &ccedil;ıkmasına itiraz etme,</p>
                                </li>
                                <li>
                                    <p>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması h&acirc;linde zararın giderilmesini talep etme haklarına sahiptir.</p>
                                </li>
                            </ul>
                            <p>Kanun kapsamındaki haklarınızdan yararlanmak i&ccedil;in elektronik posta yoluyla <a href="mailto:bilgi@8milyarsalavat.com">bilgi@8milyarsalavat.com</a> adresine başvurabilirsiniz.</p>
                        </>
                    }
                    {
                        i18n.language === "en" &&
                        <>
                            <p>Personal Data Protection Law is the
                                legal mechanism used to determine purposes and means of processing
                                personal data, management and creation of data recording systems.
                            </p>
                            <p><b>Your rights under data protection law;</b></p>
                            <p>You seek your right mentioned in Article 11 of the law which are
                                explained below by contacting us and ask your questions via the pages
                                &ldquo;Contact Us&rdquo; or &ldquo;E-Bulletin&rdquo; on our website
                                <a href="http://www.8milyarsalavat.com/">www.8milyarsalavat.com</a>;
                                posting letters to our address mentioned above or sending an email to
                                <a href="mailto:bilgi@8milyarsalavat.com">bilgi@8milyarsalavat.com</a>
                                and you can use your rights which is explained below and also in
                                Article 11 of the Law.</p>
                            <p><b>Processing of personal data;</b></p>
                            <p>The categories mentioned below refers to the personal data that
                                will be processed.
                            </p>
                            <ul>
                                <li><p>Identity Information</p></li>
                                <li><p>Communication Informatio</p></li>
                            </ul>

                            <p><b>Purposes of Personal Data Processing;</b></p>
                            <p>We process and use your personal information only for the purpose
                                of informing our activities and events, and for the purpose of
                                promoting and informing future events.</p>
                            <p><b>Sharing of personal data with third party entities;</b></p>
                            <p>Your personal data may be shared with the following third parties;</p>
                            <p>Public institutions and private individuals in accordance with the
                                purposes stated in this Privacy Policy and Articles 8 and 9 of the
                                Law;</p>
                            <p>Third parties such as business partners, outsourcing service
                                providers, service providers, law firms, research companies, call
                                centers and sponsor companies within the scope of a competition,
                                limited to the provision of such services;</p>
                            <p><b>Legal basis and methods for the collection of personal data;</b></p>
                            <p>We collect your personal data by non-automated systems, through
                                the personal data shared by users managed by the Foundation, or by
                                the E-Bulletin and Contact Us forms on our website.</p>


                            <p>In this context, the legal reason for the collecting, processing
                                and sharing of personal data of the users constitutes &quot;explicit
                                consent of the relevant person&quot; specified in paragraph 1 of
                                Article 5 of the Law. Users are required to tick the box indicating
                                that they accept this Privacy Policy.</p>
                            <p><b>Deletion of Personal Data</b></p>
                            <p>In compliance with the Law, your personal data processed for the
                                purposes specified in this &quot;Information Text&quot; will continue
                                to be used anonymously. However, if you send your request in writing,
                                your personal information shared with us will be erased from our
                                database.</p>


                            <p>Requests for the deletion of Personal Data</p>

                            <p>You can send your request for deletion of personal data to us
                                using any of the following
                            </p>
                            <ul>
                                <li><p>By using the &ldquo;Contact Us&rdquo; page at our website
                                    <a href="http://www.8milyarsalavat.com/">www.8milyarsalavat.com</a>;
                                </p></li>
                                <li><p>By sending a letter to our postal address above;</p></li>
                                <li><p>Or by sending an e-mail it to us at info<a href="mailto:bilgi@8milyarsalavat.com">@8milyarsalavat.com</a>;
                                </p></li>
                            </ul>


                            <p><b>Your Rights Enumerated in Article 11 of the Law;</b></p>
                            <p>Within the framework of Article 11 of the Law, each person has the
                                right to request the data controller about him/her:</p>
                            <ul>
                                <li><p>To request whether his/her personal data is processed or not,</p></li>
                                <li><p>To request information whether his/her personal data have
                                    been processed,</p></li>
                                <li><p>To request the purpose of processing of personal data and
                                    whether the personal data are used in compliance with the purpose;</p></li>
                                <li><p>To request the third parties with whom his/her personal data
                                    had been shared in the country or abroad
                                </p></li>
                                <li><p>To request the correction of personal data in case of
                                    incomplete or inaccurate data and reporting of such data that have
                                    been shared with the third parties
                                </p></li>
                            </ul>
                            <p>To request the personal data to be erased or destroyed in the case
                                of reasons for processing no longer existent, despite being processed
                                in compliance with the provisions of this law and other relevant
                                laws, and reporting change of such data that have been shared with
                                third parties.</p>
                        </>
                    }
                    {
                        i18n.language === "ar" &&
                        <div className="rtl">
                            <p>
                                <font face="Arial"><span lang="hi-IN">&#1606;&#1589;
                                    &#1602;&#1575;&#1606;&#1608;&#1606; &#1581;&#1605;&#1575;&#1610;&#1577;
                                    &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                                </span></font>
                            </p>

                            <p><font face="Arial"><span lang="hi-IN">&#1575;&#1604;&#1603;&#1610;&#1575;&#1606;
                                &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610; &#1607;&#1608;
                                &#1575;&#1604;&#1584;&#1610; &#1610;&#1581;&#1583;&#1583; &#1571;&#1594;&#1585;&#1575;&#1590;
                                &#1608;&#1608;&#1587;&#1575;&#1574;&#1604; &#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
                                &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                                &#1608;&#1607;&#1608; &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;
                                &#1593;&#1606; &#1573;&#1606;&#1588;&#1575;&#1569; &#1608;&#1573;&#1583;&#1575;&#1585;&#1577;
                                &#1606;&#1592;&#1575;&#1605; &#1578;&#1587;&#1580;&#1610;&#1604;
                                &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;</span></font></p>
                            <p><font face="Arial"><span lang="hi-IN">&#1601;&#1610;&#1605;&#1575;
                                &#1610;&#1578;&#1593;&#1604;&#1602; &#1576;&#1581;&#1602;&#1608;&#1602;&#1603;
                                &#1576;&#1605;&#1608;&#1580;&#1576; &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;
                                &#1563;</span></font></p>
                            <p><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1605;&#1606;
                                &#1589;&#1601;&#1581;&#1575;&#1578; </span></span></font>&quot;<font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604;</span></span></font>&quot;
                                <font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1571;&#1608;
                                </span></span></font>&quot;<font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1575;&#1604;&#1606;&#1588;&#1585;&#1577;
                                    &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1577;</span></span></font>&quot;
                                <font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1593;&#1604;&#1609;
                                    &#1605;&#1608;&#1602;&#1593;&#1606;&#1575; &#1593;&#1604;&#1609;
                                    &#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578;
                                </span></span></font>www.8milyarsalawat.com <font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1581;&#1610;&#1579;
                                    &#1610;&#1605;&#1603;&#1606;&#1603; &#1573;&#1585;&#1587;&#1575;&#1604;
                                    &#1585;&#1587;&#1575;&#1574;&#1604;&#1603; &#1573;&#1604;&#1610;&#1606;&#1575;&#1563;
                                    &#1593;&#1604;&#1609; &#1605;&#1608;&#1602;&#1593;&#1606;&#1575; &#1593;&#1606;
                                    &#1591;&#1585;&#1610;&#1602; &#1575;&#1604;&#1585;&#1587;&#1575;&#1574;&#1604;
                                    &#1575;&#1604;&#1578;&#1610; &#1578;&#1585;&#1587;&#1604;&#1607;&#1575;
                                    &#1573;&#1604;&#1609; &#1575;&#1604;&#1593;&#1606;&#1608;&#1575;&#1606;
                                    &#1571;&#1593;&#1604;&#1575;&#1607;&#1563;</span></span></font></p>
                            <p dir="rtl"><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1608;&#1610;&#1605;&#1603;&#1606;&#1603;
                                &#1575;&#1604;&#1578;&#1608;&#1575;&#1589;&#1604; &#1605;&#1593;&#1606;&#1575;
                                &#1593;&#1604;&#1609; &#1593;&#1606;&#1608;&#1575;&#1606; &#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
                                &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
                            </span></span></font>info@8milyarsalawat.com <font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1548;</span></span></font></p>
                            <p><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1608;&#1591;&#1585;&#1581;
                                &#1571;&#1587;&#1574;&#1604;&#1578;&#1603; &#1608;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                                &#1581;&#1602;&#1608;&#1602;&#1603; &#1575;&#1604;&#1605;&#1606;&#1589;&#1608;&#1589;
                                &#1593;&#1604;&#1610;&#1607;&#1575; &#1601;&#1610; &#1575;&#1604;&#1605;&#1575;&#1583;&#1577;
                            </span></span></font>11 <font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1605;&#1606;
                                &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; &#1608;&#1575;&#1604;&#1605;&#1608;&#1590;&#1581;&#1577;
                                &#1571;&#1583;&#1606;&#1575;&#1607;</span></span></font></p>
                            <p><font face="Arial"><span lang="hi-IN">&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
                                &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1575;&#1604;&#1578;&#1610;
                                &#1587;&#1578;&#1578;&#1605; &#1605;&#1593;&#1575;&#1604;&#1580;&#1578;&#1607;&#1575;&#1563;</span></font></p>
                            <p><font face="Arial"><span lang="hi-IN">&#1578;&#1588;&#1610;&#1585;
                                &#1575;&#1604;&#1601;&#1574;&#1575;&#1578; &#1575;&#1604;&#1605;&#1583;&#1585;&#1580;&#1577;
                                &#1571;&#1583;&#1606;&#1575;&#1607; &#1573;&#1604;&#1609; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;
                                &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1602;&#1610;&#1583;
                                &#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;&#1563;</span></font></p>
                            <p><font face="Arial"><span lang="hi-IN">&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
                                &#1575;&#1604;&#1607;&#1608;&#1610;&#1577; &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;</span></font></p>
                            <p><font face="Arial"><span lang="hi-IN">&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
                                &#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604;</span></font></p>
                            <br />
                            <p dir="rtl"><font face="Arial"><span lang="hi-IN">&#1571;&#1594;&#1585;&#1575;&#1590;
                                &#1605;&#1593;&#1575;&#1604;&#1580;&#1577; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
                                &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;&#1563;</span></font></p>
                            <p dir="rtl"><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1606;&#1602;&#1608;&#1605;
                                &#1576;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577; &#1608;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                                &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;&#1605;
                                &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1601;&#1602;&#1591;
                                &#1605;&#1606; &#1571;&#1580;&#1604; &#1571;&#1606; &#1606;&#1593;&#1604;&#1606;
                                &#1593;&#1606; &#1571;&#1606;&#1588;&#1591;&#1578;&#1606;&#1575;
                                &#1608;&#1605;&#1606;&#1592;&#1605;&#1575;&#1578;&#1606;&#1575;&#1548;
                                &#1608;&#1604;&#1594;&#1585;&#1590; &#1575;&#1604;&#1578;&#1585;&#1608;&#1610;&#1580;
                                &#1608;&#1575;&#1604;&#1573;&#1576;&#1604;&#1575;&#1594; &#1593;&#1606;
                                &#1575;&#1604;&#1605;&#1606;&#1592;&#1605;&#1577; &#1575;&#1604;&#1578;&#1610;
                                &#1587;&#1610;&#1578;&#1605; &#1578;&#1606;&#1592;&#1610;&#1605;&#1607;&#1575;
                                &#1601;&#1610; &#1575;&#1604;&#1605;&#1587;&#1578;&#1602;&#1576;&#1604;</span></span></font>.</p>
                            <p dir="rtl"><font face="Arial"><span lang="hi-IN">&#1606;&#1602;&#1604;
                                &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                                &#1608;&#1575;&#1604;&#1594;&#1585;&#1590; &#1605;&#1606;&#1607;&#1563;</span></font></p>
                            <p dir="rtl"><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;
                                &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;&#1563; &#1610;&#1605;&#1603;&#1606;
                                &#1605;&#1588;&#1575;&#1585;&#1603;&#1578;&#1607;&#1575; &#1605;&#1593;
                                &#1575;&#1604;&#1605;&#1572;&#1587;&#1587;&#1575;&#1578; &#1575;&#1604;&#1593;&#1575;&#1605;&#1577;
                                &#1608;&#1575;&#1604;&#1571;&#1601;&#1585;&#1575;&#1583; &#1608;&#1601;&#1602;&#1611;&#1575;
                                &#1604;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590; &#1575;&#1604;&#1608;&#1575;&#1585;&#1583;&#1577;
                                &#1601;&#1610; &#1587;&#1610;&#1575;&#1587;&#1577; &#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
                                &#1608;&#1608;&#1601;&#1602;&#1575; &#1604;&#1604;&#1605;&#1575;&#1583;&#1578;&#1610;&#1606;
                            </span></span></font>8 <font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1608;
                            </span></span></font>9 <font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1605;&#1606;
                                &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1563; &#1608;&#1610;&#1578;&#1605;
                                &#1605;&#1588;&#1575;&#1585;&#1603;&#1578;&#1607;&#1575; &#1605;&#1593;
                                &#1571;&#1591;&#1585;&#1575;&#1601; &#1579;&#1575;&#1604;&#1579;&#1577;
                                &#1605;&#1579;&#1604; &#1588;&#1585;&#1603;&#1575;&#1569; &#1575;&#1604;&#1593;&#1605;&#1604;
                                &#1608;&#1605;&#1602;&#1583;&#1605;&#1610; &#1582;&#1583;&#1605;&#1575;&#1578;
                                &#1575;&#1604;&#1578;&#1593;&#1607;&#1610;&#1583; &#1608;&#1605;&#1602;&#1583;&#1605;&#1610;
                                &#1582;&#1583;&#1605;&#1575;&#1578; &#1575;&#1604;&#1575;&#1587;&#1578;&#1590;&#1575;&#1601;&#1577;
                                &#1608;&#1588;&#1585;&#1603;&#1575;&#1578; &#1575;&#1604;&#1605;&#1581;&#1575;&#1605;&#1575;&#1577;
                                &#1608;&#1588;&#1585;&#1603;&#1575;&#1578; &#1575;&#1604;&#1571;&#1576;&#1581;&#1575;&#1579;
                                &#1608;&#1605;&#1585;&#1575;&#1603;&#1586; &#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604;
                                &#1608;&#1575;&#1604;&#1588;&#1585;&#1603;&#1575;&#1578; &#1575;&#1604;&#1578;&#1610;
                                &#1578;&#1602;&#1583;&#1605; &#1575;&#1604;&#1585;&#1593;&#1575;&#1610;&#1577;
                                &#1601;&#1610; &#1606;&#1591;&#1575;&#1602; &#1575;&#1604;&#1605;&#1606;&#1575;&#1601;&#1587;&#1577;
                                &#1576;&#1594;&#1585;&#1590; &#1578;&#1602;&#1583;&#1610;&#1605;
                                &#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578; &#1575;&#1604;&#1605;&#1584;&#1603;&#1608;&#1585;&#1577;
                            </span></span></font>.</p>
                            <br />
                            <p dir="rtl"><font face="Arial"><span lang="hi-IN">&#1575;&#1604;&#1571;&#1587;&#1576;&#1575;&#1576;
                                &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577; &#1604;&#1580;&#1605;&#1593;
                                &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                                &#1608;&#1591;&#1585;&#1602; &#1580;&#1605;&#1593;&#1607;&#1575;&#1563;</span></font></p>
                            <p dir="rtl"><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1606;&#1580;&#1605;&#1593;
                                &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;&#1605; &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                                &#1576;&#1591;&#1585;&#1602; &#1594;&#1610;&#1585; &#1570;&#1604;&#1610;&#1577;
                                &#1576;&#1605;&#1588;&#1575;&#1585;&#1603;&#1577; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                                &#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606;
                                &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1575;&#1604;&#1578;&#1610;
                                &#1578;&#1583;&#1610;&#1585;&#1607;&#1575; &#1575;&#1604;&#1605;&#1572;&#1587;&#1587;&#1577;
                                &#1571;&#1608; &#1593;&#1606; &#1591;&#1585;&#1610;&#1602; &#1575;&#1604;&#1606;&#1588;&#1585;&#1577;
                                &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1577;
                                &#1608;&#1575;&#1587;&#1578;&#1605;&#1575;&#1585;&#1575;&#1578;
                                &#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604; &#1593;&#1576;&#1585;
                                &#1605;&#1608;&#1602;&#1593;&#1606;&#1575; &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;</span></span></font>.</p>
                            <p dir="rtl"><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1601;&#1610;
                                &#1575;&#1604;&#1587;&#1610;&#1575;&#1602; &#1584;&#1575;&#1578;&#1607;&#1548;
                                &#1610;&#1588;&#1603;&#1604; &#1575;&#1604;&#1587;&#1576;&#1576;
                                &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610; &#1604;&#1580;&#1605;&#1593;
                                &#1608;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577; &#1608;&#1606;&#1602;&#1604;
                                &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                                &#1604;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606;&#1548;
                                &#1607;&#1610; &#1593;&#1576;&#1575;&#1585;&#1577; </span></span></font>&quot;<font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1575;&#1604;&#1605;&#1608;&#1575;&#1601;&#1602;&#1577;
                                    &#1575;&#1604;&#1589;&#1585;&#1610;&#1581;&#1577; &#1605;&#1606;
                                    &#1575;&#1604;&#1588;&#1582;&#1589; &#1575;&#1604;&#1605;&#1593;&#1606;&#1610;</span></span></font>&quot;
                                <font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1575;&#1604;&#1605;&#1576;&#1610;&#1606;&#1577;
                                    &#1601;&#1610; &#1575;&#1604;&#1601;&#1602;&#1585;&#1577; &#1575;&#1604;&#1571;&#1608;&#1604;&#1609;
                                    &#1605;&#1606; &#1575;&#1604;&#1605;&#1575;&#1583;&#1577; &#1575;&#1604;&#1582;&#1575;&#1605;&#1587;&#1577;
                                    &#1605;&#1606; &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;</span></span></font>.
                                <font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1608;&#1601;&#1610;
                                    &#1607;&#1584;&#1575; &#1575;&#1604;&#1587;&#1610;&#1575;&#1602; &#1610;&#1580;&#1576;
                                    &#1593;&#1604;&#1609; &#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606;
                                    &#1608;&#1590;&#1593; &#1593;&#1604;&#1575;&#1605;&#1577; &#1601;&#1610;
                                    &#1575;&#1604;&#1605;&#1585;&#1576;&#1593; &#1575;&#1604;&#1584;&#1610;
                                    &#1610;&#1588;&#1610;&#1585; &#1573;&#1604;&#1609; &#1602;&#1576;&#1608;&#1604;&#1607;&#1605;
                                    &#1587;&#1610;&#1575;&#1587;&#1577; &#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
                                    &#1607;&#1584;&#1607;</span></span></font>.</p>
                            <br />
                            <p dir="rtl"><font face="Arial"><span lang="hi-IN">&#1581;&#1584;&#1601;
                                &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603; &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;</span></font></p>
                            <p dir="rtl"><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1608;&#1601;&#1602;&#1611;&#1575;
                                &#1604;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1548; &#1587;&#1610;&#1587;&#1578;&#1605;&#1585;
                                &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;
                                &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1575;&#1604;&#1578;&#1610;
                                &#1578;&#1578;&#1605; &#1605;&#1593;&#1575;&#1604;&#1580;&#1578;&#1607;&#1575;
                                &#1604;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590; &#1575;&#1604;&#1605;&#1581;&#1583;&#1583;&#1577;
                                &#1601;&#1610; &#1606;&#1589; &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                                &#1607;&#1584;&#1575; &#1593;&#1606; &#1591;&#1585;&#1610;&#1602;
                                &#1573;&#1582;&#1601;&#1575;&#1569; &#1575;&#1604;&#1607;&#1608;&#1610;&#1577;</span></span></font>.
                                <font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1608;&#1605;&#1593;
                                    &#1584;&#1604;&#1603;&#1548; &#1573;&#1584;&#1575; &#1591;&#1604;&#1576;&#1578;
                                    &#1603;&#1578;&#1575;&#1576;&#1610;&#1611;&#1575;&#1548; &#1601;&#1587;&#1610;&#1578;&#1605;
                                    &#1581;&#1584;&#1601; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                                    &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1575;&#1604;&#1578;&#1610;
                                    &#1578;&#1605;&#1578; &#1605;&#1588;&#1575;&#1585;&#1603;&#1578;&#1607;&#1575;
                                    &#1605;&#1593;&#1606;&#1575; &#1605;&#1606; &#1602;&#1575;&#1593;&#1583;&#1577;
                                    &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1582;&#1575;&#1589;&#1577;
                                    &#1576;&#1606;&#1575;</span></span></font>.</p>
                            <p dir="rtl"><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1610;&#1605;&#1603;&#1606;&#1603;
                                &#1591;&#1604;&#1576; &#1581;&#1584;&#1601; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;
                                &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1605;&#1606; &#1589;&#1601;&#1581;&#1577;
                            </span></span></font>&quot;<font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604;</span></span></font>&quot;
                                <font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1593;&#1604;&#1609;
                                    &#1605;&#1608;&#1602;&#1593;&#1606;&#1575; &#1593;&#1604;&#1609;
                                    &#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578;
                                </span></span></font>www.8milyarsalawat.com<font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1548;
                                    &#1581;&#1610;&#1579; &#1610;&#1605;&#1603;&#1606;&#1603; &#1605;&#1585;&#1575;&#1587;&#1604;&#1578;&#1606;&#1575;&#1563;
                                    &#1593;&#1606; &#1591;&#1585;&#1610;&#1602; &#1575;&#1604;&#1585;&#1587;&#1575;&#1574;&#1604;
                                    &#1575;&#1604;&#1578;&#1610; &#1578;&#1585;&#1587;&#1604;&#1607;&#1575;
                                    &#1573;&#1604;&#1609; &#1575;&#1604;&#1593;&#1606;&#1608;&#1575;&#1606;
                                    &#1571;&#1593;&#1604;&#1575;&#1607;&#1563;</span></span></font></p>
                            <p dir="rtl"><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1603;&#1605;&#1575;
                                &#1610;&#1605;&#1603;&#1606;&#1603; &#1605;&#1585;&#1575;&#1587;&#1604;&#1578;&#1606;&#1575;
                                &#1593;&#1604;&#1609; </span></span></font>info@8milyarsalawat.com <font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1548;
                                    &#1608;&#1607;&#1608; &#1593;&#1606;&#1608;&#1575;&#1606; &#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
                                    &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
                                    &#1575;&#1604;&#1605;&#1587;&#1580;&#1604; &#1604;&#1604;&#1605;&#1572;&#1587;&#1587;&#1577;</span></span></font>.</p>

                            <br />

                            <p dir="rtl"><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1581;&#1602;&#1608;&#1602;&#1603;&#1605;
                                &#1575;&#1604;&#1605;&#1606;&#1589;&#1608;&#1589; &#1593;&#1604;&#1610;&#1607;&#1575;
                                &#1601;&#1610; &#1575;&#1604;&#1605;&#1575;&#1583;&#1577; </span></span></font>11
                                <font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1605;&#1606;
                                    &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1563;</span></span></font></p>
                            <ul>
                                <li><p dir="rtl"><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1608;&#1601;&#1602;&#1575;
                                    &#1604;&#1604;&#1605;&#1575;&#1583;&#1577; </span></span></font>11
                                    <font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1605;&#1606;
                                        &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; &#1603;&#1604; &#1588;&#1582;&#1589;
                                        &#1605;&#1585;&#1578;&#1576;&#1591; &#1576;&#1606;&#1601;&#1587;&#1607;</span></span></font>:</p></li>
                                <li><p dir="rtl"><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1605;&#1593;&#1585;&#1601;&#1577;
                                    &#1605;&#1575; &#1573;&#1584;&#1575; &#1603;&#1575;&#1606;&#1578;
                                    &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                                    &#1578;&#1593;&#1575;&#1604;&#1580; &#1571;&#1605; &#1604;&#1575;</span></span></font>,
                                </p></li>
                                <li><p dir="rtl"><font face="Arial"><span lang="hi-IN">&#1573;&#1584;&#1575;
                                    &#1603;&#1575;&#1606;&#1578; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
                                    &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1578;&#1593;&#1575;&#1604;&#1580;
                                    &#1591;&#1604;&#1576; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                                    &#1593;&#1606;&#1607;&#1575;</span></font></p></li>
                                <li><p dir="rtl"><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1605;&#1593;&#1585;&#1601;&#1577;
                                    &#1575;&#1604;&#1594;&#1585;&#1590; &#1605;&#1606; &#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
                                    &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                                    &#1608; &#1605;&#1593;&#1585;&#1601;&#1577; &#1605;&#1575; &#1573;&#1584;&#1575;
                                    &#1603;&#1575;&#1606;&#1578; &#1578;&#1587;&#1578;&#1582;&#1583;&#1605;
                                    &#1601;&#1610; &#1575;&#1604;&#1594;&#1585;&#1590; &#1575;&#1604;&#1605;&#1602;&#1589;&#1608;&#1583;
                                    &#1605;&#1606;&#1607;&#1575;</span></span></font>,</p></li>
                                <li><p dir="rtl"><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1605;&#1593;&#1585;&#1601;&#1577;
                                    &#1575;&#1604;&#1571;&#1591;&#1585;&#1575;&#1601; &#1575;&#1604;&#1579;&#1575;&#1604;&#1579;&#1577;
                                    &#1575;&#1604;&#1578;&#1610; &#1578;&#1606;&#1602;&#1604; &#1575;&#1604;&#1610;&#1607;&#1575;
                                    &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                                    &#1583;&#1575;&#1582;&#1604; &#1575;&#1604;&#1576;&#1604;&#1583; &#1571;&#1608;
                                    &#1582;&#1575;&#1585;&#1580;&#1607;</span></span></font>,</p></li>
                                <li><p dir="rtl"><font face="Arial"><span lang="hi-IN">&#1591;&#1604;&#1576;
                                    &#1578;&#1589;&#1581;&#1610;&#1581; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
                                    &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1601;&#1610;
                                    &#1581;&#1575;&#1604;&#1577; &#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
                                    &#1594;&#1610;&#1585; &#1575;&#1604;&#1603;&#1575;&#1605;&#1604;&#1577;
                                    &#1571;&#1608; &#1575;&#1604;&#1582;&#1575;&#1591;&#1574;&#1577;
                                    &#1608;&#1591;&#1604;&#1576; &#1575;&#1604;&#1573;&#1576;&#1604;&#1575;&#1594;
                                    &#1576;&#1575;&#1604;&#1605;&#1593;&#1575;&#1605;&#1604;&#1577; &#1575;&#1604;&#1578;&#1610;
                                    &#1578;&#1605;&#1578; &#1601;&#1610; &#1607;&#1584;&#1575; &#1575;&#1604;&#1606;&#1591;&#1575;&#1602;
                                    &#1573;&#1604;&#1609; &#1575;&#1604;&#1571;&#1591;&#1585;&#1575;&#1601;
                                    &#1575;&#1604;&#1579;&#1575;&#1604;&#1579;&#1577; &#1575;&#1604;&#1578;&#1610;
                                    &#1578;&#1605; &#1606;&#1602;&#1604; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
                                    &#1573;&#1604;&#1610;&#1607;&#1575;</span></font></p></li>
                            </ul>
                            <p><font face="Arial"><span lang="hi-IN">&#1575;&#1604;&#1581;&#1602;
                                &#1601;&#1610; &#1591;&#1604;&#1576; &#1581;&#1584;&#1601; &#1571;&#1608;
                                &#1573;&#1578;&#1604;&#1575;&#1601; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
                                &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1601;&#1610; &#1581;&#1575;&#1604;&#1577;
                                &#1575;&#1582;&#1578;&#1601;&#1575;&#1569; &#1575;&#1604;&#1571;&#1587;&#1576;&#1575;&#1576;
                                &#1575;&#1604;&#1578;&#1610; &#1578;&#1578;&#1591;&#1604;&#1576;
                                &#1605;&#1593;&#1575;&#1604;&#1580;&#1578;&#1607;&#1575;&#1548; &#1593;&#1604;&#1609;
                                &#1575;&#1604;&#1585;&#1594;&#1605; &#1605;&#1606; &#1571;&#1606;&#1607;
                                &#1602;&#1583; &#1578;&#1605; &#1605;&#1593;&#1575;&#1604;&#1580;&#1578;&#1607;&#1575;
                                &#1608;&#1601;&#1602;&#1575; &#1604;&#1571;&#1581;&#1603;&#1575;&#1605;
                                &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; &#1608;&#1575;&#1604;&#1571;&#1581;&#1603;&#1575;&#1605;
                                &#1575;&#1604;&#1571;&#1582;&#1585;&#1609; &#1584;&#1575;&#1578;
                                &#1575;&#1604;&#1589;&#1604;&#1577;&#1548; &#1608;&#1591;&#1604;&#1576;
                                &#1573;&#1582;&#1591;&#1575;&#1585; &#1575;&#1604;&#1571;&#1591;&#1585;&#1575;&#1601;
                                &#1575;&#1604;&#1579;&#1575;&#1604;&#1579;&#1577; &#1575;&#1604;&#1578;&#1610;
                                &#1610;&#1578;&#1605; &#1606;&#1602;&#1604; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
                                &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1573;&#1604;&#1610;&#1607;&#1575;
                                &#1576;&#1575;&#1604;&#1605;&#1593;&#1575;&#1605;&#1604;&#1577; &#1575;&#1604;&#1578;&#1610;
                                &#1578;&#1605;&#1578; &#1601;&#1610; &#1607;&#1584;&#1575; &#1575;&#1604;&#1606;&#1591;&#1575;&#1602;
                            </span></font>
                            </p>
                            <p dir="rtl"><font face="Arial"><span lang="hi-IN">&#1575;&#1604;&#1581;&#1602;
                                &#1601;&#1610; &#1591;&#1604;&#1576; &#1575;&#1604;&#1575;&#1593;&#1578;&#1585;&#1575;&#1590;
                                &#1593;&#1604;&#1609; &#1592;&#1607;&#1608;&#1585; &#1606;&#1578;&#1610;&#1580;&#1577;
                                &#1590;&#1583; &#1575;&#1604;&#1588;&#1582;&#1589; &#1606;&#1601;&#1587;&#1607;
                                &#1576;&#1608;&#1575;&#1587;&#1591;&#1577; &#1578;&#1581;&#1604;&#1610;&#1604;
                                &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
                                &#1581;&#1589;&#1585;&#1575; &#1605;&#1606; &#1582;&#1604;&#1575;&#1604;
                                &#1575;&#1604;&#1571;&#1606;&#1592;&#1605;&#1577; &#1575;&#1604;&#1570;&#1604;&#1610;&#1577;</span></font></p>
                            <p><font face="Arial"><span lang="hi-IN">&#1575;&#1604;&#1581;&#1602;
                                &#1601;&#1610; &#1591;&#1604;&#1576; &#1578;&#1593;&#1608;&#1610;&#1590;
                                &#1593;&#1606; &#1575;&#1604;&#1571;&#1590;&#1585;&#1575;&#1585; &#1601;&#1610;
                                &#1581;&#1575;&#1604;&#1577; &#1581;&#1583;&#1608;&#1579; &#1571;&#1590;&#1585;&#1575;&#1585;
                                &#1576;&#1587;&#1576;&#1576; &#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
                                &#1594;&#1610;&#1585; &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;
                                &#1604;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;</span></font></p>
                            <p dir="rtl"><font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1610;&#1605;&#1603;&#1606;&#1603;
                                &#1575;&#1604;&#1578;&#1602;&#1583;&#1610;&#1605; &#1593;&#1606; &#1591;&#1585;&#1610;&#1602;
                                &#1575;&#1604;&#1576;&#1585;&#1610;&#1583; &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
                            </span></span></font><a href="mailto:bilgi@8milyarsalavat.com">bilgi@8milyarsalavat.com</a>
                                <font face="Arial"><span lang="hi-IN"><span lang="hi-IN">&#1604;&#1604;&#1575;&#1587;&#1578;&#1601;&#1575;&#1583;&#1577;
                                    &#1576;&#1581;&#1602;&#1608;&#1602;&#1603; &#1576;&#1605;&#1608;&#1580;&#1576;
                                    &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; </span></span></font>
                            </p>
                        </div>
                    }
                    {
                        i18n.language === "id" &&
                        <div>
                            <p>Ini adalah <span lang="id-ID">aturan
                                hukum</span> yang menentukan tujuan dan sarana pemrosesan data
                                pribadi dan bertanggung jawab atas pembentukan dan pengelolaan sistem
                                perekaman data <span lang="id-ID">anda</span>.</p>
                            <p><b>Berkaitan dengan hak-hak Anda menurut
                                hukum;</b></p>
                            <p>Dari halaman &quot;Kontak&quot; atau &quot;Bulletin Elektronik&quot;
                                di situs web kami www.8milyarsalawat.com, tempat Anda dapat
                                mengirimkan pesan kepada kami; dengan surat yang Anda kirim ke alamat
                                di atas;</p>
                            <p>Anda dapat menghubungi kami di alamat email
                                info@8milyarsalawat.com, mengajukan pertanyaan dan menggunakan hak
                                Anda yang diatur dalam Pasal 11 <span lang="id-ID">dari </span>Undang-undang
                                <span lang="id-ID">perlindungan data pribadi akan </span>dijelaskan
                                di bawah ini<span lang="id-ID">.</span></p>
                            <p><b>Data Pribadi mana yang akan Diproses;</b></p>
                            <p>Kategori yang tercantum di bawah mengacu pada data pribadi Anda
                                yang sedang diproses;</p>
                            <ul>
                                <li><p>Kredensial</p></li>
                                <li><p>Informasi komunikasi</p></li>
                            </ul>
                            <p>Untuk Tujuan Apa Data Pribadi Akan Diproses;</p>
                            <p>Kami hanya memproses dan menggunakan informasi pribadi Anda untuk
                                tujuan menginformasikan kegiatan dan organisasi kami, dan untuk
                                tujuan mempromosikan dan menginformasikan tentang organisasi yang
                                akan diselenggarakan di masa mendatang.</p>
                            <p><b>Kepada Siapa Data Pribadi Dapat Ditransfer dan Untuk Tujuan Apa;</b></p>
                            <p><b>Data pribadi Anda;</b></p>
                            <p>Kepada lembaga publik dan individu swasta sesuai dengan tujuan
                                yang ditentukan dalam Kebijakan Privasi ini dan Pasal 8 dan 9
                                Undang-Undang;</p>
                            <p>Kepada pihak ketiga seperti mitra bisnis, penyedia layanan
                                outsourcing, penyedia layanan hosting (layanan hosting), firma hukum,
                                perusahaan riset, call center dan perusahaan yang memberikan
                                sponsorship dalam lingkup kompetisi, terbatas pada penyediaan layanan
                                tersebut;</p>
                            <p><b>Metode dan Alasan Hukum Pengumpulan Data Pribadi;</b></p>
                            <p>Kami mengumpulkan data pribadi Anda dengan metode non-otomatis,
                                melalui berbagi informasi pribadi pengguna yang dikelola oleh
                                Yayasan, atau melalui E-Bulletin dan formulir Kontak di situs web
                                kami.</p>
                            <p>Dalam konteks ini, alasan hukum untuk pengumpulan, pemrosesan, dan
                                transfer data pribadi pengguna Anda adalah &quot;persetujuan
                                eksplisit dari orang yang relevan&quot; yang ditentukan dalam
                                paragraf 1 Pasal 5 Undang-Undang. Dalam konteks ini, pengguna
                                diharuskan untuk mencentang kotak yang menyatakan bahwa mereka
                                menerima Kebijakan Privasi ini.</p>
                            <p><b>Penghapusan Data Pribadi Anda;</b></p>
                            <p>Sesuai dengan Hukum, data pribadi Anda yang diproses untuk tujuan
                                yang ditentukan dalam Teks Informasi ini akan terus digunakan dengan
                                menganonimkan. Namun, jika Anda meminta secara tertulis, informasi
                                pribadi Anda yang dibagikan kepada kami akan dihapus dari basis data
                                kami.</p>
                            <p><b>Permintaan Anda untuk menghapus data pribadi Anda;</b></p>
                            <p>Dari halaman &quot;Kontak&quot; situs web kami
                                www.8milyarsalawat.com di mana Anda dapat mengirimkan pesan Anda
                                kepada kami; dengan surat yang Anda kirim ke alamat di atas;</p>
                            <p>Anda dapat mengirimkannya kepada kami di info@8milyarsalawat.com
                                yang merupakan alamat Surat Elektronik Terdaftar (KEP) Yayasan.</p>
                            <p>Hak-Hak Anda Yang Diuraikan Dalam Pasal 11 Undang-Undang;</p>
                            <p>Dalam kerangka Pasal 11 Undang-undang, setiap orang berhubungan
                                dengan dirinya sendiri;</p>
                            <p><b>Mempelajari apakah data pribadi diproses atau tida</b>;</p>
                            <p>Jika data pribadi telah diproses, meminta informasi tentangnya,
                                Mempelajari tujuan pemrosesan data pribadi dan apakah digunakan
                                sesuai dengan tujuannya, Mengetahui pihak ketiga kepada siapa data
                                pribadi ditransfer di rumah atau di luar negeri,</p>
                            <p>Meminta koreksi data pribadi dalam hal pemrosesan yang tidak
                                lengkap atau salah dan meminta pemberitahuan transaksi yang dilakukan
                                dalam ruang lingkup ini kepada pihak ketiga kepada siapa data pribadi
                                tersebut telah ditransfer,</p>
                            <p>Meminta penghapusan atau pemusnahan data pribadi dalam hal alasan
                                yang mengharuskannya untuk diproses hilang meskipun telah diproses
                                sesuai dengan Undang-undang dan ketentuan hukum terkait lainnya, dan
                                meminta pemberitahuan transaksi yang dilakukan dalam ruang lingkup
                                ini kepada pihak ketiga kepada siapa data pribadi telah ditransfer,</p>
                            <p>Menolak munculnya hasil terhadap orang itu sendiri dengan
                                menganalisis data yang diproses secara eksklusif melalui sistem
                                otomatis,</p>
                            <p>Jika data pribadi rusak karena pemrosesan yang melanggar hukum, ia
                                berhak menuntut ganti rugi atas kerusakan tersebut.</p>
                            <p>Untuk memanfaatkan hak-hak Anda berdasarkan hukum, Anda dapat
                                mengajukan permohonan ke info@8milyarsalawat.com melalui email.</p>
                        </div>
                    }
                    {
                        i18n.language === "ru" &&
                        <div><p lang="ru-RU" class="western">&#1058;&#1077;&#1082;&#1089;&#1090;
                            &#1047;&#1047;&#1055;&#1044;</p>
                            <p lang="ru-RU" class="western">&#1047;&#1072;&#1082;&#1086;&#1085; &#1086;
                                &#1079;&#1072;&#1097;&#1080;&#1090;&#1077; &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
                                &#1076;&#1072;&#1085;&#1085;&#1099;&#1093; - &#1101;&#1090;&#1086;
                                &#1087;&#1088;&#1072;&#1074;&#1086;&#1074;&#1086;&#1081; &#1084;&#1077;&#1093;&#1072;&#1085;&#1080;&#1079;&#1084;,
                                &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1091;&#1077;&#1084;&#1099;&#1081;
                                &#1076;&#1083;&#1103; &#1086;&#1087;&#1088;&#1077;&#1076;&#1077;&#1083;&#1077;&#1085;&#1080;&#1103;
                                &#1094;&#1077;&#1083;&#1077;&#1081; &#1080; &#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;
                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1080;
                                &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
                                &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;, &#1091;&#1087;&#1088;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103;
                                &#1080; &#1089;&#1086;&#1079;&#1076;&#1072;&#1085;&#1080;&#1103;
                                &#1089;&#1080;&#1089;&#1090;&#1077;&#1084; &#1079;&#1072;&#1087;&#1080;&#1089;&#1080;
                                &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;.</p>
                            <p lang="ru-RU" class="western">
                                <b>
                                    &#1042;&#1072;&#1096;&#1080; &#1087;&#1088;&#1072;&#1074;&#1072;
                                    &#1074; &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1080;
                                    &#1089; &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1084; &#1086;
                                    &#1079;&#1072;&#1097;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;;
                                </b>
                            </p>
                            <p lang="ru-RU" class="western">&#1042;&#1099; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                                &#1074;&#1086;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1090;&#1100;&#1089;&#1103;
                                &#1089;&#1074;&#1086;&#1080;&#1084;&#1080; &#1087;&#1088;&#1072;&#1074;&#1072;&#1084;&#1080;,
                                &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1099;&#1084;&#1080;
                                &#1074; &#1089;&#1090;&#1072;&#1090;&#1100;&#1077; 11 &#1079;&#1072;&#1082;&#1086;&#1085;&#1072;
                                &#1058;&#1091;&#1088;&#1094;&#1080;&#1080;, &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1077;
                                &#1086;&#1073;&#1098;&#1103;&#1089;&#1085;&#1103;&#1102;&#1090;&#1089;&#1103;
                                &#1085;&#1080;&#1078;&#1077;;</p>
                            <p lang="ru-RU" class="western">&#1057;&#1074;&#1103;&#1079;&#1099;&#1074;&#1072;&#1090;&#1100;&#1089;&#1103;
                                &#1089; &#1085;&#1072;&#1084;&#1080; &#1080; &#1079;&#1072;&#1076;&#1072;&#1074;&#1072;&#1103;
                                &#1089;&#1074;&#1086;&#1080; &#1074;&#1086;&#1087;&#1088;&#1086;&#1089;&#1099;
                                &#1095;&#1077;&#1088;&#1077;&#1079; &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1099;
                                &laquo;&#1057;&#1074;&#1103;&#1078;&#1080;&#1090;&#1077;&#1089;&#1100;
                                &#1089; &#1085;&#1072;&#1084;&#1080;&raquo; &#1080;&#1083;&#1080;
                                &laquo;&#1069;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1099;&#1081;
                                &#1073;&#1102;&#1083;&#1083;&#1077;&#1090;&#1077;&#1085;&#1100;&raquo;
                                &#1085;&#1072; &#1085;&#1072;&#1096;&#1077;&#1084; &#1074;&#1077;&#1073;-&#1089;&#1072;&#1081;&#1090;&#1077;
                                www.8milyarsalavat.com ;
                            </p>
                            <p lang="en-US" class="western"><span lang="ru-RU">&#1054;&#1090;&#1087;&#1088;&#1072;&#1074;&#1083;&#1103;&#1090;&#1100;
                                &#1087;&#1080;&#1089;&#1100;&#1084;&#1072; &#1085;&#1072; &#1085;&#1072;&#1096;
                                &#1072;&#1076;&#1088;&#1077;&#1089;, &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1099;&#1081;
                                &#1074;&#1099;&#1096;&#1077;, &#1080;&#1083;&#1080; &#1086;&#1090;&#1087;&#1088;&#1072;&#1074;&#1083;&#1103;&#1090;&#1100;
                                &#1101;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1077;
                                &#1087;&#1080;&#1089;&#1100;&#1084;&#1086; &#1085;&#1072;
                                <a href="mailto:bilgi@8milyarsalavat.com">bilgi@8milyarsalavat.com</a>.</span></p>
                            <p lang="ru-RU" class="western">
                                <b>
                                    &#1054;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1072;
                                    &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
                                    &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;;
                                </b>
                            </p>
                            <p lang="ru-RU" class="western">&#1050;&#1072;&#1090;&#1077;&#1075;&#1086;&#1088;&#1080;&#1080;,
                                &#1091;&#1087;&#1086;&#1084;&#1103;&#1085;&#1091;&#1090;&#1099;&#1077;
                                &#1085;&#1080;&#1078;&#1077;, &#1086;&#1090;&#1085;&#1086;&#1089;&#1103;&#1090;&#1089;&#1103;
                                &#1082; &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1084;
                                &#1076;&#1072;&#1085;&#1085;&#1099;&#1084;, &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1077;
                                &#1073;&#1091;&#1076;&#1091;&#1090; &#1086;&#1073;&#1088;&#1072;&#1073;&#1072;&#1090;&#1099;&#1074;&#1072;&#1090;&#1100;&#1089;&#1103;.</p>
                            <ul>
                                <li><p lang="ru-RU" class="western">&#1048;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1082;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1099;&#1077;
                                    &#1076;&#1072;&#1085;&#1085;&#1099;&#1077;
                                </p></li>
                                <li><p lang="ru-RU" class="western">&#1050;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;&#1085;&#1072;&#1103;
                                    &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;</p></li>
                            </ul>
                            <p lang="ru-RU" class="western">
                                <b>
                                    &#1062;&#1077;&#1083;&#1080;
                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1080;
                                    &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
                                    &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;;
                                </b>
                            </p>
                            <p lang="ru-RU" class="western">&#1052;&#1099; &#1086;&#1073;&#1088;&#1072;&#1073;&#1072;&#1090;&#1099;&#1074;&#1072;&#1077;&#1084;
                                &#1080; &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1091;&#1077;&#1084;
                                &#1074;&#1072;&#1096;&#1091; &#1083;&#1080;&#1095;&#1085;&#1091;&#1102;
                                &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1102;
                                &#1090;&#1086;&#1083;&#1100;&#1082;&#1086; &#1074; &#1094;&#1077;&#1083;&#1103;&#1093;
                                &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1080;&#1088;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
                                &#1086; &#1085;&#1072;&#1096;&#1077;&#1081; &#1076;&#1077;&#1103;&#1090;&#1077;&#1083;&#1100;&#1085;&#1086;&#1089;&#1090;&#1080;,
                                &#1072; &#1090;&#1072;&#1082;&#1078;&#1077; &#1074; &#1094;&#1077;&#1083;&#1103;&#1093;
                                &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1080;&#1088;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
                                &#1086; <span lang="en-US">&#1087;&#1088;&#1086;&#1080;&#1089;&#1093;&#1086;&#1076;&#1103;&#1097;&#1080;&#1093;
                                    &#1080; &#1073;&#1091;&#1076;&#1091;&#1097;&#1080;&#1093;</span>
                                &#1089;&#1086;&#1073;&#1099;&#1090;&#1080;&#1103;&#1093;.</p>
                            <p lang="ru-RU" class="western">
                                <b>
                                    &#1055;&#1077;&#1088;&#1077;&#1076;&#1072;&#1095;&#1072;
                                    &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
                                    &#1076;&#1072;&#1085;&#1085;&#1099;&#1093; &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1084;
                                    &#1083;&#1080;&#1094;&#1072;&#1084;;
                                </b>
                            </p>
                            <p lang="ru-RU" class="western">&#1042;&#1072;&#1096;&#1080;
                                &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1077;
                                &#1076;&#1072;&#1085;&#1085;&#1099;&#1077; &#1084;&#1086;&#1075;&#1091;&#1090;
                                &#1073;&#1099;&#1090;&#1100; &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1085;&#1099;
                                &#1089;&#1083;&#1077;&#1076;&#1091;&#1102;&#1097;&#1080;&#1084;
                                &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1084; &#1083;&#1080;&#1094;&#1072;&#1084;;</p>
                            <ul>
                                <li><p lang="ru-RU" class="western">&#1043;&#1086;&#1089;&#1091;&#1076;&#1072;&#1088;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1099;&#1084;
                                    &#1091;&#1095;&#1088;&#1077;&#1078;&#1076;&#1077;&#1085;&#1080;&#1103;&#1084;
                                    &#1080; &#1095;&#1072;&#1089;&#1090;&#1085;&#1099;&#1084; &#1083;&#1080;&#1094;&#1072;&#1084;
                                    &#1074; &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1080;
                                    &#1089; &#1094;&#1077;&#1083;&#1103;&#1084;&#1080;, &#1080;&#1079;&#1083;&#1086;&#1078;&#1077;&#1085;&#1085;&#1099;&#1084;&#1080;
                                    &#1074; &#1055;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1077;
                                    &#1050;&#1086;&#1085;&#1092;&#1080;&#1076;&#1077;&#1085;&#1094;&#1080;&#1072;&#1083;&#1100;&#1085;&#1086;&#1089;&#1090;&#1080;
                                    &#1080; &#1089;&#1090;&#1072;&#1090;&#1100;&#1103;&#1093; 8 &#1080;
                                    9 &#1047;&#1072;&#1082;&#1086;&#1085;&#1072; &#1058;&#1091;&#1088;<span lang="en-US">&#1077;&#1094;&#1082;&#1086;&#1081;
                                        &#1056;&#1077;&#1089;&#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1080;</span>;</p></li>
                                <li><p lang="ru-RU" class="western">&#1058;&#1088;&#1077;&#1090;&#1100;&#1080;
                                    &#1083;&#1080;&#1094;&#1072; <span lang="en-US">-</span> &#1076;&#1077;&#1083;&#1086;&#1074;&#1099;&#1077;
                                    &#1087;&#1072;&#1088;&#1090;&#1085;&#1077;&#1088;&#1099;, &#1087;&#1086;&#1089;&#1090;&#1072;&#1074;&#1097;&#1080;&#1082;&#1080;
                                    &#1093;&#1086;&#1089;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1099;&#1093;
                                    &#1091;&#1089;&#1083;&#1091;&#1075;, &#1102;&#1088;&#1080;&#1076;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;&#1077;
                                    &#1092;&#1080;&#1088;&#1084;&#1099;, &#1080;&#1089;&#1089;&#1083;&#1077;&#1076;&#1086;&#1074;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1082;&#1080;&#1077;
                                    &#1082;&#1086;&#1084;&#1087;&#1072;&#1085;&#1080;&#1080;,
                                    &#1082;&#1086;&#1083;&#1083;-&#1094;&#1077;&#1085;&#1090;&#1088;&#1099;
                                    &#1080; &#1082;&#1086;&#1084;&#1087;&#1072;&#1085;&#1080;&#1080;-&#1089;&#1087;&#1086;&#1085;&#1089;&#1086;&#1088;&#1099;
                                    &#1074; &#1088;&#1072;&#1084;&#1082;&#1072;&#1093; &#1082;&#1086;&#1085;&#1082;&#1091;&#1088;&#1089;&#1072;,
                                    &#1086;&#1075;&#1088;&#1072;&#1085;&#1080;&#1095;&#1080;&#1074;&#1072;&#1102;&#1090;&#1089;&#1103;
                                    &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1077;&#1084;
                                    &#1090;&#1072;&#1082;&#1080;&#1093; &#1091;&#1089;&#1083;&#1091;&#1075;;</p></li>
                            </ul>
                            <p lang="ru-RU" class="western">
                                <b>
                                    &#1055;&#1088;&#1072;&#1074;&#1086;&#1074;&#1099;&#1077;
                                    &#1086;&#1089;&#1085;&#1086;&#1074;&#1099; &#1080; &#1084;&#1077;&#1090;&#1086;&#1076;&#1099;
                                    &#1089;&#1073;&#1086;&#1088;&#1072; &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
                                    &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;;
                                </b>
                            </p>
                            <p lang="ru-RU" class="western">&#1052;&#1099; &#1089;&#1086;&#1073;&#1080;&#1088;&#1072;&#1077;&#1084;
                                &#1074;&#1072;&#1096;&#1080; &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1077;
                                &#1076;&#1072;&#1085;&#1085;&#1099;&#1077; &#1089; &#1087;&#1086;&#1084;&#1086;&#1097;&#1100;&#1102;
                                &#1086;&#1073;&#1084;&#1077;&#1085;&#1072; &#1076;&#1072;&#1085;&#1085;&#1099;&#1084;&#1080;
                                &#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1090;&#1077;&#1083;&#1077;&#1081;,
                                &#1091;&#1087;&#1088;&#1072;&#1074;&#1083;&#1103;&#1077;&#1084;&#1099;&#1093;
                                &#1092;&#1086;&#1085;&#1076;&#1086;&#1084;, &#1080;&#1083;&#1080;
                                &#1085;&#1077;&#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;&#1084;&#1080;
                                &#1084;&#1077;&#1090;&#1086;&#1076;&#1072;&#1084;&#1080; &#1087;&#1086;&#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;&#1086;&#1084;
                                &#1101;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1099;&#1093;
                                &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1099;&#1093;
                                &#1073;&#1102;&#1083;&#1083;&#1077;&#1090;&#1077;&#1085;&#1077;&#1081;
                                &#1080; &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;&#1085;&#1099;&#1093;
                                &#1092;&#1086;&#1088;&#1084; &#1085;&#1072; &#1085;&#1072;&#1096;&#1077;&#1084;
                                &#1074;&#1077;&#1073;-&#1089;&#1072;&#1081;&#1090;&#1077;.</p>
                            <p lang="en-US" class="western"><br />
                                <br />

                            </p>
                            <p lang="ru-RU" class="western">&#1042; &#1101;&#1090;&#1086;&#1084;
                                &#1082;&#1086;&#1085;&#1090;&#1077;&#1082;&#1089;&#1090;&#1077;
                                &#1102;&#1088;&#1080;&#1076;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;&#1084;
                                &#1086;&#1089;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;&#1084;
                                &#1076;&#1083;&#1103; &#1089;&#1073;&#1086;&#1088;&#1072;, &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1080;
                                &#1080; &#1086;&#1073;&#1084;&#1077;&#1085;&#1072; &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1084;&#1080;
                                &#1076;&#1072;&#1085;&#1085;&#1099;&#1084;&#1080; &#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1090;&#1077;&#1083;&#1077;&#1081;
                                &#1103;&#1074;&#1083;&#1103;&#1077;&#1090;&#1089;&#1103; &quot;&#1089;&#1086;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077;
                                &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1091;&#1102;&#1097;&#1077;&#1075;&#1086;
                                &#1083;&#1080;&#1094;&#1072;&quot;, &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1086;&#1077;
                                &#1074; &#1087;&#1091;&#1085;&#1082;&#1090;&#1077; 1 &#1089;&#1090;&#1072;&#1090;&#1100;&#1080;
                                5 &#1047;&#1072;&#1082;&#1086;&#1085;&#1072; &#1058;&#1091;&#1088;<span lang="en-US">&#1077;&#1094;&#1082;&#1086;&#1081;
                                    &#1056;&#1077;&#1089;&#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1080;</span>.
                                &#1055;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1090;&#1077;&#1083;&#1080;
                                &#1076;&#1086;&#1083;&#1078;&#1085;&#1099; &#1087;&#1086;&#1089;&#1090;&#1072;&#1074;&#1080;&#1090;&#1100;
                                &#1075;&#1072;&#1083;&#1086;&#1095;&#1082;&#1091; &#1074; &#1087;&#1086;&#1083;&#1077;,
                                &#1091;&#1082;&#1072;&#1079;&#1099;&#1074;&#1072;&#1102;&#1097;&#1077;&#1084;,
                                &#1095;&#1090;&#1086; &#1086;&#1085;&#1080; &#1089;&#1086;&#1075;&#1083;&#1072;&#1089;&#1085;&#1099;
                                &#1089; &#1055;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1086;&#1081;
                                &#1082;&#1086;&#1085;&#1092;&#1080;&#1076;&#1077;&#1085;&#1094;&#1080;&#1072;&#1083;&#1100;&#1085;&#1086;&#1089;&#1090;&#1080;.</p>
                            <p lang="ru-RU" class="western">&#1059;&#1076;&#1072;&#1083;&#1077;&#1085;&#1080;&#1077;
                                &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
                                &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;</p>
                            <p lang="ru-RU" class="western">&#1042; &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1080;
                                &#1089; &#1047;&#1072;&#1082;&#1086;&#1085;&#1086;&#1084;, &#1074;&#1072;&#1096;&#1080;
                                &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1077;
                                &#1076;&#1072;&#1085;&#1085;&#1099;&#1077;, &#1086;&#1073;&#1088;&#1072;&#1073;&#1072;&#1090;&#1099;&#1074;&#1072;&#1077;&#1084;&#1099;&#1077;
                                &#1074; &#1094;&#1077;&#1083;&#1103;&#1093;, &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1099;&#1093;
                                &#1074; &#1101;&#1090;&#1086;&#1084; &quot;&#1048;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1086;&#1084;
                                &#1090;&#1077;&#1082;&#1089;&#1090;&#1077;&quot;, &#1073;&#1091;&#1076;&#1091;&#1090;
                                &#1087;&#1086;-&#1087;&#1088;&#1077;&#1078;&#1085;&#1077;&#1084;&#1091;
                                &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1090;&#1100;&#1089;&#1103;
                                &#1072;&#1085;&#1086;&#1085;&#1080;&#1084;&#1085;&#1086;. &#1054;&#1076;&#1085;&#1072;&#1082;&#1086;,
                                &#1077;&#1089;&#1083;&#1080; &#1074;&#1099; &#1086;&#1090;&#1087;&#1088;&#1072;&#1074;&#1080;&#1090;&#1077;
                                &#1074;&#1072;&#1096; &#1079;&#1072;&#1087;&#1088;&#1086;&#1089; &#1074;
                                &#1087;&#1080;&#1089;&#1100;&#1084;&#1077;&#1085;&#1085;&#1086;&#1081;
                                &#1092;&#1086;&#1088;&#1084;&#1077;, &#1074;&#1072;&#1096;&#1072;
                                &#1083;&#1080;&#1095;&#1085;&#1072;&#1103; &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;,
                                &#1082;&#1086;&#1090;&#1086;&#1088;&#1086;&#1081; &#1074;&#1099;
                                &#1087;&#1086;&#1076;&#1077;&#1083;&#1080;&#1083;&#1080;&#1089;&#1100;
                                &#1089; &#1085;&#1072;&#1084;&#1080;, &#1073;&#1091;&#1076;&#1077;&#1090;
                                &#1091;&#1076;&#1072;&#1083;&#1077;&#1085;&#1072; &#1080;&#1079;
                                &#1085;&#1072;&#1096;&#1077;&#1081; &#1073;&#1072;&#1079;&#1099;
                                &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;.</p>
                            <p lang="ru-RU" class="western">
                                <b>
                                    &#1047;&#1072;&#1087;&#1088;&#1086;&#1089;&#1099;
                                    &#1085;&#1072; &#1091;&#1076;&#1072;&#1083;&#1077;&#1085;&#1080;&#1077;
                                    &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
                                    &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;;
                                </b>
                            </p>
                            <p lang="ru-RU" class="western">&#1042;&#1099; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                                &#1086;&#1090;&#1087;&#1088;&#1072;&#1074;&#1080;&#1090;&#1100; &#1085;&#1072;&#1084;
                                &#1089;&#1074;&#1086;&#1081; &#1079;&#1072;&#1087;&#1088;&#1086;&#1089;
                                &#1085;&#1072; &#1091;&#1076;&#1072;&#1083;&#1077;&#1085;&#1080;&#1077;
                                &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
                                &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;, &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1091;&#1103;
                                &#1083;&#1102;&#1073;&#1086;&#1081; &#1080;&#1079; &#1089;&#1083;&#1077;&#1076;&#1091;&#1102;&#1097;&#1080;&#1093;
                                &#1089;&#1087;&#1086;&#1089;&#1086;&#1073;&#1086;&#1074;;</p>
                            <ul>
                                <li><p lang="en-US" class="western"><span lang="ru-RU">&#1048;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1091;&#1103;
                                    &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1091; &ldquo;&#1057;&#1074;&#1103;&#1078;&#1080;&#1090;&#1077;&#1089;&#1100;
                                    &#1089; &#1085;&#1072;&#1084;&#1080;&rdquo; &#1085;&#1072; &#1085;&#1072;&#1096;&#1077;&#1084;
                                    &#1074;&#1077;&#1073;-&#1089;&#1072;&#1081;&#1090;&#1077;
                                    <a href="http://www.8milyarsalavat.com/">www.8milyarsalavat.com</a>;</span></p></li>
                                <li><p lang="en-US" class="western"><span lang="ru-RU">&#1054;&#1090;&#1087;&#1088;&#1072;&#1074;&#1080;&#1074;
                                    &#1087;&#1080;&#1089;&#1100;&#1084;&#1086; &#1085;&#1072; &#1085;&#1072;&#1096;
                                    &#1087;&#1086;&#1095;&#1090;&#1086;&#1074;&#1099;&#1081; &#1072;&#1076;&#1088;&#1077;&#1089;,
                                    &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1099;&#1081; &#1074;&#1099;&#1096;&#1077;
                                    &#1080;&#1083;&#1080; &#1086;&#1090;&#1087;&#1088;&#1072;&#1074;&#1080;&#1074;
                                    &#1077;&#1075;&#1086; &#1085;&#1072;&#1084; &#1087;&#1086;
                                    &#1101;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1081;
                                    &#1087;&#1086;&#1095;&#1090;&#1077; &#1087;&#1086; &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;
                                    <a href="mailto:info@8milyarsalavat.com">info@8milyarsalavat.com</a>;</span></p></li>
                            </ul>
                            <p lang="ru-RU" class="western">
                                <b>
                                    &#1042;&#1072;&#1096;&#1080; &#1087;&#1088;&#1072;&#1074;&#1072;,
                                    &#1087;&#1077;&#1088;&#1077;&#1095;&#1080;&#1089;&#1083;&#1077;&#1085;&#1085;&#1099;&#1077;
                                    &#1074; &#1089;&#1090;&#1072;&#1090;&#1100;&#1077; 11 &#1047;&#1072;&#1082;&#1086;&#1085;&#1072;
                                    &#1058;&#1091;&#1088;<span lang="en-US">&#1077;&#1094;&#1082;&#1086;&#1081;
                                        &#1056;&#1077;&#1089;&#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1080;</span>;
                                </b>
                            </p>
                            <p lang="ru-RU" class="western">&#1042; &#1088;&#1072;&#1084;&#1082;&#1072;&#1093;
                                &#1089;&#1090;&#1072;&#1090;&#1100;&#1080; 11 &#1079;&#1072;&#1082;&#1086;&#1085;&#1072;
                                &#1058;&#1091;&#1088;<span lang="en-US">&#1077;</span>&#1094;<span lang="en-US">&#1082;&#1086;&#1081;
                                    &#1056;&#1077;&#1089;&#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1080;</span>,
                                &#1082;&#1072;&#1078;&#1076;&#1086;&#1077; &#1083;&#1080;&#1094;&#1086;
                                &#1080;&#1084;&#1077;&#1077;&#1090; &#1087;&#1088;&#1072;&#1074;&#1086;
                                &#1079;&#1072;&#1087;&#1088;&#1086;&#1089;&#1080;&#1090;&#1100;
                                &#1076;&#1072;&#1085;&#1085;&#1099;&#1077; &#1086; &#1089;&#1077;&#1073;&#1077;:
                            </p>
                            <ul>
                                <li><p lang="ru-RU" class="western">&#1047;&#1072;&#1087;&#1088;&#1086;&#1089;&#1080;&#1090;&#1100;,
                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1072;&#1090;&#1099;&#1074;&#1072;&#1102;&#1090;&#1089;&#1103;
                                    &#1083;&#1080; &#1077;&#1075;&#1086; / &#1077;&#1077; &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1077;
                                    &#1076;&#1072;&#1085;&#1085;&#1099;&#1077; &#1080;&#1083;&#1080;
                                    &#1085;&#1077;&#1090;,</p></li>
                                <li><p lang="ru-RU" class="western">&#1047;&#1072;&#1087;&#1088;&#1086;&#1089;&#1080;&#1090;&#1100;
                                    &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1102;
                                    &#1086; &#1090;&#1086;&#1084;, <span lang="en-US">&#1095;&#1090;&#1086;
                                    </span>&#1077;&#1075;&#1086; / &#1077;&#1077; &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1077;
                                    &#1076;&#1072;&#1085;&#1085;&#1099;&#1077; &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1072;&#1085;&#1099;,</p></li>
                                <li><p lang="ru-RU" class="western">&#1047;&#1072;&#1087;&#1088;&#1086;&#1089;&#1080;&#1090;&#1100;
                                    &#1094;&#1077;&#1083;&#1080; &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1080;
                                    &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
                                    &#1076;&#1072;&#1085;&#1085;&#1099;&#1093; &#1080; &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1091;&#1102;&#1090;&#1089;&#1103;
                                    &#1083;&#1080; &#1086;&#1085;&#1080; &#1087;&#1086; &#1085;&#1072;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1080;&#1102;;</p></li>
                                <li><p lang="ru-RU" class="western">&#1047;&#1072;&#1087;&#1088;&#1072;&#1096;&#1080;&#1074;&#1072;&#1090;&#1100;
                                    &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1093; &#1083;&#1080;&#1094;,
                                    &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1084; &#1073;&#1099;&#1083;&#1080;
                                    &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1085;&#1099; &#1077;&#1075;&#1086;/&#1077;&#1077;
                                    &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1077;
                                    &#1076;&#1072;&#1085;&#1085;&#1099;&#1077; &#1074; &#1089;&#1090;&#1088;&#1072;&#1085;&#1077;
                                    &#1080;&#1083;&#1080; &#1079;&#1072; &#1088;&#1091;&#1073;&#1077;&#1078;&#1086;&#1084;;</p></li>
                                <li><p lang="ru-RU" class="western">&#1047;&#1072;&#1087;&#1088;&#1072;&#1096;&#1080;&#1074;&#1072;&#1090;&#1100;
                                    &#1080;&#1089;&#1087;&#1088;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1077;
                                    &#1085;&#1077;&#1087;&#1086;&#1083;&#1085;&#1099;&#1093; &#1080;&#1083;&#1080;
                                    &#1085;&#1077;&#1087;&#1088;&#1072;&#1074;&#1080;&#1083;&#1100;&#1085;&#1086;
                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1072;&#1085;&#1085;&#1099;&#1093;
                                    &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
                                    &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;, &#1080; &#1089;&#1086;&#1086;&#1073;&#1097;&#1077;&#1085;&#1080;&#1077;
                                    &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1084; &#1083;&#1080;&#1094;&#1072;&#1084;,
                                    &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1084; &#1073;&#1099;&#1083;&#1080;
                                    &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1085;&#1099;
                                    &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1077;
                                    &#1076;&#1072;&#1085;&#1085;&#1099;&#1077;;</p></li>
                                <li><p lang="ru-RU" class="western">&#1047;&#1072;&#1087;&#1088;&#1072;&#1096;&#1080;&#1074;&#1072;&#1090;&#1100;
                                    &#1091;&#1076;&#1072;&#1083;&#1077;&#1085;&#1080;&#1077; &#1080;&#1083;&#1080;
                                    &#1091;&#1085;&#1080;&#1095;&#1090;&#1086;&#1078;&#1077;&#1085;&#1080;&#1077;
                                    &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
                                    &#1076;&#1072;&#1085;&#1085;&#1099;&#1093; &#1074; &#1089;&#1083;&#1091;&#1095;&#1072;&#1077;,
                                    &#1077;&#1089;&#1083;&#1080; &#1087;&#1088;&#1080;&#1095;&#1080;&#1085;
                                    &#1076;&#1083;&#1103; &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1080;
                                    &#1073;&#1086;&#1083;&#1100;&#1096;&#1077; &#1085;&#1077;<span lang="en-US">&#1090;</span>,
                                    &#1074;<span lang="en-US">&#1079;&#1080;&#1088;&#1072;&#1103;</span>
                                    &#1085;&#1072; &#1090;&#1086;, &#1095;&#1090;&#1086; &#1086;&#1085;&#1080;
                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1072;&#1090;&#1099;&#1074;&#1072;&#1102;&#1090;&#1089;&#1103;
                                    &#1074; &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1080;
                                    &#1089; &#1087;&#1086;&#1083;&#1086;&#1078;&#1077;&#1085;&#1080;&#1103;&#1084;&#1080;
                                    &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1075;&#1086;
                                    &#1079;&#1072;&#1082;&#1086;&#1085;&#1072; &#1080; &#1076;&#1088;&#1091;&#1075;&#1080;&#1093;
                                    &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1091;&#1102;&#1097;&#1080;&#1093;
                                    &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1074;, &#1080; &#1089;&#1086;&#1086;&#1073;&#1097;&#1072;&#1090;&#1100;
                                    &#1086;&#1073; &#1080;&#1079;&#1084;&#1077;&#1085;&#1077;&#1085;&#1080;&#1080;
                                    &#1101;&#1090;&#1080;&#1093; &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;,
                                    &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1077; &#1073;&#1099;&#1083;&#1080;
                                    &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1085;&#1099; &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1084;
                                    &#1083;&#1080;&#1094;&#1072;&#1084;;</p></li>
                                <li><p lang="ru-RU" class="western">&#1042; &#1089;&#1083;&#1091;&#1095;&#1072;&#1077;
                                    &#1087;&#1088;&#1080;&#1095;&#1080;&#1085;&#1077;&#1085;&#1080;&#1103;
                                    &#1091;&#1097;&#1077;&#1088;&#1073;&#1072; &#1080;&#1079;-&#1079;&#1072;
                                    &#1085;&#1077;&#1079;&#1072;&#1082;&#1086;&#1085;&#1085;&#1086;&#1081;
                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1080;
                                    &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
                                    &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;, &#1074;&#1099; &#1080;&#1084;&#1077;&#1077;&#1090;&#1077;
                                    &#1087;&#1088;&#1072;&#1074;&#1086; &#1090;&#1088;&#1077;&#1073;&#1086;&#1074;&#1072;&#1090;&#1100;
                                    &#1074;&#1086;&#1079;&#1084;&#1077;&#1097;&#1077;&#1085;&#1080;&#1103;
                                    &#1091;&#1097;&#1077;&#1088;&#1073;&#1072;.</p></li>
                            </ul>
                            <p lang="ru-RU" class="western">&#1063;&#1090;&#1086;&#1073;&#1099;
                                &#1074;&#1086;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1090;&#1100;&#1089;&#1103;
                                &#1074;&#1072;&#1096;&#1080;&#1084;&#1080; &#1087;&#1088;&#1072;&#1074;&#1072;&#1084;&#1080;
                                &#1074; &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1080;
                                &#1089; &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1084;, &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                                &#1086;&#1073;&#1088;&#1072;&#1090;&#1080;&#1090;&#1100;&#1089;&#1103;
                                &#1087;&#1086; &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;
                                bilgi@8milyarsalavat.com.</p>
                        </div>
                    }
                    {
                        i18n.language === "de" &&
                        <div><p>Gesetzestext zur Verwendung personen
                            bezogener Daten</p>
                            <p>Die juristische Person ist f&uuml;r Bestimmung der Zwecke und
                                Mittel der Verarbeitung personenbezogener Daten und f&uuml;r die
                                Einrichtung und Verwaltung des Datenerfassungssystems verantwortlich.</p>
                            <p>In Bezug auf Ihre Rechte nach dem Gesetz;</p>
                            <p>Von den Seiten unserer Website <a href="http://www.8milyarsalawat.com/">www.8milyarsalawat.com</a>
                                &quot;Newsletter&quot; oder &quot;Kontakt&quot; , wo Sie uns Ihre
                                Nachrichten senden k&ouml;nnen; durch die Briefe, die Sie an die oben
                                genannte Adresse senden;</p>
                            <p>Sie k&ouml;nnen uns unter der E-Mail-Adresse
                                <a href="mailto:info@8milyarsalawat.com">info@8milyarsalawat.com</a>
                                erreichen, Ihre Fragen stellen und Ihre Rechte wahrnehmen, die in
                                Artikel 11 des Gesetzes aufgef&uuml;hrt und unten beschrieben sind.</p>
                            <p>Welche personenbezogenen Daten verarbeitet werden;</p>
                            <p>Die nachfolgend aufgef&uuml;hrten Kategorien beziehen sich auf die
                                Verarbeitung Ihrer personenbezogenen Daten;</p>
                            <ul>
                                <li><p>Identit&auml;tsdaten</p></li>
                                <li><p>Kontaktdaten</p></li>
                            </ul>
                            <p>Verwendungszweck personenbezogene Daten;</p>
                            <p>Wir verarbeiten und nutzen Ihre personenbezogenen Daten zum Zwecke
                                der Information &uuml;ber unsere Aktivit&auml;ten und Organisationen
                                und Vorstellung und Informierung &uuml;ber zuk&uuml;nftige
                                Organisationen.</p>
                            <p><br />
                                <br />

                            </p>
                            <p>An wen und zu welchen Zwecken personenbezogene Daten &uuml;bermittelt
                                werden k&ouml;nnen;</p>
                            <p>Ihre personenbezogenen Daten;</p>
                            <p>&bull;An &ouml;ffentliche Einrichtungen und Privatpersonen gem&auml;&szlig;
                                den in dieser Datenschutzrichtlinie und den Artikeln 8 und 9 des
                                Gesetzes genannten Zwecken;</p>
                            <p>&bull; Zum Zwecke der Erbringung von Dienstleistungen an Dritte
                                wie Gesch&auml;ftspartner, Outsourcing-Dienstleister,
                                Hosting-Dienstleister, Anwaltskanzleien, Forschungsunternehmen, Call
                                Center und Unternehmen, die im Rahmen des Wettbewerbs Sponsoring
                                anbieten, beschr&auml;nkt auf die Bereitstellung dieser Dienste;</p>
                            <p><br />
                                <br />

                            </p>
                            <p>Methode und Rechtsgrund f&uuml;r die Erhebung personenbezogener
                                Daten;</p>
                            <p>Wir erheben Ihre personenbezogenen Daten auf nicht-automatische
                                Weise, indem die Nutzer auf unseren Webseıten, Newsletter und
                                Kontaktformulare der Stiftung ihre Daten weitergeben.</p>
                            <p>Rechtsgrund f&uuml;r die Erhebung, Verarbeitung und &Uuml;bermittlung
                                Ihrer personenbezogenen Daten der Nutzer ist in diesem Zusammenhang
                                die &bdquo;ausdr&uuml;ckliche Einwilligung der betroffenen Person&ldquo;
                                gem&auml;&szlig; Artikel 5 Absatz 1 des Gesetzes. In diesem
                                Zusammenhang m&uuml;ssen die Benutzer das K&auml;stchen ankreuzen,
                                dass sie diese Datenschutzerkl&auml;rung akzeptieren.</p>
                            <p><br />
                                <br />

                            </p>
                            <p><br />
                                <br />

                            </p>
                            <p>L&ouml;schung Ihrer personenbezogenen Daten</p>
                            <p>Gem&auml;&szlig; dem Gesetz werden Ihre personenbezogenen Daten,
                                die zu den in diesem Informationstext genannten Zwecken verarbeitet
                                wurden, weiterhin anonymisiert verwendet. Auf schriftliche
                                Anforderung werden Ihre uns mitgeteilten personenbezogenen Daten
                                jedoch aus unserer Datenbank gel&ouml;scht.</p>
                            <p>Ihre Antr&auml;ge auf L&ouml;schung
                                Ihrer personenbezogenen Daten</p>
                            <p>auf der &quot;Kontakt&quot;-Seite unserer Website
                                www.8milyarsalawat.com, wo Sie uns Ihre Nachrichten senden k&ouml;nnen;
                                durch die Briefe, die Sie an die oben genannte Adresse senden;</p>
                            <p>Sie k&ouml;nnen es uns an info@8milyarsalawat.com zusenden, das
                                ist die Registered Electronic Mail Adresse der Stiftung.</p>
                            <p><br />
                                <br />

                            </p>
                            <p>Ihre in Artikel 11 des Gesetzes aufgez&auml;hlten Rechte;</p>
                            <p><br />
                                <br />

                            </p>
                            <p>Im Rahmen von Artikel 11 des Gesetzes ist jeder &uuml;ber sich
                                selbst;</p>
                            <p><br />
                                <br />

                            </p>
                            <p>&bull;Erfahren, ob personenbezogene Daten verarbeitet werden oder
                                nicht,</p>
                            <p>&bull; wenn personenbezogene Daten verarbeitet wurden, Auskunft
                                dar&uuml;ber verlangen,</p>
                            <p>&bull;Erfahren des Zwecks der Verarbeitung personenbezogener Daten
                                und ob sie ihrem Zweck entsprechend verwendet werden,</p>
                            <p>&bull; Kenntnis der Dritten, an die personenbezogene Daten im In-
                                oder Ausland &uuml;bermittelt werden,</p>
                            <p>&bull; die Berichtigung personenbezogener Daten bei
                                unvollst&auml;ndiger oder fehlerhafter Verarbeitung zu verlangen und
                                die Benachrichtigung der in diesem Rahmen get&auml;tigten Transaktion
                                an die Dritten zu verlangen, an die die personenbezogenen Daten
                                &uuml;bermittelt wurden,</p>
                            <p>&bull; Antrag auf L&ouml;schung oder Vernichtung personenbezogener
                                Daten f&uuml;r den Fall, dass die Verarbeitungsgr&uuml;nde entfallen
                                sind, obwohl sie in &Uuml;bereinstimmung mit dem Gesetz und anderen
                                einschl&auml;gigen Rechtsvorschriften verarbeitet wurden, und die
                                Benachrichtigung der in diesem Umfang erfolgten Transaktion an den
                                Dritten zu verlangen Parteien, an die die personenbezogenen Daten
                                &uuml;bermittelt wurden,</p>
                            <p>&bull; Widerspruch gegen die Entstehung eines Ergebnisses gegen
                                die Person selbst durch Analyse der verarbeiteten Daten
                                ausschlie&szlig;lich durch automatisierte Systeme,</p>
                            <p>&bull; Sie hat das Recht, im Falle eines Verlustes aufgrund der
                                unrechtm&auml;&szlig;igen Verarbeitung personenbezogener Daten den
                                Ersatz des Schadens zu verlangen.</p>
                            <p><br />
                                <br />

                            </p>
                            <p>Um von Ihren gesetzlichen Rechten Gebrauch zu machen, k&ouml;nnen
                                Sie sich per E-Mail an info@8milyarsalawat.com wenden.</p>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        setShowKvkkModal(false);
                        setkvkk(true);
                        store.userDropdown.current.toggle();
                    }}><Trans>Okudum, onaylıyorum</Trans></Button>
                    <Button onClick={() => {
                        setShowKvkkModal(false);
                        setkvkk(false);
                        store.userDropdown.current.toggle();
                    }}><Trans>Ret ediyorum</Trans></Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default observer(RegisterForm);