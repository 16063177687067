import React from 'react';
import PropTypes from 'prop-types';
import './FormButton.scss';
import { Button, Spinner } from 'reactstrap';

const FormButton = props => {
    return <Button
        onClick={props.onClick}
        ref={props.parentRef}
        className={`form-button ${props.sending ? "progress-bar-animated progress-bar-striped" : ""}`}
        type={props.type || "button"}
        color={props.color || "primary"}
        disabled={props.sending}>
        {props.text}
    </Button>
}

FormButton.propTypes = {
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["submit", "button"]),
    color: PropTypes.oneOf(["primary", "light"]),
    onClick: PropTypes.func,
    parentRef: PropTypes.object,
    sending: PropTypes.bool,
}

export default FormButton