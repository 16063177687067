import React, { useEffect, useState, useCallback } from "react";
import "./MainSection.scss";
import { Input, Button, Alert } from "reactstrap";
import CountUp from "react-countup";
import axios from "axios";
import { Trans, useTranslation } from "react-i18next";
import utils from "../../../../utils";
import {
  addMilliseconds,
  differenceInDays,
  intervalToDuration,
} from "date-fns";
import store from "../../../../store";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router";
import _ from "lodash";
import { getCountries, regionsTR, regionsTRDict } from "../../../../consts";
import NumberFormat from "react-number-format";
import https from "https";

const MainSection = (props) => {
  const { t, i18n } = useTranslation();
  const [amount, setamount] = useState(utils.isDev ? 1 : 1);
  const [amountManuel, setamountManuel] = useState(1);
  const [daysLeft, setdaysLeft] = useState(0);
  const [timeLeft, settimeLeft] = useState({});
  const form = React.useRef();
  const counterRef = React.useRef();
  const [errorMessage, seterrorMessage] = useState("");
  const [message, setmessage] = useState("");
  const [sending, setsending] = useState(false);
  const [showManuel, setshowManuel] = useState(false);
  const location = useLocation();
  const history = useHistory();

  // You can use useEffect to trigger the verification as soon as the component being loaded
  // useEffect(() => {
  //     if (executeRecaptcha)
  //         refreshRecaptchaToken();
  // }, [refreshRecaptchaToken, executeRecaptcha]);

  useEffect(() => {
    if (store.showAddingConfirmationCount) {
      setmessage(
        t("E-postanız doğrulandı", {
          0: utils.formatNumber(store.showAddingConfirmationCount),
        })
      );
      counterRef.current.scrollIntoView({ behavior: "smooth" });
      store.showAddingConfirmationCount = null;
    }

    const kandilTime = new Date("2025-09-03"); //!!! Attention, use as str dont use js date constructor, because in js months start from 0 and make confusing.

    var currentTimeDeltaMs = 0;
    const calcTimeLeft = () => {
      var interval;
      if (new Date() < kandilTime)
        interval = {
          start: addMilliseconds(new Date(), currentTimeDeltaMs),
          end: kandilTime,
        };
      else interval = { start: kandilTime, end: kandilTime };
      setdaysLeft(differenceInDays(interval.end, interval.start));
      settimeLeft(intervalToDuration(interval));
    };

    calcTimeLeft();

    const interval = window.setInterval(calcTimeLeft, 1000);

    //if (!store.geoCountryCode || (store.geoCountryCode === "TR" && !store.geoRegion)) {
    axios
      .get("https://ipapi.co/json/", {
        headers: {
          "Accept-Language": "en-US,en;",
        },
      })
      .then((response) => {
        store.geoCountryName = t(response.data.country_name.toUpperCase());
        store.geoCountryCode = response.data.country_code;
        if (response.data.country_code === "TR") {
          store.geoRegionOriginal = response.data.region;
          store.geoRegion =
            regionsTRDict[response.data.region_code] === undefined
              ? ""
              : regionsTRDict[response.data.region_code];
        } else {
          store.geoRegion = null;
        }
      })
      .catch((error) => {
        window.console.log("Error : " + utils.getAxiosErrorMessage(error));
      });
    //}

    axios
      .get(`https://worldclockapi.com/api/json/utc/now`, {
        httpsAgent: new https.Agent({
          rejectUnauthorized: false,
        }),
      })
      .then((response) => {
        const delta =
          Date.parse(response.data.currentDateTime) -
          Date.parse(new Date().toISOString()); /*browser utc time*/
        currentTimeDeltaMs = delta;
      })
      .catch((error) => {});

    // axios.get(`http://worldtimeapi.org/api/timezone/europe/istanbul?timestamp=${new Date().getTime()}`)
    //     .then((response) => {
    //         const delta = new Date() - Date.parse(response.data.datetime);
    //         currentTimeDeltaMs = delta;
    //     }).catch((error) => {
    //     });

    if (store.ended && !store.endMessageShowed) {
      store.messageOnClosed = () => store.endMessageShowed = true;
      store.message = (
        <div className="sukranDiv" dangerouslySetInnerHTML={{ __html: t("tesekkur", { 0: "1447", 1: "1448" }) }}>
        </div>
      );
    }

    return () => {
      window.clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const send = async (e) => {
    e.preventDefault();

    // store.message = <div className="sukranDiv">
    //     Değerli Salâvat gönüllüsü,<br />
    //     1445 yılı 8 Milyar Salavat kampanyamız sona ermiştir. <br />
    //     Bir sonraki kampanya dönemi için salavat girişleri en yakın zamanda tekrar başlayacaktır.<br />
    // </div>;

    if (store.ended || sending) return;

    e.preventDefault();

    seterrorMessage("");
    if (message) setmessage("\u00A0");
    else setmessage("");

    const formData = utils.formSerialize(form.current);
    formData.amount = showManuel ? amountManuel : amount;

    if (formData.amount < 0 || isNaN(formData.amount)) {
      return;
    }

    if (!store.confirmSending(formData.amount)) {
      setmessage("");
      _.delay(() => setmessage(t("Salvat eklemesi yapılmadı")), 100);
      return;
    }

    setsending(true);

    formData.recaptchaToken = await store.getRecaptchaToken();
    // if (!formData.recaptchaToken) {
    //     store.error = t("Recaptcha_error");
    //     setsending(false);
    //     return;
    // }

    if (store.token) {
      axios
        .post(`${utils.ApiAddress}/api/salawat/add`, formData, {
          headers: { Authorization: "Bearer " + store.token },
        })
        .then((response) => {
          if (response.data.code >= 400 || !response.data.message.statistics) {
            props.parent.seterrorMessage(utils.getApiError(response));
          } else {
            setmessage(
              t("salavat eklendi", { 0: utils.formatNumber(formData.amount) })
            );
            store.refreshUserStats();
            //store.addUserSalavats(amount);
            // store.totalOld = store.totalCurrent;
            // store.totalCurrent = _.toNumber(response.data.message.statistics.general.total_salawats);
            store.setNewTotalCurrent(
              response.data.message.statistics.general.total_salawats
            );
            store.setPrevSendedCount(formData.amount);
          }
        })
        .catch((error) => {
          console.log(error);
          seterrorMessage(utils.getAxiosErrorMessage(error));
        })
        .finally(() => {
          setamountManuel("");
          setsending(false);
        });
    } else {
      if (formData.name) localStorage.setItem("name", formData.name);
      if (formData.email) localStorage.setItem("email", formData.email);

      formData.language = i18n.language;
      formData.country = store.geoCountryCode
        ? store.geoCountryCode.toLowerCase()
        : "tr";
      if (formData.country === "tr")
        formData.city = store.geoRegion || store.geoRegionOriginal || "";
      else delete formData.city;

      //formData.city = formData.country === "tr" ? store.geoRegion || store.geoRegionOriginal || "" : null;
      store.saveAnonymLocationToLocalStorage();

      axios
        .post(`${utils.ApiAddress}/api/salawat/add`, formData)
        .then((response) => {
          if (response.data.code >= 400) {
            props.parent.seterrorMessage(utils.getApiError(response));
          } else if (response.data.message === "Confirmation Email sent") {
            store.message = t("E-posta kutunuza doğrulama", {
              0: utils.formatNumber(formData.amount),
            });
          } else {
            setmessage(
              t("salavat eklendi", { 0: utils.formatNumber(formData.amount) })
            );
            store.refreshUserStats();

            if (
              response.data.message &&
              response.data.message.statistics &&
              response.data.message.statistics.general &&
              response.data.message.statistics.general.total_salawats > 0
            )
              store.setNewTotalCurrent(
                response.data.message.statistics.general.total_salawats
              );
            else store.addUserSalavats(formData.amount);

            store.setPrevSendedCount(formData.amount);
          }
        })
        .catch((error) => {
          console.log(error);
          const errorMessage = utils.getAxiosErrorMessage(error);
          if (errorMessage === "User must login") {
            store.loginEmail = formData.email;
            store.message = t("E-postanız ile sitemizde", {
              0: store.loginEmail,
            });
            store.messageOnClosed = () => store.openUserLogin();
          } else {
            seterrorMessage(errorMessage);
          }
        })
        .finally(() => {
          setamountManuel(null);
          setsending(false);
        });
    }
  };

  const dashDelta =
    (store.token
      ? -12 * 4
      : !store.geoCountryCode || store.geoCountryCode === "TR"
      ? 72
      : 0) + (showManuel ? 72 : 0);

  return (
    <>
      <div id="main-section-container">
        {/* <img src="/assets/images/logo.svg" alt="" id="logo" /> */}
        <div className="row">
          <div className="col order-12 order-sm-6 order-md-6">
            <div className="d-flex align-items-center h-100">
              {/* <Typist> */}
              <p id="slogan">
                {/* <p className="sectionSon">
                                    TEŞEKKÜRLER<br />
                                    <br />
                                    Son Peygamber Hz. Muhammed'in (sas) sevgi ve ünsiyetine,
                                    insanlığın selametine vesile olması temennisiyle düzenlediğimiz
                                    8 Milyar İnsan Adına 8 Milyar Salavat kampanyamız
                                    1443 yılında
                                    Mevlid Kandili itibariyle sona ermiştir.
                                    Dünyanın dört bir yanından  ve Türkiye'nin her köşesinden kampanyamıza katılan tüm Peygamber sevdalılarına  teşekkürlerimizi sunarız.
                                    1444 Mevlid Kandili'nde yeniden muhabbetle buluşmak üzere...
                                    (Salavat sayacımız 1444 yılı kampanyasına yönelik olarak  1 Rebiulahir 1443 (6 Kasım 2021) tarihinde başlatılacaktır.)
                                </p> */}
                <Trans i18nKey="Dünyada yaşayan insanlar adına">
                  <span className="section1">
                    Dünyada yaşayan insanlar adına{" "}
                  </span>
                  <span className="section2">
                    dünyanın her yerinden salavat
                  </span>
                  <span className="heart-l"></span>
                  <span className="section2"> gönderenlere sen de katıl</span>
                </Trans>
              </p>
              {/* </Typist> */}
            </div>
          </div>
          <div className="col order-1 order-sm-6 order-md-6">
            <div className="d-md-flex">
              <div id="form-container" className="flex-grow-1">
                <div>
                  <div className="item" ref={counterRef}>
                    <span id="label1">
                      <Trans>Bugüne Kadar</Trans>
                    </span>
                    <CountUp
                      start={store.totalOld}
                      end={store.totalCurrent}
                      delay={0}
                      separator=","
                    >
                      {({ countUpRef }) => (
                        <span
                          id="label2"
                          className="heart-l"
                          ref={countUpRef}
                        />
                      )}
                    </CountUp>
                    {/* <svg id="dash" width="135" height="550" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <line strokeWidth="6" strokeDasharray="0,12" strokeLinecap="round" id="svg_1" y2="7" x2="60" y1="7" x1="36" stroke="#fff" fill="none"></line>
                                                <line strokeWidth="6" strokeDasharray="0,12" strokeLinecap="round" id="svg_2" y2="445" x2="60" y1="7" x1="60" stroke="#fff" fill="none"></line>
                                                <line strokeWidth="6" strokeDasharray="0,12" strokeLinecap="round" id="svg_3" y2="439" x2="60" y1="439" x1="12" stroke="#fff" fill="none"></line>
                                            </g>
                                        </svg> */}
                    <svg
                      id="dash"
                      width="65"
                      height={493 + dashDelta}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <line
                          strokeWidth="6"
                          strokeDasharray="0,12"
                          strokeLinecap="round"
                          id="svg_1"
                          y2="7"
                          x2="60"
                          y1="7"
                          x1="36"
                          stroke="#fff"
                          fill="none"
                        ></line>
                        <line
                          strokeWidth="6"
                          strokeDasharray="0,12"
                          strokeLinecap="round"
                          id="svg_2"
                          y2={480 + dashDelta}
                          x2="60"
                          y1="7"
                          x1="60"
                          stroke="#fff"
                          fill="none"
                        ></line>
                        <line
                          strokeWidth="6"
                          strokeDasharray="0,12"
                          strokeLinecap="round"
                          id="svg_3"
                          y2={475 + dashDelta}
                          x2="70"
                          y1={475 + dashDelta}
                          x1="24"
                          stroke="#fff"
                          fill="none"
                        ></line>
                      </g>
                    </svg>
                  </div>
                  <div id="progressbar-container" className="d-flex">
                    <Input
                      className="e-range"
                      type="range"
                      value={(100 * store.totalCurrent) / 8000000000.0}
                      readOnly
                      disabled
                    />
                    <img src="/assets/images/alarm_on-24px.svg" alt="" />
                  </div>
                  <div className="item">
                    <CountUp
                      start={store.MainCountUpShowed ? 8000000000 : 0}
                      end={8000000000}
                      delay={0}
                      separator=","
                      onEnd={() => (store.MainCountUpShowed = true)}
                    >
                      {({ countUpRef }) => (
                        <span id="label3" ref={countUpRef} />
                      )}
                    </CountUp>
                  </div>
                  <div id="input-container">
                    <form autoComplete="off" onSubmit={send} ref={form}>
                      {store.token ? (
                        <>
                          <Input
                            disabled={store.ended}
                            type="text"
                            name="name"
                            placeholder={t("Adınız Soyadınız")}
                            autoComplete="off"
                            value={store.user.name || ""}
                            readOnly
                          />
                          <Input
                            disabled={store.ended}
                            type="email"
                            name="email"
                            placeholder={t("Email Adresiniz")}
                            value={store.user.email || ""}
                            readOnly
                          />
                        </>
                      ) : (
                        <>
                          <Input
                            disabled={store.ended}
                            type="text"
                            name="name"
                            placeholder={t("Adınız Soyadınız")}
                            autoComplete="off"
                            //onChange={e => setname(e.target.value)}
                            defaultValue={localStorage.getItem("name") || ""}
                            required
                          />
                          <Input
                            disabled={store.ended}
                            type="email"
                            name="email"
                            placeholder={t("Email Adresiniz")}
                            autoComplete="off"
                            //onChange={e => setemail(e.target.value)}
                            defaultValue={localStorage.getItem("email") || ""}
                            required
                          />
                          <Input
                            disabled={store.ended}
                            key={
                              i18n.language + store.geoCountryCode + "country"
                            }
                            name="country"
                            type="select"
                            required
                            defaultValue={store.geoCountryCode || "TR"}
                            onChange={(e) =>
                              (store.geoCountryCode = e.target.value)
                            }
                          >
                            {getCountries().map((i) => (
                              <option key={i.value} value={i.value}>
                                {t(i.text)}
                              </option>
                            ))}
                          </Input>
                          {!store.geoCountryCode ||
                          store.geoCountryCode === "TR" ? (
                            <Input
                              disabled={store.ended}
                              key={i18n.language + store.geoRegion + "city"}
                              name="city"
                              type="select"
                              required
                              onChange={(e) =>
                                (store.geoRegion = e.target.value)
                              }
                              defaultValue={store.geoRegion || ""}
                            >
                              <option value=""></option>
                              {regionsTR.map((i) => (
                                <option key={i} value={i}>
                                  {i}
                                </option>
                              ))}
                            </Input>
                          ) : null}
                        </>
                      )}
                      <Input
                        type="select"
                        name="amount"
                        disabled={store.ended}
                        onChange={(e) => {
                          if (e.target.value === "-1") {
                            setshowManuel(true);
                            setamount(e.target.value);
                          } else if (e.target.value === "0") {
                            if (store.token) history.push("/dashboard");
                            else _.delay(() => store.openUserLogin(true), 100);
                          } else {
                            setshowManuel(false);
                            setamount(e.target.value);
                          }
                        }}
                        value={amount}
                        required
                      >
                        <option value={-1}>{t("özel sayı")}</option>
                        <option value={1}>+1 {t("Salavat")}</option>
                        <option value={100}>+100 {t("Salavat")}</option>
                        <option value={300}>+300 {t("Salavat")}</option>
                        <option value={500}>+500 {t("Salavat")}</option>
                        <option value={1000}>+1.000 {t("Salavat")}</option>
                        <option value={5000}>+5.000 {t("Salavat")}</option>
                        <option value={10000}>+10.000 {t("Salavat")}</option>
                        <option value={20000}>+20.000 {t("Salavat")}</option>
                        <option value={50000}>+50.000 {t("Salavat")}</option>
                        <option value={100000}>+100.000 {t("Salavat")}</option>
                        <option value={0}>{t("Daha fazla ekle...")}</option>
                      </Input>
                      {
                        showManuel && (
                          <NumberFormat
                            min={1}
                            max={100000}
                            value={amountManuel}
                            thousandSeparator={true}
                            allowNegative={false}
                            className="form-control"
                            onChange={(e) => {
                              setamountManuel(e.target.value);
                            }}
                          />
                        )
                        /*<Input type="number"
                                                value={amountManuel}
                                                min={1}
                                                max={100000}
                                                onChange={e => {
                                                    setamountManuel(e.target.value);
                                                }} />*/
                      }
                      <Button
                        type="submit"
                        disabled={sending || store.ended}
                        className={
                          sending
                            ? "progress-bar-animated progress-bar-striped"
                            : ""
                        }
                      >
                        <Trans i18nKey="Salavat Gönder">
                          <span className="heart-l mr-4">Salavat</span> Gönder
                        </Trans>
                      </Button>
                      {message && (
                        <Alert color="success fade" className="mt-2">
                          {message}
                        </Alert>
                      )}
                      {errorMessage && (
                        <Alert color="warning fade" className="mt-2">
                          {errorMessage}
                        </Alert>
                      )}
                    </form>
                    <div id="location">
                      {/* <img src="/assets/images/where_to_vote.svg" alt="" />
                                            <span><Trans>Konumunuz</Trans>: {store.geoRegion} {t(countriesByCode[(store.geoCountryCode || "").toLowerCase()])}</span> */}
                      {/* <span><Trans>Konumunuz</Trans>: {t(countriesByCode[(store.geoCountryCode || "")])}</span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div id="hicri-date-time" className="d-none d-lg-block">
                <div className="d-flex flex-column">
                  <div className="label2">
                    <Trans>mevlidTarihiAy</Trans>
                    <br />
                    <Trans>mevlidTarihiGunes</Trans>
                    <br />
                    <Trans i18nKey="Mevlid Kandili’ne kalan">
                      <span
                        className="mt-1 d-inline-block"
                        style={{ textTransform: "none" }}
                      >
                        Mevlid
                        <br />
                        Kandili’ne kalan
                      </span>
                    </Trans>
                  </div>
                  <div id="divider"></div>
                  <div className="mb-3">
                    <span className="label1">{daysLeft}</span>
                    <span className="label2">
                      {daysLeft > 1 ? t("gün_plural") : t("gün")}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="label1">{timeLeft.hours}</span>
                    <span className="label2">
                      {timeLeft.hours > 1 ? t("saat_plural") : t("saat")}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="label1">{timeLeft.minutes}</span>
                    <span className="label2">
                      {timeLeft.minutes > 1 ? t("dakika_plural") : t("dakika")}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="label1">{timeLeft.seconds}</span>
                    <span className="label2">
                      {timeLeft.seconds > 1 ? t("saniye_plural") : t("saniye")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="hicri-date-time" className="d-lg-none">
          <div className="d-flex flex-column">
            <div>
              <span className="mr-4">
                <Trans>mevlidTarihiAy</Trans>
              </span>
              <span>
                <Trans>mevlidTarihiGunes</Trans>
              </span>{" "}
              <br />
              <Trans i18nKey="Mevlid Kandili’ne kalan">
                <span
                  className="d-inline-block mt-1"
                  style={{ textTransform: "none", fontSize: 17 }}
                >
                  Mevlid Kandili’ne kalan
                </span>
              </Trans>
            </div>
            <div className="mb-3">
              <span className="label1">{daysLeft}</span>
              <span className="label2 mr-3">
                {" "}
                <Trans>gün</Trans>
              </span>
              <span className="label1">{timeLeft.hours}</span>
              <span className="label2 mr-3">
                {" "}
                <Trans>saat</Trans>
              </span>
              <span className="label1">{timeLeft.minutes}</span>
              <span className="label2 mr-3">
                {" "}
                <Trans>dakika</Trans>
              </span>
              <span className="label1">{timeLeft.seconds}</span>
              <span className="label2">
                {" "}
                <Trans>saniye</Trans>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(MainSection);
