import axios from 'axios';
import React, { useContext, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Alert, Col, Row } from 'reactstrap';
import FormButton from '../../../components/form-button/FormButton';
import FormInput from '../../../components/form-input/FormInput';
import { MainContext } from '../../../context/MainContext';
import utils from '../../../utils';
import store from '../../../store';
import './Contact.scss';
import { observer } from 'mobx-react';

const Contact = () => {

    const [isMenuOpen, setMenuOpen] = useContext(MainContext);
    const [errorMessage, seterrorMessage] = useState('');
    // const [message, setmessage] = useState('');
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [sending, setsending] = useState(false);
    const form = useRef();

    const send = (e) => {
        e.preventDefault();
        seterrorMessage("");
        // setmessage("");
        var formData = utils.formSerialize(form.current);
        setsending(true);

        axios.post(`${utils.ApiAddress}/api/sendemail`, formData)
            .then(responseJson => {
                // if (responseJson && responseJson.error === true) {
                //     seterrorMessage(responseJson.message)
                // } else if (responseJson && responseJson.errorMessage) {
                //     seterrorMessage(responseJson.errorMessage)
                // } else {
                    store.message = t("Mesajınız gönderildi.");
                    store.messageOnClosed = () => history.push('/');
                //}
            })
            .catch(error => {
                console.log(error)
                seterrorMessage(utils.getAxiosErrorMessage(error));
            })
            .finally(() => setsending(false));

        // axios.post(`https://8milyarsalavat.com/api/sendemail`, formData)
        //     .then(responseJson => {
        //         if (responseJson && responseJson.error === true) {
        //             seterrorMessage(responseJson.message)
        //         } else if (responseJson && responseJson.errorMessage) {
        //             seterrorMessage(responseJson.errorMessage)
        //         } else {
        //             store.message = t("Mesajınız gönderildi.");
        //             store.messageOnClosed = () => history.push('/');
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error)
        //         seterrorMessage(utils.getAxiosErrorMessage(error));
        //     })
        //     .finally(() => setsending(false));
    }

    return (
        <div id="contact-container" className={`vh-100 ${!isMenuOpen && "z-index-4"}`}>
            <div className="info-card">
                <span className="title"><Trans>Bizimle İletişime Geçin</Trans></span>
                <form ref={form} onSubmit={send}>
                    <Row>
                        <Col>
                            <FormInput name="name" label={t("Adınız Soyadınız")} required maxLength={100} defaultValue={store.user && store.user.name} />
                        </Col>
                        <Col>
                            <FormInput name="email" label={t("Email Adresiniz")} type="text" required maxLength={500} defaultValue={store.user && store.user.email} />
                        </Col>
                    </Row>
                    <FormInput name="message" label={t("Mesajınız")} type="textarea" required maxLength={1000} />
                    <div className="text-right">
                        <FormButton type="submit" color="primary" text={t("gönder")} sending={sending} />
                    </div>
                    {/* {message && <Alert color="success fade" className="mt-2">{message}</Alert>} */}
                    {errorMessage && <Alert color="warning fade" className="mt-2">{errorMessage}</Alert>}
                </form>
            </div>
        </div>
    )
}

export default observer(Contact);