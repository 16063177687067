
import i18n from "i18next";

export const getCountries = () => [
    { text: "TÜRKİYE", value: "TR" },
    { text: "AMERİKA BİRLEŞİK DEVLETLERİ", value: "US" },
    { text: "AFGANİSTAN", value: "AF" },
    { text: "ALMANYA", value: "DE" },
    { text: "AMERİKA KÜÇÜK OUT. ADALARI", value: "UM" },
    { text: "AMERİKAN SAMOA", value: "AS" },
    { text: "ANDORRA", value: "AD" },
    { text: "ANGOLA", value: "AO" },
    { text: "ANGUİLLA", value: "AI" },
    { text: "ANTARTİKA", value: "AQ" },
    { text: "ANTİGUA-BARBUDA", value: "AG" },
    { text: "ARJANTİN", value: "AR" },
    { text: "ARNAVUTLUK", value: "AL" },
    { text: "ARUBA", value: "AW" },
    { text: "AVUSTRALYA", value: "AU" },
    { text: "AVUSTURYA", value: "AT" },
    { text: "AZERBAYCAN", value: "AZ" },
    { text: "BAHAMALAR", value: "BS" },
    { text: "BAHREYN", value: "BH" },
    { text: "BANGLADEŞ", value: "BD" },
    { text: "BARBADOS", value: "BB" },
    { text: "BATI TİMOR", value: "TL" },
    { text: "BELÇİKA", value: "BE" },
    { text: "BELİZE", value: "BZ" },
    { text: "BENİN", value: "BJ" },
    { text: "BERMUDA", value: "BM" },
    { text: "BEYAZ RUSYA", value: "BY" },
    { text: "BHUTAN", value: "BT" },
    { text: "BİRLEŞİK ARAP EMİRLİKLERİ", value: "AE" },
    { text: "BİRLEŞİK KRALLIK", value: "GB" },
    { text: "BOLİVYA", value: "BO" },
    { text: "BOSNA HERSEK", value: "BA" },
    { text: "BOTSVANA", value: "BW" },
    { text: "BOUVET ADASI", value: "BV" },
    { text: "BREZİLYA", value: "BR" },
    { text: "BRUNEİ", value: "BN" },
    { text: "BULGARİSTAN", value: "BG" },
    { text: "BURKİNA FASO", value: "BF" },
    { text: "BURMA (BİRMANYA)", value: "MM" },
    { text: "BURUNDİ", value: "BI" },
    { text: "CAPE VERDE", value: "CV" },
    { text: "CAYMAN ADALARI", value: "KY" },
    { text: "CEBELİTARIK", value: "GI" },
    { text: "CEUTA", value: "XC" },
    { text: "CEZAYİR", value: "DZ" },
    { text: "CİBUTİ", value: "DJ" },
    { text: "COCOS ADALARI", value: "CC" },
    { text: "COOK ADASI", value: "CK" },
    { text: "ÇAD", value: "TD" },
    { text: "ÇEK CUMHURİYETİ", value: "CZ" },
    { text: "ÇİN HALK CUMHUR.", value: "CN" },
    { text: "DANİMARKA", value: "DK" },
    { text: "DOMİNİK CUMHUR.", value: "DO" },
    { text: "DOMİNİKA", value: "DM" },
    { text: "EKVATOR", value: "EC" },
    { text: "EKVATOR GİNESİ", value: "GQ" },
    { text: "EL SALVADOR", value: "SV" },
    { text: "ENDONEZYA", value: "ID" },
    { text: "ERİTRE", value: "ER" },
    { text: "ERMENİSTAN", value: "AM" },
    { text: "ESTONYA", value: "EE" },
    { text: "ETİYOPYA", value: "ET" },
    { text: "FALKLAND ADALARI", value: "FK" },
    { text: "FAROE ADALARI", value: "FO" },
    { text: "FAS", value: "MA" },
    { text: "FİJİ", value: "FJ" },
    { text: "FİLDİŞİ SAHİLİ", value: "CI" },
    { text: "FİLİPİNLER", value: "PH" },
    { text: "FİNLANDİYA", value: "FI" },
    { text: "FRANSA", value: "FR" },
    { text: "FRANSA GÜNEY BÖLGELERİ", value: "TF" },
    { text: "FRANSIZ POLİNEZYASI", value: "PF" },
    { text: "GABON", value: "GA" },
    { text: "GAMBİYA", value: "GM" },
    { text: "GANA", value: "GH" },
    { text: "GİNE", value: "GN" },
    { text: "GİNE-BİSSAU", value: "GW" },
    { text: "GRENADA", value: "GD" },
    { text: "GRÖNLAND", value: "GL" },
    { text: "GUAM", value: "GU" },
    { text: "GUATEMALA", value: "GT" },
    { text: "GUYANA", value: "GY" },
    { text: "GÜNEY AFRİKA CUM.", value: "ZA" },
    { text: "GÜNEY GEORGİA VE GÜNEY SANDVİÇ ADALARI", value: "GS" },
    { text: "GÜNEY KORE CUM.", value: "KR" },
    { text: "GÜRCİSTAN", value: "GE" },
    { text: "HAİTİ", value: "HT" },
    { text: "HEARD ADALAI VE MC DONALD ADASI", value: "HM" },
    { text: "HIRVATİSTAN", value: "HR" },
    { text: "HİNDİSTAN", value: "IN" },
    { text: "HOLLANDA", value: "NL" },
    { text: "HOLLANDA ANTİLLERİ", value: "AN" },
    { text: "HONDURAS", value: "HN" },
    { text: "HONG-KONG", value: "HK" },
    { text: "IRAK", value: "IQ" },
    { text: "İNGİLİZ HİNT OKYANUSU TOPRAKLARI", value: "IO" },
    { text: "BRİTANYA VİRJİN ADALARI", value: "VG" },
    { text: "İRAN", value: "IR" },
    { text: "İRLANDA", value: "IE" },
    { text: "İSPANYA", value: "ES" },
    { text: "İSRAİL", value: "IL" },
    { text: "İSVEÇ", value: "SE" },
    { text: "İSVİÇRE", value: "CH" },
    { text: "FİLİSTİN", value: "PS" },
    { text: "İTALYA", value: "IT" },
    { text: "İZLANDA", value: "IS" },
    { text: "JAMAİKA", value: "JM" },
    { text: "JAPONYA", value: "JP" },
    { text: "KAMBOÇYA", value: "KH" },
    { text: "KAMERUN", value: "CM" },
    { text: "KANADA", value: "CA" },
    { text: "KARADAĞ", value: "ME" },
    { text: "KATAR", value: "QA" },
    { text: "KAZAKİSTAN", value: "KZ" },
    { text: "KENYA", value: "KE" },
    { text: "KIRGIZİSTAN", value: "KG" },
    { text: "KİRİBATİ", value: "KI" },
    { text: "KKTC", value: "CYN" },
    { text: "KOLOMBİYA", value: "CO" },
    { text: "KOMORLAR", value: "KM" },
    { text: "KONGO", value: "CG" },
    { text: "KONGO DEM. CUM", value: "CD" },
    { text: "KOSOVA", value: "XK" },
    { text: "KOSTA RİKA", value: "CR" },
    { text: "KRİSMIS ADALARI", value: "CX" },
    { text: "KUVEYT", value: "KW" },
    { text: "KUZEY KORE", value: "KP" },
    { text: "KUZEY MARİANA ADALARI", value: "MP" },
    { text: "KÜBA", value: "CU" },
    { text: "LAOS DEMOK.HALK C", value: "LA" },
    { text: "LESOTO", value: "LS" },
    { text: "LETONYA", value: "LV" },
    { text: "LIECHTENSTEIN", value: "LI" },
    { text: "LİBERYA", value: "LR" },
    { text: "LİBYA", value: "LY" },
    { text: "LİTVANYA", value: "LT" },
    { text: "LÜBNAN", value: "LB" },
    { text: "LÜKSEMBURG", value: "LU" },
    { text: "MACARİSTAN", value: "HU" },
    { text: "MADAGASKAR", value: "MG" },
    { text: "MAKAO", value: "MO" },
    { text: "MAKEDONYA", value: "MK" },
    { text: "MALAVİ", value: "MW" },
    { text: "MALDİVLER", value: "MV" },
    { text: "MALEZYA", value: "MY" },
    { text: "MALİ", value: "ML" },
    { text: "MALTA", value: "MT" },
    { text: "MARSHAL ADALARI", value: "MH" },
    { text: "MAURİTİUS", value: "MU" },
    { text: "MAYOTTE", value: "YT" },
    { text: "MEKSİKA", value: "MX" },
    { text: "MELİLLA", value: "XL" },
    { text: "MISIR", value: "EG" },
    { text: "MİKRONEZYA", value: "FM" },
    { text: "MOĞOLİSTAN", value: "MN" },
    { text: "MOLDOVYA", value: "MD" },
    { text: "MONTSERRAT", value: "MS" },
    { text: "MORİTANYA", value: "MR" },
    { text: "MOZAMBİK", value: "MZ" },
    { text: "NAMİBYA", value: "NA" },
    { text: "NAURU", value: "NR" },
    { text: "NEPAL", value: "NP" },
    { text: "NIUE ADASI", value: "NU" },
    { text: "NİJER", value: "NE" },
    { text: "NİJERYA", value: "NG" },
    { text: "NİKARAGUA", value: "NI" },
    { text: "NORFOLK ADASI", value: "NF" },
    { text: "NORVEÇ", value: "NO" },
    { text: "ORTA AFRİKA CUM", value: "CF" },
    { text: "ÖZBEKİSTAN", value: "UZ" },
    { text: "PAKİSTAN", value: "PK" },
    { text: "PALAU", value: "PW" },
    { text: "PANAMA", value: "PA" },
    { text: "PAPUA (YENİ GİNE)", value: "PG" },
    { text: "PARAGUAY", value: "PY" },
    { text: "PERU", value: "PE" },
    { text: "PİTCAİRN", value: "PN" },
    { text: "POLONYA", value: "PL" },
    { text: "PORTEKİZ", value: "PT" },
    { text: "ROMANYA", value: "RO" },
    { text: "RUANDA", value: "RW" },
    { text: "RUSYA FEDERASYONU", value: "RU" },
    { text: "SAMOA", value: "WS" },
    { text: "SAN MARİNO", value: "SM" },
    { text: "SAO TOME VE PRIN.", value: "ST" },
    { text: "SENEGAL", value: "SN" },
    { text: "SEYŞELLER", value: "SC" },
    { text: "SIRBİSTAN", value: "XS" },
    { text: "SİERRA LEONE", value: "SL" },
    { text: "SİNGAPUR", value: "SG" },
    { text: "SLOVAK CUMHURİYETİ", value: "SK" },
    { text: "SLOVENYA", value: "SI" },
    { text: "SOLOMON ADALARI", value: "SB" },
    { text: "SOMALİ", value: "SO" },
    { text: "SRİ LANKA", value: "LK" },
    { text: "ST. HELENA", value: "SH" },
    { text: "ST. KITTS VE  NEVİS", value: "KN" },
    { text: "ST. PIERRE & MIQUELON", value: "PM" },
    { text: "ST.LUCİA", value: "LC" },
    { text: "SUDAN", value: "SD" },
    { text: "SURİNAM", value: "SR" },
    { text: "SURİYE ARAP CUMHURİYETİ", value: "SY" },
    { text: "SUUDİ ARABİSTAN", value: "SA" },
    { text: "SVAZİLAND", value: "SZ" },
    { text: "ŞİLİ", value: "CL" },
    { text: "TACİKİSTAN", value: "TJ" },
    { text: "TANZANYA", value: "TZ" },
    { text: "TAYLAND", value: "TH" },
    { text: "TAYVAN", value: "TW" },
    { text: "TOGO", value: "TG" },
    { text: "TOKELAU", value: "TK" },
    { text: "TONGA", value: "TO" },
    { text: "TRİNİDAD VE TOBAGO", value: "TT" },
    { text: "TUNUS", value: "TN" },
    { text: "TURKS VE CAİCAOS ADALARI", value: "TC" },
    { text: "TUVALU", value: "TV" },
    { text: "TÜRKMENİSTAN", value: "TM" },
    { text: "UGANDA", value: "UG" },
    { text: "UKRAYNA", value: "UA" },
    { text: "UMMAN", value: "OM" },
    { text: "URUGUAY", value: "UY" },
    { text: "ÜRDÜN", value: "JO" },
    { text: "VANUATU", value: "VU" },
    { text: "VENEZUELLA", value: "VE" },
    { text: "VİETNAM SOSYALİST", value: "VN" },
    { text: "WALLİS VE FUTUNA", value: "WF" },
    { text: "YEMEN", value: "YE" },
    { text: "YENİ KALEDONYA", value: "NC" },
    { text: "YENİ ZELANDA", value: "NZ" },
    { text: "YUNANİSTAN", value: "GR" },
    { text: "ZAMBİYA", value: "ZM" },
    { text: "ZİMBABVE", value: "ZW" },
].sort((a, b) => a.text.localeCompare(b.text))

// export const getCountriesTranslated = () => [
//     { text: i18n.t("TÜRKİYE"), value: "TR" },
//     { text: i18n.t("AMERİKA BİRLEŞİK DEVLETLERİ"), value: "US" },
//     { text: i18n.t("AFGANİSTAN"), value: "AF" },
//     { text: i18n.t("ALMANYA"), value: "DE" },
//     { text: i18n.t("AMERİKA KÜÇÜK OUT. ADALARI"), value: "UM" },
//     { text: i18n.t("AMERİKAN SAMOA"), value: "AS" },
//     { text: i18n.t("ANDORRA"), value: "AD" },
//     { text: i18n.t("ANGOLA"), value: "AO" },
//     { text: i18n.t("ANGUİLLA"), value: "AI" },
//     { text: i18n.t("ANTARTİKA"), value: "AQ" },
//     { text: i18n.t("ANTİGUA-BARBUDA"), value: "AG" },
//     { text: i18n.t("ARJANTİN"), value: "AR" },
//     { text: i18n.t("ARNAVUTLUK"), value: "AL" },
//     { text: i18n.t("ARUBA"), value: "AW" },
//     { text: i18n.t("AVUSTRALYA"), value: "AU" },
//     { text: i18n.t("AVUSTURYA"), value: "AT" },
//     { text: i18n.t("AZERBAYCAN"), value: "AZ" },    
//     { text: i18n.t("BAHAMALAR"), value: "BS" },
//     { text: i18n.t("BAHREYN"), value: "BH" },
//     { text: i18n.t("BANGLADEŞ"), value: "BD" },
//     { text: i18n.t("BARBADOS"), value: "BB" },
//     { text: i18n.t("BATI TİMOR"), value: "TL" },
//     { text: i18n.t("BELÇİKA"), value: "BE" },
//     { text: i18n.t("BELİZE"), value: "BZ" },
//     { text: i18n.t("BENİN"), value: "BJ" },
//     { text: i18n.t("BERMUDA"), value: "BM" },
//     { text: i18n.t("BEYAZ RUSYA"), value: "BY" },
//     { text: i18n.t("BHUTAN"), value: "BT" },
//     { text: i18n.t("BİRLEŞİK ARAP EMİRLİKLERİ"), value: "AE" },
//     { text: i18n.t("BİRLEŞİK KRALLIK"), value: "GB" },
//     { text: i18n.t("BOLİVYA"), value: "BO" },														  
//     { text: i18n.t("BOSNA HERSEK"), value: "BA" },
//     { text: i18n.t("BOTSVANA"), value: "BW" },
//     { text: i18n.t("BOUVET ADASI"), value: "BV" },
//     { text: i18n.t("BREZİLYA"), value: "BR" },
//     { text: i18n.t("BRUNEİ"), value: "BN" },
//     { text: i18n.t("BULGARİSTAN"), value: "BG" },
//     { text: i18n.t("BURKİNA FASO"), value: "BF" },
//     { text: i18n.t("BURMA (BİRMANYA)"), value: "MM" },
//     { text: i18n.t("BURUNDİ"), value: "BI" },
//     { text: i18n.t("CAPE VERDE"), value: "CV" },
//     { text: i18n.t("CAYMAN ADALARI"), value: "KY" },
//     { text: i18n.t("CEBELİTARIK"), value: "GI" },
//     { text: i18n.t("CEUTA"), value: "XC" },
//     { text: i18n.t("CEZAYİR"), value: "DZ" },
//     { text: i18n.t("CİBUTİ"), value: "DJ" },
//     { text: i18n.t("COCOS ADALARI"), value: "CC" },
//     { text: i18n.t("COOK ADASI"), value: "CK" },
//     { text: i18n.t("ÇAD"), value: "TD" },
//     { text: i18n.t("ÇEK CUMHURİYETİ"), value: "CZ" },
//     { text: i18n.t("ÇİN HALK CUMHUR."), value: "CN" },
//     { text: i18n.t("DANİMARKA"), value: "DK" },
//     { text: i18n.t("DOMİNİK CUMHUR."), value: "DO" },
//     { text: i18n.t("DOMİNİKA"), value: "DM" },
//     { text: i18n.t("EKVATOR"), value: "EC" },
//     { text: i18n.t("EKVATOR GİNESİ"), value: "GQ" },
//     { text: i18n.t("EL SALVADOR"), value: "SV" },
//     { text: i18n.t("ENDONEZYA"), value: "ID" },
//     { text: i18n.t("ERİTRE"), value: "ER" },
//     { text: i18n.t("ERMENİSTAN"), value: "AM" },
//     { text: i18n.t("ESTONYA"), value: "EE" },
//     { text: i18n.t("ETİYOPYA"), value: "ET" },
//     { text: i18n.t("FALKLAND ADALARI"), value: "FK" },
//     { text: i18n.t("FAROE ADALARI"), value: "FO" },
//     { text: i18n.t("FAS"), value: "MA" },
//     { text: i18n.t("FİJİ"), value: "FJ" },
//     { text: i18n.t("FİLDİŞİ SAHİLİ"), value: "CI" },
//     { text: i18n.t("FİLİPİNLER"), value: "PH" },
//     { text: i18n.t("FİNLANDİYA"), value: "FI" },
//     { text: i18n.t("FRANSA"), value: "FR" },
//     { text: i18n.t("FRANSA GÜNEY BÖLGELERİ"), value: "TF" },
//     { text: i18n.t("FRANSIZ POLİNEZYASI"), value: "PF" },
//     { text: i18n.t("GABON"), value: "GA" },
//     { text: i18n.t("GAMBİYA"), value: "GM" },
//     { text: i18n.t("GANA"), value: "GH" },
//     { text: i18n.t("GİNE"), value: "GN" },
//     { text: i18n.t("GİNE-BİSSAU"), value: "GW" },
//     { text: i18n.t("GRENADA"), value: "GD" },
//     { text: i18n.t("GRÖNLAND"), value: "GL" },
//     { text: i18n.t("GUAM"), value: "GU" },
//     { text: i18n.t("GUATEMALA"), value: "GT" },
//     { text: i18n.t("GUYANA"), value: "GY" },
//     { text: i18n.t("GÜNEY AFRİKA CUM."), value: "ZA" },
//     { text: i18n.t("GÜNEY GEORGİA VE GÜNEY SANDVİÇ ADALARI"), value: "GS" },
//     { text: i18n.t("GÜNEY KORE CUM."), value: "KR" },
//     { text: i18n.t("GÜRCİSTAN"), value: "GE" },
//     { text: i18n.t("HAİTİ"), value: "HT" },
//     { text: i18n.t("HEARD ADALAI VE MC DONALD ADASI"), value: "HM" },
//     { text: i18n.t("HIRVATİSTAN"), value: "HR" },
//     { text: i18n.t("HİNDİSTAN"), value: "IN" },
//     { text: i18n.t("HOLLANDA"), value: "NL" },
//     { text: i18n.t("HOLLANDA ANTİLLERİ"), value: "AN" },
//     { text: i18n.t("HONDURAS"), value: "HN" },
//     { text: i18n.t("HONG-KONG"), value: "HK" },
//     { text: i18n.t("IRAK"), value: "IQ" },
//     { text: i18n.t("İNGİLİZ HİNT OKYANUSU TOPRAKLARI"), value: "IO" },
//     { text: i18n.t("BRİTANYA VİRJİN ADALARI"), value: "VG" },
//     { text: i18n.t("İRAN"), value: "IR" },
//     { text: i18n.t("İRLANDA"), value: "IE" },
//     { text: i18n.t("İSPANYA"), value: "ES" },
//     { text: i18n.t("İSRAİL"), value: "IL" },
//     { text: i18n.t("İSVEÇ"), value: "SE" },
//     { text: i18n.t("İSVİÇRE"), value: "CH" },
//     { text: i18n.t("FİLİSTİN"), value: "PS" },
//     { text: i18n.t("İTALYA"), value: "IT" },
//     { text: i18n.t("İZLANDA"), value: "IS" },
//     { text: i18n.t("JAMAİKA"), value: "JM" },
//     { text: i18n.t("JAPONYA"), value: "JP" },
//     { text: i18n.t("KAMBOÇYA"), value: "KH" },
//     { text: i18n.t("KAMERUN"), value: "CM" },
//     { text: i18n.t("KANADA"), value: "CA" },
//     { text: i18n.t("KARADAĞ"), value: "ME" },
//     { text: i18n.t("KATAR"), value: "QA" },
//     { text: i18n.t("KAZAKİSTAN"), value: "KZ" },
//     { text: i18n.t("KENYA"), value: "KE" },										   
//     { text: i18n.t("KIRGIZİSTAN"), value: "KG" },
//     { text: i18n.t("KİRİBATİ"), value: "KI" },
//     { text: i18n.t("KKTC"), value: "CYN" },
//     { text: i18n.t("KOLOMBİYA"), value: "CO" },
//     { text: i18n.t("KOMORLAR"), value: "KM" },
//     { text: i18n.t("KONGO"), value: "CG" },
//     { text: i18n.t("KONGO DEM. CUM"), value: "CD" },
//     { text: i18n.t("KOSOVA"), value: "XK" },
//     { text: i18n.t("KOSTA RİKA"), value: "CR" },
//     { text: i18n.t("KRİSMIS ADALARI"), value: "CX" },
//     { text: i18n.t("KUVEYT"), value: "KW" },
//     { text: i18n.t("KUZEY KORE"), value: "KP" },
//     { text: i18n.t("KUZEY MARİANA ADALARI"), value: "MP" },
//     { text: i18n.t("KÜBA"), value: "CU" },
//     { text: i18n.t("LAOS DEMOK.HALK C"), value: "LA" },
//     { text: i18n.t("LESOTO"), value: "LS" },
//     { text: i18n.t("LETONYA"), value: "LV" },
//     { text: i18n.t("LIECHTENSTEIN"), value: "LI" },
//     { text: i18n.t("LİBERYA"), value: "LR" },
//     { text: i18n.t("LİBYA"), value: "LY" },
//     { text: i18n.t("LİTVANYA"), value: "LT" },
//     { text: i18n.t("LÜBNAN"), value: "LB" },
//     { text: i18n.t("LÜKSEMBURG"), value: "LU" },
//     { text: i18n.t("MACARİSTAN"), value: "HU" },
//     { text: i18n.t("MADAGASKAR"), value: "MG" },
//     { text: i18n.t("MAKAO"), value: "MO" },
//     { text: i18n.t("MAKEDONYA"), value: "MK" },
//     { text: i18n.t("MALAVİ"), value: "MW" },
//     { text: i18n.t("MALDİVLER"), value: "MV" },
//     { text: i18n.t("MALEZYA"), value: "MY" },
//     { text: i18n.t("MALİ"), value: "ML" },
//     { text: i18n.t("MALTA"), value: "MT" },
//     { text: i18n.t("MARSHAL ADALARI"), value: "MH" },
//     { text: i18n.t("MAURİTİUS"), value: "MU" },
//     { text: i18n.t("MAYOTTE"), value: "YT" },
//     { text: i18n.t("MEKSİKA"), value: "MX" },
//     { text: i18n.t("MELİLLA"), value: "XL" },
//     { text: i18n.t("MISIR"), value: "EG" },
//     { text: i18n.t("MİKRONEZYA"), value: "FM" },
//     { text: i18n.t("MOĞOLİSTAN"), value: "MN" },
//     { text: i18n.t("MOLDOVYA"), value: "MD" },
//     { text: i18n.t("MONTSERRAT"), value: "MS" },
//     { text: i18n.t("MORİTANYA"), value: "MR" },
//     { text: i18n.t("MOZAMBİK"), value: "MZ" },
//     { text: i18n.t("NAMİBYA"), value: "NA" },
//     { text: i18n.t("NAURU"), value: "NR" },
//     { text: i18n.t("NEPAL"), value: "NP" },
//     { text: i18n.t("NIUE ADASI"), value: "NU" },
//     { text: i18n.t("NİJER"), value: "NE" },
//     { text: i18n.t("NİJERYA"), value: "NG" },
//     { text: i18n.t("NİKARAGUA"), value: "NI" },
//     { text: i18n.t("NORFOLK ADASI"), value: "NF" },
//     { text: i18n.t("NORVEÇ"), value: "NO" },
//     { text: i18n.t("ORTA AFRİKA CUM"), value: "CF" },
//     { text: i18n.t("ÖZBEKİSTAN"), value: "UZ" },
//     { text: i18n.t("PAKİSTAN"), value: "PK" },
//     { text: i18n.t("PALAU"), value: "PW" },
//     { text: i18n.t("PANAMA"), value: "PA" },
//     { text: i18n.t("PAPUA (YENİ GİNE)"), value: "PG" },
//     { text: i18n.t("PARAGUAY"), value: "PY" },
//     { text: i18n.t("PERU"), value: "PE" },
//     { text: i18n.t("PİTCAİRN"), value: "PN" },
//     { text: i18n.t("POLONYA"), value: "PL" },
//     { text: i18n.t("PORTEKİZ"), value: "PT" },
//     { text: i18n.t("ROMANYA"), value: "RO" },
//     { text: i18n.t("RUANDA"), value: "RW" },
//     { text: i18n.t("RUSYA FEDERASYONU"), value: "RU" },
//     { text: i18n.t("SAMOA"), value: "WS" },
//     { text: i18n.t("SAN MARİNO"), value: "SM" },
//     { text: i18n.t("SAO TOME VE PRIN."), value: "ST" },
//     { text: i18n.t("SENEGAL"), value: "SN" },
//     { text: i18n.t("SEYŞELLER"), value: "SC" },
//     { text: i18n.t("SIRBİSTAN"), value: "XS" },
//     { text: i18n.t("SİERRA LEONE"), value: "SL" },
//     { text: i18n.t("SİNGAPUR"), value: "SG" },
//     { text: i18n.t("SLOVAK CUMHURİYETİ"), value: "SK" },
//     { text: i18n.t("SLOVENYA"), value: "SI" },
//     { text: i18n.t("SOLOMON ADALARI"), value: "SB" },
//     { text: i18n.t("SOMALİ"), value: "SO" },
//     { text: i18n.t("SRİ LANKA"), value: "LK" },
//     { text: i18n.t("ST. HELENA"), value: "SH" },
//     { text: i18n.t("ST. KITTS VE  NEVİS"), value: "KN" },
//     { text: i18n.t("ST. PIERRE & MIQUELON"), value: "PM" },
//     { text: i18n.t("ST.LUCİA"), value: "LC" },
//     { text: i18n.t("SUDAN"), value: "SD" },
//     { text: i18n.t("SURİNAM"), value: "SR" },
//     { text: i18n.t("SURİYE ARAP CUMHURİYETİ"), value: "SY" },
//     { text: i18n.t("SUUDİ ARABİSTAN"), value: "SA" },
//     { text: i18n.t("SVAZİLAND"), value: "SZ" },
//     { text: i18n.t("ŞİLİ"), value: "CL" },
//     { text: i18n.t("TACİKİSTAN"), value: "TJ" },
//     { text: i18n.t("TANZANYA"), value: "TZ" },
//     { text: i18n.t("TAYLAND"), value: "TH" },
//     { text: i18n.t("TAYVAN"), value: "TW" },
//     { text: i18n.t("TOGO"), value: "TG" },
//     { text: i18n.t("TOKELAU"), value: "TK" },
//     { text: i18n.t("TONGA"), value: "TO" },
//     { text: i18n.t("TRİNİDAD VE TOBAGO"), value: "TT" },
//     { text: i18n.t("TUNUS"), value: "TN" },
//     { text: i18n.t("TURKS VE CAİCAOS ADALARI"), value: "TC" },
//     { text: i18n.t("TUVALU"), value: "TV" },
//     { text: i18n.t("TÜRKMENİSTAN"), value: "TM" },
//     { text: i18n.t("UGANDA"), value: "UG" },
//     { text: i18n.t("UKRAYNA"), value: "UA" },
//     { text: i18n.t("UMMAN"), value: "OM" },
//     { text: i18n.t("URUGUAY"), value: "UY" },
//     { text: i18n.t("ÜRDÜN"), value: "JO" },
//     { text: i18n.t("VANUATU"), value: "VU" },
//     { text: i18n.t("VENEZUELLA"), value: "VE" },
//     { text: i18n.t("VİETNAM SOSYALİST"), value: "VN" },
//     { text: i18n.t("WALLİS VE FUTUNA"), value: "WF" },
//     { text: i18n.t("YEMEN"), value: "YE" },
//     { text: i18n.t("YENİ KALEDONYA"), value: "NC" },
//     { text: i18n.t("YENİ ZELANDA"), value: "NZ" },
//     { text: i18n.t("YUNANİSTAN"), value: "GR" },
//     { text: i18n.t("ZAMBİYA"), value: "ZM" },
//     { text: i18n.t("ZİMBABVE"), value: "ZW" },
// ].sort((a, b) => a.text.localeCompare(b.text))

export const countriesByCode = {
    "tr": "TÜRKİYE",
    "us": "AMERİKA BİRLEŞİK DEVLETLERİ",
    "af": "AFGANİSTAN",
    "de": "ALMANYA",
    "um": "AMERİKA KÜÇÜK OUT. ADALARI",
    "as": "AMERİKAN SAMOA",
    "ad": "ANDORRA",
    "ao": "ANGOLA",
    "ai": "ANGUİLLA",
    "aq": "ANTARTİKA",
    "ag": "ANTİGUA-BARBUDA",
    "ar": "ARJANTİN",
    "al": "ARNAVUTLUK",
    "aw": "ARUBA",
    "au": "AVUSTRALYA",
    "at": "AVUSTURYA",
    "az": "AZERBAYCAN",
    "bs": "BAHAMALAR",
    "bh": "BAHREYN",
    "bd": "BANGLADEŞ",
    "bb": "BARBADOS",
    "tl": "BATI TİMOR",
    "be": "BELÇİKA",
    "bz": "BELİZE",
    "bj": "BENİN",
    "bm": "BERMUDA",
    "by": "BEYAZ RUSYA",
    "bt": "BHUTAN",
    "ae": "BİRLEŞİK ARAP EMİRLİKLERİ",
    "gb": "BİRLEŞİK KRALLIK",
    "bo": "BOLİVYA",
    "ba": "BOSNA HERSEK",
    "bw": "BOTSVANA",
    "bv": "BOUVET ADASI",
    "br": "BREZİLYA",
    "bn": "BRUNEİ",
    "bg": "BULGARİSTAN",
    "bf": "BURKİNA FASO",
    "mm": "BURMA (BİRMANYA)",
    "bi": "BURUNDİ",
    "cv": "CAPE VERDE",
    "ky": "CAYMAN ADALARI",
    "gi": "CEBELİTARIK",
    "xc": "CEUTA",
    "dz": "CEZAYİR",
    "dj": "CİBUTİ",
    "cc": "COCOS ADALARI",
    "ck": "COOK ADASI",
    "td": "ÇAD",
    "cz": "ÇEK CUMHURİYETİ",
    "cn": "ÇİN HALK CUMHUR.",
    "dk": "DANİMARKA",
    "do": "DOMİNİK CUMHUR.",
    "dm": "DOMİNİKA",
    "ec": "EKVATOR",
    "gq": "EKVATOR GİNESİ",
    "sv": "EL SALVADOR",
    "id": "ENDONEZYA",
    "er": "ERİTRE",
    "am": "ERMENİSTAN",
    "ee": "ESTONYA",
    "et": "ETİYOPYA",
    "fk": "FALKLAND ADALARI",
    "fo": "FAROE ADALARI",
    "ma": "FAS",
    "fj": "FİJİ",
    "ci": "FİLDİŞİ SAHİLİ",
    "ph": "FİLİPİNLER",
    "fi": "FİNLANDİYA",
    "fr": "FRANSA",
    "tf": "FRANSA GÜNEY BÖLGELERİ",
    "pf": "FRANSIZ POLİNEZYASI",
    "ga": "GABON",
    "gm": "GAMBİYA",
    "gh": "GANA",
    "gn": "GİNE",
    "gw": "GİNE-BİSSAU",
    "gd": "GRENADA",
    "gl": "GRÖNLAND",
    "gu": "GUAM",
    "gt": "GUATEMALA",
    "gy": "GUYANA",
    "za": "GÜNEY AFRİKA CUM.",
    "gs": "GÜNEY GEORGİA VE GÜNEY SANDVİÇ ADALARI",
    "kr": "GÜNEY KORE CUM.",
    "ge": "GÜRCİSTAN",
    "ht": "HAİTİ",
    "hm": "HEARD ADALAI VE MC DONALD ADASI",
    "hr": "HIRVATİSTAN",
    "in": "HİNDİSTAN",
    "nl": "HOLLANDA",
    "an": "HOLLANDA ANTİLLERİ",
    "hn": "HONDURAS",
    "hk": "HONG-KONG",
    "iq": "IRAK",
    "io": "İNGİLİZ HİNT OKYANUSU TOPRAKLARI",
    "vg": "BRİTANYA VİRJİN ADALARI",
    "ir": "İRAN",
    "ie": "İRLANDA",
    "es": "İSPANYA",
    "il": "İSRAİL",
    "se": "İSVEÇ",
    "ch": "İSVİÇRE",
    "ps": "FİLİSTİN",
    "it": "İTALYA",
    "is": "İZLANDA",
    "jm": "JAMAİKA",
    "jp": "JAPONYA",
    "kh": "KAMBOÇYA",
    "cm": "KAMERUN",
    "ca": "KANADA",
    "me": "KARADAĞ",
    "qa": "KATAR",
    "kz": "KAZAKİSTAN",
    "ke": "KENYA",
    "kg": "KIRGIZİSTAN",
    "ki": "KİRİBATİ",
    "cyn": "KKTC",
    "co": "KOLOMBİYA",
    "km": "KOMORLAR",
    "cg": "KONGO",
    "cd": "KONGO DEM. CUM",
    "xk": "KOSOVA",
    "cr": "KOSTA RİKA",
    "cx": "KRİSMIS ADALARI",
    "kw": "KUVEYT",
    "kp": "KUZEY KORE",
    "mp": "KUZEY MARİANA ADALARI",
    "cu": "KÜBA",
    "la": "LAOS DEMOK.HALK C",
    "ls": "LESOTO",
    "lv": "LETONYA",
    "li": "LIECHTENSTEIN",
    "lr": "LİBERYA",
    "ly": "LİBYA",
    "lt": "LİTVANYA",
    "lb": "LÜBNAN",
    "lu": "LÜKSEMBURG",
    "hu": "MACARİSTAN",
    "mg": "MADAGASKAR",
    "mo": "MAKAO",
    "mk": "MAKEDONYA",
    "mw": "MALAVİ",
    "mv": "MALDİVLER",
    "my": "MALEZYA",
    "ml": "MALİ",
    "mt": "MALTA",
    "mh": "MARSHAL ADALARI",
    "mu": "MAURİTİUS",
    "yt": "MAYOTTE",
    "mx": "MEKSİKA",
    "xl": "MELİLLA",
    "eg": "MISIR",
    "fm": "MİKRONEZYA",
    "mn": "MOĞOLİSTAN",
    "md": "MOLDOVYA",
    "ms": "MONTSERRAT",
    "mr": "MORİTANYA",
    "mz": "MOZAMBİK",
    "na": "NAMİBYA",
    "nr": "NAURU",
    "np": "NEPAL",
    "nu": "NIUE ADASI",
    "ne": "NİJER",
    "ng": "NİJERYA",
    "ni": "NİKARAGUA",
    "nf": "NORFOLK ADASI",
    "no": "NORVEÇ",
    "cf": "ORTA AFRİKA CUM",
    "uz": "ÖZBEKİSTAN",
    "pk": "PAKİSTAN",
    "pw": "PALAU",
    "pa": "PANAMA",
    "pg": "PAPUA (YENİ GİNE)",
    "py": "PARAGUAY",
    "pe": "PERU",
    "pn": "PİTCAİRN",
    "pl": "POLONYA",
    "pt": "PORTEKİZ",
    "ro": "ROMANYA",
    "rw": "RUANDA",
    "ru": "RUSYA FEDERASYONU",
    "ws": "SAMOA",
    "sm": "SAN MARİNO",
    "st": "SAO TOME VE PRIN.",
    "sn": "SENEGAL",
    "sc": "SEYŞELLER",
    "xs": "SIRBİSTAN",
    "sl": "SİERRA LEONE",
    "sg": "SİNGAPUR",
    "sk": "SLOVAK CUMHURİYETİ",
    "si": "SLOVENYA",
    "sb": "SOLOMON ADALARI",
    "so": "SOMALİ",
    "lk": "SRİ LANKA",
    "sh": "ST. HELENA",
    "kn": "ST. KITTS VE  NEVİS",
    "pm": "ST. PIERRE & MIQUELON",
    "lc": "ST.LUCİA",
    "sd": "SUDAN",
    "sr": "SURİNAM",
    "sy": "SURİYE ARAP CUMHURİYETİ",
    "sa": "SUUDİ ARABİSTAN",
    "sz": "SVAZİLAND",
    "cl": "ŞİLİ",
    "tj": "TACİKİSTAN",
    "tz": "TANZANYA",
    "th": "TAYLAND",
    "tw": "TAYVAN",
    "tg": "TOGO",
    "tk": "TOKELAU",
    "to": "TONGA",
    "tt": "TRİNİDAD VE TOBAGO",
    "tn": "TUNUS",
    "tc": "TURKS VE CAİCAOS ADALARI",
    "tv": "TUVALU",
    "tm": "TÜRKMENİSTAN",
    "ug": "UGANDA",
    "ua": "UKRAYNA",
    "om": "UMMAN",
    "uy": "URUGUAY",
    "jo": "ÜRDÜN",
    "vu": "VANUATU",
    "ve": "VENEZUELLA",
    "vn": "VİETNAM SOSYALİST",
    "wf": "WALLİS VE FUTUNA",
    "ye": "YEMEN",
    "nc": "YENİ KALEDONYA",
    "nz": "YENİ ZELANDA",
    "gr": "YUNANİSTAN",
    "zm": "ZAMBİYA",
    "zw": "ZİMBABVE"
}

export const regionsTR = [
    "Adana",
    "Adıyaman",
    "Afyonkarahisar",
    "Ağrı",
    "Aksaray",
    "Amasya",
    "Ankara",
    "Antalya",
    "Ardahan",
    "Artvin",
    "Aydın",
    "Balıkesir",
    "Bartın",
    "Batman",
    "Bayburt",
    "Bilecik",
    "Bingöl",
    "Bitlis",
    "Bolu",
    "Burdur",
    "Bursa",
    "Çanakkale",
    "Çankırı",
    "Çorum",
    "Denizli",
    "Diyarbakır",
    "Düzce",
    "Edirne",
    "Elazığ",
    "Erzincan",
    "Erzurum",
    "Eskişehir",
    "Gaziantep",
    "Giresun",
    "Gümüşhane",
    "Hakkâri",
    "Hatay",
    "Iğdır",
    "Isparta",
    "İstanbul",
    "İzmir",
    "Kahramanmaraş",
    "Karabük",
    "Karaman",
    "Kars",
    "Kastamonu",
    "Kayseri",
    "Kilis",
    "Kırıkkale",
    "Kırklareli",
    "Kırşehir",
    "Kocaeli",
    "Konya",
    "Kütahya",
    "Malatya",
    "Manisa",
    "Mardin",
    "Mersin",
    "Muğla",
    "Muş",
    "Nevşehir",
    "Niğde",
    "Ordu",
    "Osmaniye",
    "Rize",
    "Sakarya",
    "Samsun",
    "Şanlıurfa",
    "Siirt",
    "Sinop",
    "Sivas",
    "Şırnak",
    "Tekirdağ",
    "Tokat",
    "Trabzon",
    "Tunceli",
    "Uşak",
    "Van",
    "Yalova",
    "Yozgat",
    "Zonguldak"
]

export const regionsTRDict = {
    '01': 'Adana',
    '02': 'Adıyaman',
    '03': 'Afyon',
    '04': 'Ağrı',
    '68': 'Aksaray',
    '05': 'Amasya',
    '06': 'Ankara',
    '07': 'Antalya',
    '75': 'Ardahan',
    '08': 'Artvin',
    '09': 'Aydın',
    '10': 'Balıkesir',
    '74': 'Bartın',
    '72': 'Batman',
    '69': 'Bayburt',
    '11': 'Bilecik',
    '12': 'Bingöl',
    '13': 'Bitlis',
    '14': 'Bolu',
    '15': 'Burdur',
    '16': 'Bursa',
    '17': 'Çanakkale',
    '18': 'Çankırı',
    '19': 'Çorum',
    '20': 'Denizli',
    '21': 'Diyarbakır',
    '81': 'Düzce',
    '22': 'Edirne',
    '23': 'Elazığ',
    '24': 'Erzincan',
    '25': 'Erzurum',
    '26': 'Eskişehir',
    '27': 'Gaziantep',
    '28': 'Giresun',
    '29': 'Gümüşhane',
    '30': 'Hakkari',
    '31': 'Hatay',
    '76': 'Iğdır',
    '32': 'Isparta',
    '33': 'İçel',
    '34': 'İstanbul',
    '35': 'İzmir',
    '46': 'Kahramanmaraş',
    '78': 'Karabük',
    '70': 'Karaman',
    '36': 'Kars',
    '37': 'Kastamonu',
    '38': 'Kayseri',
    '71': 'Kırıkkale',
    '39': 'Kırklareli',
    '40': 'Kırşehir',
    '79': 'Kilis',
    '41': 'Kocaeli',
    '42': 'Konya',
    '43': 'Kütahya',
    '44': 'Malatya',
    '45': 'Manisa',
    '47': 'Mardin',
    '48': 'Muğla',
    '49': 'Muş',
    '50': 'Nevşehir',
    '51': 'Niğde',
    '52': 'Ordu',
    '80': 'Osmaniye',
    '53': 'Rize',
    '54': 'Sakarya',
    '55': 'Samsun',
    '56': 'Siirt',
    '57': 'Sinop',
    '58': 'Sivas',
    '63': 'Şanlıurfa',
    '73': 'Şırnak',
    '59': 'Tekirdağ',
    '60': 'Tokat',
    '61': 'Trabzon',
    '62': 'Tunceli',
    '64': 'Uşak',
    '65': 'Van',
    '77': 'Yalova',
    '66': 'Yozgat',
    '67': 'Zonguldak'
}