import React from 'react';
import { Fab, Action } from 'react-tiny-fab';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, InstapaperShareButton, InstapaperIcon, EmailShareButton, EmailIcon } from "react-share";
import { useTranslation } from 'react-i18next';

const ShareButtons = props => {

    const { t, i18n } = useTranslation();

    const mainButtonStyles = {
        backgroundColor: '#FFC400',
        width: "46px",
        height: "46px",
    };

    const shareBtStyle = { borderRadius: "50%", boxShadow: "0 0 4px rgb(0 0 0 / 14%), 0 4px 8px rgb(0 0 0 / 28%)" };

    return (
        <div id="share-buttons-container">
            <Fab
                mainButtonStyles={mainButtonStyles}
                icon={<img src="/assets/images/share-24px.svg" alt="" width="32" style={{ marginLeft: -3 }} />}
                alwaysShowTitle={true}>

                <WhatsappShareButton url="https://8milyarsalavat.com/" title={t("8 Milyar Salavat")} tyle={shareBtStyle} >
                    <WhatsappIcon size="48" round />
                </WhatsappShareButton>

                {/* nedense burada çalışmıyor <EmailShareButton url="https://8milyarsalavat.com/" title={t("8 Milyar Salavat")} style={shareBtStyle} body={t("Dünyada yaşayan insanlar")}>
                    <EmailIcon size="48" round />
                </EmailShareButton> */}

                <TwitterShareButton url="https://8milyarsalavat.com/" quote={t("8 Milyar Salavat")} style={shareBtStyle}>
                    <TwitterIcon size="48" round />
                </TwitterShareButton>

                <FacebookShareButton url="https://8milyarsalavat.com/" title={t("8 Milyar Salavat")} style={shareBtStyle} quote={t("Dünyada yaşayan insanlar")}>
                    <FacebookIcon size="48" round />
                </FacebookShareButton>

            </Fab>
        </div>
    )
}

export default ShareButtons;