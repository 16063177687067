import React, { useEffect, useState } from 'react';
import './CountrySection.scss';
import { Spinner, Table } from 'reactstrap';
import { CircleFlag } from 'react-circle-flags';
import axios from 'axios';
import utils from '../../../../utils';
import _ from 'lodash';
import store from '../../../../store';
import { ListTask, ListStars, ChevronExpand } from "react-bootstrap-icons"
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';

const CountrySection = props => {

    const [showAll, setshowAll] = useState(false);
    const [expandedCountryCode, setexpandedCountryCode] = useState("");
    const { t, i18n } = useTranslation();

    function expandCountry(country) {
        if (expandedCountryCode === country)
            setexpandedCountryCode("");
        else
            setexpandedCountryCode(country);
    }

    return (
        <div id="country-section-container">
            <div id="table-container" className="mx-auto">
                <div id="mozaik">
                    <span id="title"><Trans i18nKey="Ülkeler Salavat">Ülkeler <span className="heart-s mr-3">Salavat</span> Sıralaması</Trans></span>
                    <span id="subtitle"><Trans>Tüm dünya <strong>8milyarSalavat.com</strong> sitesinde.</Trans></span>
                </div>
                <Table borderless striped>
                    <thead>
                        <tr className="text-center">
                            <th><Trans>Sıralama</Trans></th>
                            <th className="text-left" colSpan={2}><Trans>Ülke</Trans></th>
                            <th className="heart-s text-right pr-4"><Trans>Salavat</Trans></th>
                        </tr>
                    </thead>
                    <tbody>
                        {_.isArray(store.countries) && store.countries.slice(0, (showAll ? 9999 : 10)).map((item, i) => {
                            return <React.Fragment key={item.country}>
                                <tr onClick={()=> item.country === "TR" && expandCountry(item.country)} style={{ cursor: item.country === "TR" && 'pointer' }}>
                                    <td className="text-center">{i + 1}</td>
                                    <td colSpan={2}>
                                        {item.country === "TR" ? <ChevronExpand size={28} style={{ cursor: 'pointer', position:"absolute", marginLeft: -37 }} /> : null}
                                        <CircleFlag countryCode={item.country.toLowerCase()} height="28" className="mr-2" />
                                        {t(item.countryName)}
                                    </td>
                                    <td className="count text-right pr-4"> {utils.formatNumber(item.salavatCount)} </td>
                                </tr>
                                {
                                    expandedCountryCode === item.country && 
                                    <>
                                        {store.turkish_cities.map((city, i) =>
                                            <tr key={city.city}>
                                                <td></td>
                                                <td className="text-right" style={{ width: 1 }}>{i + 1}</td>
                                                <td style={{wordBreak: "break-all"}}> {city.city}</td>
                                                <td className="count text-right pr-4"> {utils.formatNumber(city.salavatCount)} </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td> </td>
                                            <td> </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                    </>
                                }
                            </React.Fragment>
                        })}
                    </tbody>
                </Table>
                {store.countries.length > 10 &&
                    <div className="text-center">
                        {!store.countries && <Spinner />}
                        {!showAll && <button id="btn-show-all" onClick={() => {
                            setshowAll(true);
                        }}>{t("Tüm Sıralamayı Göster")}</button>}
                    </div>}
            </div>
        </div>
    )
}
export default observer(CountrySection);