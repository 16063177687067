import React, { useEffect } from 'react';
import './Home.scss';
import MainSection from './main-section/MainSection';
import SocialSection from './social-section/SocialSection';
import LiveSection from './live-section/LiveSection';
import MapSection from './map-section/MapSection';
import CountrySection from './country-section/CountrySection';
import { useLocation } from 'react-router';

const Home = props => {
    return (
        <>
            <MainSection {...props} />
            <SocialSection {...props} />
            <LiveSection {...props} />
            <MapSection {...props} />
            <CountrySection {...props} />
        </>
    )
}

export default Home;