import axios from "axios";
import { makeAutoObservable, makeObservable, observable } from "mobx"
import utils from "./utils";
import _, { parseInt } from "lodash";
import { differenceInMinutes } from "date-fns";
import i18n from "i18next";
import { countriesByCode, getCountries } from "./consts";

class store {
    constructor() {
        makeObservable(this, {
            token: observable,
            user: observable,
            geoCountryCode: observable,
            geoRegion: observable,
            geoRegionOriginal: observable,
            geoCountryName: observable,
            statistics: observable,
            statisticsPrev: observable,
            showMoreModal: observable,
            showYeniden: observable,
            showSukran: observable,
            message: observable,
            error: observable,
            countries: observable,
            turkish_cities: observable,
            liveStats: observable,
            loginEmail: observable,
            totalCurrent: observable,
            totalOld: observable,
            jwtValidationInProgress: observable,
            // afterLoginGoToProfile: observable,
        });
    }

    token = ""

    geoCountryCode = ""
    geoCountryName = ""
    geoRegion = ""
    geoRegionOriginal = ""

    user = {}
    statistics = {}
    statisticsPrev = {}
    isFirstRefreshStats = true;
    showAddingConfirmationCount = null;

    ended = false;
    endMessageShowed = false;

    countries = [];
    liveStats = [];
    TRPrevCount = 0;
    turkish_cities = [];
    countriesPrevLength = 0;

    showMoreModal = false;
    showYeniden = false;
    showSukran = false;

    message = null;
    messageOnClosed = null;

    error = null;
    errorOnClosed = null;

    afterLoginGoToProfile = false;

    userDropdown = null;
    userDropdown2 = null;
    emailConfirmed = null;
    loginEmail = null;
    _recaptchaToken = null;
    _recaptchaTokenExpireTime = null;

    totalCurrent = !localStorage.getItem("totalSalavats") || isNaN(localStorage.getItem("totalSalavats")) ? 0 : parseInt(localStorage.getItem("totalSalavats"));
    totalOld = 0;

    jwtValidationInProgress = true;

    openUserLogin(afterLoginGoToProfile = false) {
        if (this.userDropdown && this.userDropdown.current) {
            this.afterLoginGoToProfile = afterLoginGoToProfile;
            this.userDropdown.current.toggle();
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        }
    }

    saveUserToLocalStorage() {
        if (this.user == null) {
            localStorage.removeItem("user");
        }
        else {
            localStorage.setItem("user", JSON.stringify(this.user));
        }

        if (this.token == null) {
            localStorage.removeItem("token");
        }
        else {
            localStorage.setItem("token", this.token);
        }
    }

    saveAnonymLocationToLocalStorage() {
        localStorage.setItem("geoCountryCode", this.geoCountryCode);
        localStorage.setItem("geoRegion", this.geoRegion);
    }

    logOut() {
        this.token = null;
        this.user = null;
        this.saveUserToLocalStorage();
    }

    refreshUserStats() {
        if (this.token) {
            if (!this.isFirstRefreshStats)
                this.statisticsPrev = this.statistics;

            axios.get(`${utils.ApiAddress}/api/profile`, { headers: { "Authorization": "Bearer " + this.token } })
                .then(response => {
                    const newStats = response.data.statistics.personal;
                    newStats.total = response.data.statistics.personal.total_salawats;
                    newStats.avgDaily = (newStats.this_months_salawats || 0) / 30;
                    newStats.avgWeekly = (newStats.this_months_salawats || 0) / 4;
                    newStats.avgMonthly = (newStats.total_salawats || 0) / 12;

                    this.statistics = newStats;
                    this.isFirstRefreshStats = false;
                })
                .catch(error => {
                    console.log(error);
                });

            // axios.get(`https://8milyarsalavat.com/api/profile`, { headers: { "X-Salavat-Auth-Token": this.token } })
            //     .then(response => {
            //         const newStats = response.data.statistics;
            //         newStats.total = response.data.user.totalSalavats;
            //         newStats.avgDaily = (newStats.monthly || 0) / 30;
            //         newStats.avgWeekly = (newStats.monthly || 0) / 4;
            //         newStats.avgMonthly = (newStats.total || 0) / 12;

            //         this.statistics = newStats;
            //         this.isFirstRefreshStats = false;
            //     })
            //     .catch(error => {
            //         console.log(error);
            //         alert(utils.getAxiosErrorMessage(error));
            //     });
        }
    }

    refreshStats() {
        axios.get(`${utils.ApiAddress}/api/statistics`)
            .then(response => {
                if (this.countries) {
                    this.countriesPrevLength = this.countries.length;
                    this.TRPrevCount = (this.countries.find(c => c.country === "TR") || {}).salavatCount || 0;
                }

                //const countryMapping = getCountries().reduce((a, x) => ({ ...a, [x.value]: x.text }), {});

                this.countries = Object.keys(response.data.countries).map(k => {
                    var item = response.data.countries[k];
                    const countryCode = k.toUpperCase() === "CYN" || k.toUpperCase() === "CY" ? "NORTHERN_CYPRUS" : k.toUpperCase();
                    return {
                        "country": countryCode,
                        //"country": k.toUpperCase(),
                        "countryName": countriesByCode[countryCode.toLowerCase()] || item.country.toLocaleUpperCase("tr"),
                        "userCount": item.user,
                        "salavatCount": parseInt(item.total)
                    }
                });

                this.turkish_cities = Object.keys(response.data.turkish_cities).map(key => {
                    return {
                        "country": "TR",
                        "city": key === "" ? "(Diğerler)" : key,
                        "userCount": response.data.turkish_cities[key].user,
                        "salavatCount": parseInt(response.data.turkish_cities[key].total)
                    }
                }).sort((a, b) => b.salavatCount - a.salavatCount);


                this.liveStats = Object.keys(response.data.last_salawats).map(k => {
                    var item = response.data.last_salawats[k];
                    return {
                        "name": item.name,
                        "countryCode": item.country,
                        //"countryName": countryMapping[item.country.toUpperCase()] || item.country,
                        //"userCount": item.user,
                        "count": item.amount
                    }
                });

                const total_salawats = Math.max(0, _.toNumber(response.data.total_salawats));
                this.setNewTotalCurrent(total_salawats);

                // this.totalOld = this.totalCurrent;
                // const total_salawats = Math.max(0, _.toNumber(response.data.total_salawats));
                // if (total_salawats > this.totalCurrent) {
                //     this.totalCurrent = total_salawats;
                // }
            })
            .catch(error => {
                console.log(error);
            });

        // axios.get(`https://8milyarsalavat.azurewebsites.net/api/export/CountriesStats?maxCount=9999`)
        // // axios.get(`https://8milyarsalavat.azurewebsites.net/api/export/CountriesStats`)
        //     .then(response => {
        //         // setStats(response.data);
        //         this.countries = response.data.filter(i => i.salavatCount > 0);
        //     })
        //     .catch(error => {
        //         console.log(error);
        //         //alert(utils.getAxiosErrorMessage(error));
        //     })
        //     .finally(() => setloading(false));
    }

    addUserSalavats(count) {
        count = _.toNumber(count);
        if (count > 0) {
            this.totalOld = this.totalCurrent;
            this.totalCurrent += count;
        }
    }

    setNewTotalCurrent(count) {
        count = _.toNumber(count);
        if (count > 0) {
            this.totalOld = this.totalCurrent;
            this.totalCurrent = count;
            localStorage.setItem("totalSalavats", this.totalCurrent);
        }
    }

    prevSendedCount = 0;
    prevSendTime = null;
    confirmSending(count) {
        if (count > 1
            && this.prevSendedCount > 1
            && this.prevSendTime
            && differenceInMinutes(new Date(), this.prevSendTime) < 1) {
            return window.confirm(i18n.t("Bir dakikadan salavat", { "0": utils.formatNumber(this.prevSendedCount) }));
        }
        else {
            return true;
        }
    }

    setPrevSendedCount(count) {
        this.prevSendedCount = count;
        this.prevSendTime = new Date();
    }

    // #region recaptcha
    _refreshRecaptchaFunc = null;
    startRecaptchaRefresh(func) {
        this._refreshRecaptchaFunc = func;

        window.setInterval(() => {
            try {
                this._refreshRecaptcha();
            } catch (err) { }
        }, 100 * 1000/* 100 seconds */);

        this._refreshRecaptcha();
    }

    _refreshRecaptcha = async () => {
        if (!this._refreshRecaptchaFunc) {
            console.warn("Execute recaptcha not yet available");
            return;
        }

        this._recaptchaToken = await this._refreshRecaptchaFunc('send');
        this._recaptchaTokenExpireTime = new Date(new Date().getTime() + 100000);
    }

    getRecaptchaToken = async () => {
        if(new Date()>this._recaptchaTokenExpireTime)
            await this._refreshRecaptcha();

        const currRecaptcha = this._recaptchaToken;
        this._refreshRecaptcha();
        return currRecaptcha;
    }
    // #endregion
}

const _store = new store();

if (localStorage.getItem("token")) {
    const storedJwt = localStorage.getItem("token");
    //jwt validation
    axios.get(`${utils.ApiAddress}/api/profile`, { headers: { "Authorization": "Bearer " + storedJwt } })
        .then(() => {
            _store.token = storedJwt;
        })
        .catch(err => {
            if (err.response.status === 403)
                localStorage.removeItem("token");
        })
        .finally(() => _store.jwtValidationInProgress = false);
}
else {
    _store.jwtValidationInProgress = false;
}

if (localStorage.getItem("geoCountryCode")) {
    _store.geoCountryCode = localStorage.getItem("geoCountryCode");
    _store.geoRegion = localStorage.getItem("geoRegion");
}

if (localStorage.getItem("user")) {
    try {
        _store.user = JSON.parse(localStorage.getItem("user"));
    } catch (error) { }
}

_store.refreshStats();
const interval = window.setInterval(() => _store.refreshStats(), 60000);

export default _store;