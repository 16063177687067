import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { MainContext } from '../../../context/MainContext';
import './About.scss';

const About = () => {
    const [isMenuOpen, setMenuOpen] = useContext(MainContext);

    return (
        <div id="about-container" className={`vh-100 ${!isMenuOpen && "z-index-4"}`}>
            <div className="info-card">
                <span className="title"><Trans>Biz Kimiz?</Trans></span>
                <p>
                    <Trans>Salavat-ı Şerif, Hz Muhammed</Trans>
                    {/* Salavat-ı Şerif, Hz. Muhammed (s.a.s.) 'ı övme, sevme, iltifat ile anma ve teslimiyetin özetidir. Bunun yanında, Kainatı yaşam tarzı ile ihya eden Son Peygamber'e duyulan sevginin, vefanın ve ümmet olma bilincinin gelecek nesillere aktarılmasına da vesiledir. Bizler, ideal mesaja muhatap, davete ve icabete mazhar olan dünyadaki 8 milyar insanın Efendimiz (s.a.s.) ile kesintisiz iletişim kurmasını arzulayan kişileriz. Kısaca bizim adımız 8 Milyar! */}
                </p>
            </div>
        </div>
    )
}

export default  observer(About);