import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translations_tr_geo from './translations/tr_geo.json'
import translations_en_geo from './translations/en_geo.json'
import translations_id_geo from './translations/id_geo.json'
import translations_ar_geo from './translations/ar_geo.json'
import translations_ru_geo from './translations/ru_geo.json'
import translations_de_geo from './translations/de_geo.json'
import translations_bm_geo from './translations/bm_geo.json'
import translations_tr from './translations/tr.json'
import translations_ar from './translations/ar.json'
import translations_en from './translations/en.json'
import translations_id from './translations/id.json'
import translations_ru from './translations/ru.json'
import translations_de from './translations/de.json'
import translations_bm from './translations/bm.json'
import utils from "./utils";


const detectionOrder = ['localStorage'];
var fallbackLng = "en";

if (utils.is8Billion && !utils.isDev)//türkçe domain'de ilk olarak türkçe gelecek, diğer domainlerde tarayıcı varsayılan dili gelecek
{
    global.languages = ["ar", "id", "bm", "en", "ru", "de"];
    detectionOrder.push('navigator');
}
else {
    global.languages = ["tr", "ar", "id", "bm", "en", "ru", "de"];
    fallbackLng = "tr";
}

if(utils.is8Billion) {
    Object.keys(translations_tr).forEach(key=>{
        translations_tr[key] = translations_tr[key] && translations_tr[key].replace(/8milyarSalavat.com/ig, "8billionsalawat.com")
    });
    Object.keys(translations_ar).forEach(key=>{
        translations_ar[key] = translations_ar[key] && translations_ar[key].replace(/8milyarSalavat.com/ig, "8billionsalawat.com")
    });
    Object.keys(translations_en).forEach(key=>{
        translations_en[key] = translations_en[key] && translations_en[key].replace(/8milyarSalavat.com/ig, "8billionsalawat.com")
    });
    Object.keys(translations_id).forEach(key=>{
        translations_id[key] = translations_id[key] && translations_id[key].replace(/8milyarSalavat.com/ig, "8billionsalawat.com")
    });
    Object.keys(translations_ru).forEach(key=>{
        translations_ru[key] = translations_ru[key] && translations_ru[key].replace(/8milyarSalavat.com/ig, "8billionsalawat.com")
    });
    Object.keys(translations_de).forEach(key=>{
        translations_de[key] = translations_de[key] && translations_de[key].replace(/8milyarSalavat.com/ig, "8billionsalawat.com")
    });
    Object.keys(translations_bm).forEach(key=>{
        translations_bm[key] = translations_bm[key] && translations_bm[key].replace(/8milyarSalavat.com/ig, "8billionsalawat.com")
    });
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // passes i18n down to react-i18next
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: fallbackLng,
        resources: {
            tr: {
                translation: { ...translations_tr, ...translations_tr_geo }
            },
            ar: {
                translation: { ...translations_ar, ...translations_ar_geo }
            },
            en: {
                translation: { ...translations_en, ...translations_en_geo }
            },
            id: {
                translation: { ...translations_id, ...translations_id_geo }
            },
            ru: {
                translation: { ...translations_ru, ...translations_ru_geo }
            },
            de: {
                translation: { ...translations_de, ...translations_de_geo }
            },
            bm: {
                translation: { ...translations_bm, ...translations_bm_geo }
            },
        },

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            transKeepBasicHtmlNodesFor: ["br", "b", "strong", "sup", "i", "p", "h1", "h2", "h3", "h4", "span"]
        },
        detection: {
            order: detectionOrder
        }
    });

const query = new URLSearchParams(window.location.hash.replace(/^[^?]+/, ""));
if (query.get("lang") && global.languages.includes(query.get("lang")))
    i18n.changeLanguage(query.get("lang"));

if (i18n.language.includes('-'))
    i18n.changeLanguage(i18n.language.split('-')[0])

if (!global.languages.includes(i18n.language))
    i18n.changeLanguage(fallbackLng)
