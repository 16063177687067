import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';
import store from "../../store"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import utils from '../../utils';

const Footer = props => {
    const { t, i18n } = useTranslation();

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    return (<>
        <div id="footer-container">
            <div id="section1">
                <div className="d-flex justify-content-around mb-3">
                    <Link to="/"><Trans>Ana Sayfa</Trans></Link>
                    <Link to="/biz-kimiz"><Trans>Biz Kimiz?</Trans></Link>
                    <Link to="/salavat-hakkinda"><Trans>Salavat Hakkında0</Trans></Link>
                    <Link to="/s.s.s."><Trans>S.S.S.</Trans></Link>
                    <a href="mailto:bilgi@8milyarsalavat.com"><Trans>İletişim</Trans></a>
                    {/* <Link to="/iletisim"><Trans>İletişim</Trans></Link> */}
                    {global.languages.length > 1 && <UncontrolledDropdown className="narrow">
                        <DropdownToggle tag="span">
                            {i18n.language.toUpperCase().toUpperCase()}
                        </DropdownToggle>
                        <DropdownMenu right>
                            {global.languages.filter(l => i18n.language !== l).map(l =>
                                <DropdownItem key={l} onClick={() => i18n.changeLanguage(l)} className="d-flex align-items-center mb-2">
                                    <span>{l.toUpperCase()}</span>
                                </DropdownItem>
                            )}
                            {/* {i18n.language !== "tr" && <DropdownItem onClick={() => i18n.changeLanguage("tr")} className="d-flex align-items-center mb-2">
                                <span>TR</span>
                            </DropdownItem>} 
                            {i18n.language !== "ar" && <DropdownItem onClick={() => i18n.changeLanguage("ar")} className="d-flex align-items-center">
                                <span>AR</span>
                            </DropdownItem>} 
                            {i18n.language !== "en" && <DropdownItem onClick={() => i18n.changeLanguage("en")} className="d-flex align-items-center">
                                <span>EN</span>
                            </DropdownItem>} */}
                        </DropdownMenu>
                    </UncontrolledDropdown>}
                </div>
                <div className="text-center mb-3">
                    {/* <a href="https://youtube.com/c/kuranglobal" target="youtube">
                        <img src="/assets/images/social/logo-white-youtube.svg" alt="Youtube" height={45} style={{ marginRight: 10 }} /></a> */}
                    <a href="https://www.facebook.com/8MilyarSalavat/" target="facebook">
                        <img src="/assets/images/social/logo-facebook.svg" alt="Facebook" style={{ marginRight: 20 }} /></a>
                    <a href="https://www.instagram.com/8milyarsalavat" target="inst">
                        <img src="/assets/images/social/logo-instagram.svg" alt="Instagram" /></a>
                    <a href="https://twitter.com/8milyarsalavat" target="twitter">
                        <img src="/assets/images/social/logo-twitter.svg" alt="Twitter" /></a>
                    <a href="https://t.me/kuranglobal" target="telegram">
                        <img src="/assets/images/social/logo-telegram.svg" alt="Telegram" /></a>
                    <a href="mailto:bilgi@8milyarsalavat.com"><img src="/assets/images/social/email2.svg" alt="Email" /></a>
                </div>
                <div className="text-center" id="org">
                    {/* <Trans i18nKey="footerOrg">
                        <span>8 milyar salavat</span>
                        <span>
                            <a href="https://serverhanimlar.foundation/" target="serverhanimlar">
                                <img src="/assets/images/server-hanimlar.png" alt={t("Betül Hanımlar")} id="logo" />
                            </a>
                        </span>
                        <span>organizasyonudur</span>
                    </Trans> */}
                    {/* <Trans i18nKey="footerOrg">
                        <span>8 milyar salavat</span>
                        <span>
                            <a href="https://betulhanimlar.com/" target="betulhanimlar">
                                <img src="/assets/images/betullogo_4.png" alt={t("Betül Hanımlar")} id="logo" />
                            </a>
                        </span>
                        <span>organizasyonudur</span>
                    </Trans> */}
                </div>
            </div>
        </div>

        {/* <div id="footer-container">
            <div id="section1" className="d-flex justify-content-between flex-md-row flex-column">
                <a href="https://serverhanimlar.foundation/" target="serverhanimlar">
                    <img src="/assets/images/server-hanimlar.png" alt={t("Betül Hanımlar")} id="logo" />
                </a>
                {utils.isDev
                    ? <>
                        <ul>
                            <li onClick={() => goToTop()}>
                                <Link to="/"><Trans>Ana Sayfa</Trans></Link>
                            </li>
                            <li onClick={() => goToTop()}>
                                <Link to="/salavat-hakkinda"><Trans>Salavat Hakkında0</Trans></Link>
                            </li>
                            <li onClick={() => goToTop()}>
                                <Link to="/biz-kimiz"><Trans>Biz Kimiz?</Trans></Link>
                            </li>
                            {!store.token && <li onClick={() => goToTop()}>
                                <Link to="/s.s.s."><Trans>S.S.S.</Trans></Link>
                            </li>}
                        </ul>
                        <ul>
                            {!store.token && <li>
                                <Link to="#" onClick={() => store.openUserLogin()}><Trans>ÜYE GİRİŞİ</Trans></Link>
                            </li>}
                            <li>
                                <UncontrolledDropdown className="narrow">
                                    <DropdownToggle tag="span">
                                        {i18n.language.toUpperCase().toUpperCase()}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        {i18n.language !== "tr" && <DropdownItem onClick={() => i18n.changeLanguage("tr")} className="d-flex align-items-center mb-2">
                                            <span>TR</span>
                                        </DropdownItem>}
                                        {i18n.language !== "en" && <DropdownItem onClick={() => i18n.changeLanguage("en")} className="d-flex align-items-center">
                                            <span>EN</span>
                                        </DropdownItem>}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                            <li onClick={() => goToTop()}>
                                <Link to="/iletisim"><Trans>İletişim</Trans></Link>
                            </li>
                            {store.token && <li onClick={() => goToTop()}>
                                <Link to="/s.s.s."><Trans>S.S.S.</Trans></Link>
                            </li>}
                        </ul>
                    </>
                    :
                    <>
                        <ul>
                            <li onClick={() => goToTop()}>
                                <Link to="/"><Trans>Ana Sayfa</Trans></Link>
                            </li>
                            <li onClick={() => goToTop()}>
                                <Link to="/salavat-hakkinda"><Trans>Salavat Hakkında0</Trans></Link>
                            </li>
                            <li onClick={() => goToTop()}>
                                <Link to="/biz-kimiz"><Trans>Biz Kimiz?</Trans></Link>
                            </li>
                        </ul>
                        <ul>
                            <li onClick={() => goToTop()}>
                                <Link to="/iletisim"><Trans>İletişim</Trans></Link>
                            </li>
                            {!store.token && <li>
                                <Link to="#" onClick={() => store.openUserLogin()}><Trans>ÜYE GİRİŞİ</Trans></Link>
                            </li>}
                            <li onClick={() => goToTop()}>
                                <Link to="/s.s.s."><Trans>S.S.S.</Trans></Link>
                            </li>
                        </ul>
                    </>}
                <ul>
                    <li className="text-center">
                        <Trans>BİZİ TAKİP EDİN</Trans>
                    </li>
                    <li>
                        <a href="mailto:bilgi@8milyarsalavat.com"><img src="/assets/images/social/email2.svg" alt="Email" /></a>
                        <a href="https://www.instagram.com/serverhanimlar/" target="inst"><img src="/assets/images/social/logo-instagram.svg" alt="Instagram" /></a>
                        <a href="https://youtube.com/c/serverhanimlar" target="inst"><img src="/assets/images/social/logo-white-youtube.svg" alt="Youtube" height={45} /></a>
                        <a href="https://www.facebook.com/serverhanimlarvakfi" target="inst"><img src="/assets/images/social/logo-facebook.svg" alt="Facebook" /></a>
                        <a href="https://twitter.com/serverhanimlar" target="inst"><img src="/assets/images/social/logo-twitter.svg" alt="Twitter" /></a>
                    </li>
                </ul>
                <div></div>
            </div>
            <div id="section2"></div>
        </div> */}
    </>)
}

export default observer(Footer);